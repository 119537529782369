import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLocationDot,
  faMoon,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./footer.css";
import { useDispatch, useSelector } from "react-redux";
import { getSiteSettingData } from "../../../redux/site-settings/action";

function Footer({
  increaseFontSize,
  decreaseFontSize,
  toggleTheme,
  toggleColorlessMode,
}) {
  const dispatch = useDispatch();
  const siteSettings = useSelector((state) => state.siteSettings);

  useEffect(() => {
    if (!siteSettings?.siteSettingsData?.length > 0)
      dispatch(getSiteSettingData());
  }, []);

  return (
    <section className="footer text-white position-relative">
      <div className="top-sec">
        <Container className="custom-container" id="footer">
          <Row>
            <Col lg={4}>
              <div className="logo-content-wrapper">
                <div className="mb-xl-5 mb-4">
                  <strong className="d-inline-block mb-lg-4 mb-3">
                    <Link to="#" className="d-inline-block">
                      <Image
                        src={Images.footerLogo}
                        alt="logo"
                        loading="lazy"
                        fluid
                      />
                    </Link>
                  </strong>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
                <div className="social-contact mb-4">
                  <h4 className="mb-3">Social Contact</h4>
                  <ul className="social-icons list-unstyled mb-0 d-flex gap-3">
                    {siteSettings?.siteSettingsData?.socialContacts?.length &&
                      siteSettings?.siteSettingsData?.socialContacts?.map(
                        (contact, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={contact?.attributeValue || "#"}
                                className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                              >
                                <Image
                                  src={contact?.icon}
                                  alt="social icon"
                                  loading="lazy"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                          );
                        },
                      )}

                    {/* <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData.find(
                            (item) => item.attributeName === "youtube",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData.find(
                            (item) => item.attributeName === "instagram",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData.find(
                            (item) => item.attributeName === "twitter",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li> */}
                  </ul>
                </div>
                <form className="newsletter-form">
                  <div className="input-group flex-nowrap">
                    <input
                      className="newsletter-email input-text"
                      type="email"
                      placeholder="Enter email"
                    />
                    <button
                      className="newsletter-submit btn btn-solid-blue"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={3} md={5} sm={7}>
              <div className="content-wrapper">
                <h4 className="title">Services</h4>
                <ul className="footer-links list-unstyled mb-0">
                  {siteSettings?.siteSettingsData?.services?.length > 0 &&
                    siteSettings?.siteSettingsData?.services?.map(
                      (service, index) => {
                        return (
                          <li key={index} className="mb-xl-4 mb-md-3 mb-2">
                            <Link
                              to={service?.value}
                              className="text-decoration-none"
                            >
                              {service?.name}
                            </Link>
                          </li>
                        );
                      },
                    )}
                </ul>
              </div>
            </Col>
            <Col lg={2} md={3} sm={5}>
              <div className="content-wrapper">
                <h4 className="title">Useful Links</h4>
                <ul className="footer-links list-unstyled mb-0">
                  {siteSettings?.siteSettingsData?.usefulLinks?.length > 0 &&
                    siteSettings?.siteSettingsData?.usefulLinks?.map(
                      (link, index) => {
                        return (
                          <li key={index} className="mb-xl-4 mb-md-3 mb-2">
                            <Link
                              to={link?.value}
                              className="text-decoration-none"
                            >
                              {link?.name}
                            </Link>
                          </li>
                        );
                      },
                    )}
                </ul>
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className="content-wrapper">
                <h4 className="title">Contact us</h4>
                <ul className="contact-info list-unstyled mb-4">
                  {siteSettings?.siteSettingsData?.contactUs?.map(
                    (contact, index) => {
                      return (
                        <li key={index} className="mb-md-3 mb-2">
                          <a
                            href={`tel::${contact?.attributeValue || "#"}`}
                            className="d-inline-flex align-items-center gap-3 text-decoration-none"
                          >
                            <FontAwesomeIcon
                              icon={
                                contact?.attributeName == "Phone"
                                  ? faPhone
                                  : contact?.attributeName == "Email"
                                    ? faEnvelope
                                    : faLocationDot
                              }
                              width={16}
                              className="flex-shrink-0"
                            />
                            <span>{contact?.attributeValue || "#"}</span>
                          </a>
                        </li>
                      );
                    },
                  )}
                </ul>
                <ul className="bottom-icons-list list-unstyled d-flex gap-2">
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => increaseFontSize()}
                  >
                    A+
                  </li>
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => decreaseFontSize()}
                  >
                    A-
                  </li>
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => toggleTheme()}
                    // variant={darkMode ? "light" : "dark"}
                  >
                    <FontAwesomeIcon className="moon-svg" icon={faMoon} />
                    <svg
                      className="sun-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#fff"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="5"
                        stroke="transparent"
                        strokeWidth="2"
                      />
                      <path
                        d="M12 2V4"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M12 20V22"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4 12L2 12"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M22 12L20 12"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.7778 4.22266L17.5558 6.25424"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4.22217 4.22266L6.44418 6.25424"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6.44434 17.5557L4.22211 19.7779"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.7778 19.7773L17.5558 17.5551"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </li>
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => toggleColorlessMode()}
                  >
                    <FontAwesomeIcon className="eye-open" icon={faEye} />
                    <FontAwesomeIcon className="eye-slash" icon={faEyeSlash} />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-sec text-center text-white">
        <Container>
          <p className="mb-0">
            Copyright © {new Date().getFullYear()} Ras Al Khaimah | All Rights
            Reserved.
          </p>
        </Container>
      </div>
    </section>
  );
}

export default Footer;
