import {
  SITE_SETTINGS_LOADER,
  SET_SITE_SETTINGS,
  FETCH_CALCULATOR,
} from "../actionTypes";

const initialState = {
  loader: false,
  siteSettingsData: [],
  calculatorData: {},
};

const siteSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITE_SETTINGS_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_SITE_SETTINGS: {
      return {
        ...state,
        siteSettingsData: action.payload,
      };
    }
    case FETCH_CALCULATOR: {
      return {
        ...state,
        calculatorData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default siteSettingsReducer;
