import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourseDetail,
  getCourses,
  updateDownloadCount,
} from "../../../redux/courses/action";
import { useParams } from "react-router-dom";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import MetaTags from "../../../components/metaTags/seoTags";
import TrainingCourses from "./trainingCourses";
import { getMediaPage } from "../../../redux/media-center/action";
import Detail from "../../../components/common/detail/detail";
import { getCmsPage } from "../../../redux/cms-pages/action";
import Banner from "../../../components/common/banner/banner";
import PageStats from "../../../components/pageStats/pageStats";

export default function TraningCourseDetail() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const media = useSelector((state) => state.media);
  const mediaPageContent = media.mediaCenter?.mediaCenter;
  const courses = useSelector((state) => state.courses);
  const coursesData = courses?.courses;
  const singleCourse = courses?.courseDetail;
  const { pageData } = useSelector((state) => state.cmsPages);
  const { id } = useParams();

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getCourseDetail(id));
    dispatch(getCmsPage("Training Courses"));
  }, [id]);

  useEffect(() => {
    dispatch(getCourses(currentPage, 6));
  }, [currentPage]);

  return (
    <>
      {courses.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={singleCourse?.pageTitle}
            description={singleCourse?.metaDescription}
            keywords={singleCourse?.metaKeywords}
            metaTitle={singleCourse?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <Detail {...singleCourse} />
          <TrainingCourses
            heading={mediaPageContent?.coursesHeading}
            limit={6}
            courses={coursesData?.courses}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalPages={coursesData.totalPages || 1}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={singleCourse?.downloadCount}
            viewCount={singleCourse?.pageViewCount}
            pdf={singleCourse?.pdfFile}
            id={singleCourse?.id}
            loader={singleCourse?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
