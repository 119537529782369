import React from "react";
import "./customSliderArows.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export const NextArrow = (props) => (
  <button
    {...props}
    className="custom-right-arrow custom-arrow p-0 border-0 bg-transparent"
  >
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
);
export const PrevArrow = (props) => (
  <button
    {...props}
    className="custom-left-arrow custom-arrow p-0 border-0 bg-transparent"
  >
    <FontAwesomeIcon icon={faChevronLeft} />
  </button>
);
