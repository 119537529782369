import authReducer from "./auth/reducer";
import e_servicesReducer from "./e-services/reducer";
import userReducer from "./user/reducer";
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import ArbitratorReducer from "./arbitrator/e-services/reducer";
import landing_pageReducer from "./landing-page/reducer";
import juryPanelReducer from "./jury-panel/reducer";
import coursesReducer from "./courses/reducer";
import cmsPageReducer from "./cms-pages/reducer";
import seminarReducer from "./seminars/reducer";
import departmentCenterReducer from "./departmentCenter/reducer";
import lawReducer from "./laws/reducer";
import arbitrationPageReducer from "./arbitration/reducer";
import siteSettingsReducer from "./site-settings/reducer";
import servicesReducer from "./services/reducer";
import vissionReducer from "./vission/reducer";
import faqReducer from "./faq/reducer";
import centerTablesReducer from "./center-tables/reducer";
import aboutUsReducer from "./about-us/reducer";
import pollReducer from "./polls/reducer";
import mediationPageReducer from "./mediation/reducer";
import feedbackReducer from "./feedback/reducer";
import newsReducer from "./news/reducer";
import mediaCenterReducer from "./media-center/reducer";
import statsReducer from "./stats/reducer";
import roleOfCenterReducer from "./role-of-center/reducer";
import eventReducer from "./events/reducer";
import workshopReducer from "./workshop/reducer";
import elibrariesReducer from "./e-library/reducer";
import photosReducer from "./photo-album/reducer";
import videosReducer from "./videos/reducer";
import mediationRequestReducer from "./mediation-request/reducer";
import arbitrationFormReducer from "./arbitrationRequest/reducer";

const middleware = [thunk];
const reducer = combineReducers({
  auth: authReducer,
  e_services: e_servicesReducer,
  user: userReducer,
  Arbitrator: ArbitratorReducer,
  landingPage: landing_pageReducer,
  juryPanel: juryPanelReducer,
  departmentCenter: departmentCenterReducer,
  law: lawReducer,
  poll: pollReducer,
  events: eventReducer,
  courses: coursesReducer,
  videos: videosReducer,
  cmsPages: cmsPageReducer,
  seminars: seminarReducer,
  workshops: workshopReducer,
  libraries: elibrariesReducer,
  photos: photosReducer,
  arbitration: arbitrationPageReducer,
  siteSettings: siteSettingsReducer,
  services: servicesReducer,
  vission: vissionReducer,
  news: newsReducer,
  faq: faqReducer,
  centerTable: centerTablesReducer,
  aboutUs: aboutUsReducer,
  mediation: mediationPageReducer,
  reviews: feedbackReducer,
  media: mediaCenterReducer,
  stats: statsReducer,
  roleOfCenter: roleOfCenterReducer,
  mediationRequest: mediationRequestReducer,
  arbitrationForm: arbitrationFormReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
