import {
  SET_JURY_PANEL_DATA,
  JURY_PANEL_LOADER,
  DOWNLOAD_COUNT,
} from "../actionTypes";

const initialState = {
  loader: false,
  juryPanelData: {},
};

const juryPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case JURY_PANEL_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_JURY_PANEL_DATA: {
      return {
        ...state,
        juryPanelData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        juryPanelData: {
          ...state.juryPanelData,
          downloadCount: (state.juryPanelData?.downloadCount || 0) + 1,
        },
      };
    }

    default:
      return state;
  }
};

export default juryPanelReducer;
