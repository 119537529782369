import {
  COURSES_LOADER,
  DOWNLOAD_COUNT,
  SET_COURSES_DATA,
  SET_COURSE_DETAIL_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  courseDetail: {},
  courses: {},
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COURSES_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_COURSES_DATA: {
      return {
        ...state,
        courses: action.payload,
      };
    }
    case SET_COURSE_DETAIL_DATA: {
      return {
        ...state,
        courseDetail: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          downloadCount: (state.courseDetail?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default coursesReducer;
