import { toast } from "react-toastify";
import {
  PHOTOS_LOADER,
  DOWNLOAD_COUNT,
  SET_PHOTO_DETAIL_DATA,
  SET_PHOTOS_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";

export const getPhotos =
  (page = 1, limit = 8, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PHOTOS_LOADER,
        payload: true,
      });
      const queryParams = new URLSearchParams({
        page,
        limit,
        ...filters,
      }).toString();

      const res = await apiHelper(
        "get",
        `/media/album/list?type=image&${queryParams}`,
      );
      if (res.data) {
        dispatch({
          type: SET_PHOTOS_DATA,
          payload: res.data.data,
        });
      }
      dispatch({
        type: PHOTOS_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: PHOTOS_LOADER,
        payload: false,
      });
    }
  };

export const getPhotoDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PHOTOS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/album/${id}`);
    if (res.data) {
      dispatch({
        type: SET_PHOTO_DETAIL_DATA,
        payload: res.data.data,
      });
    }
    dispatch({
      type: PHOTOS_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: PHOTOS_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PHOTOS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/album/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: PHOTOS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: PHOTOS_LOADER,
      payload: false,
    });
  }
};
