import {
  PHOTOS_LOADER,
  DOWNLOAD_COUNT,
  SET_PHOTOS_DATA,
  SET_PHOTO_DETAIL_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  photoDetail: {},
  photos: {},
};

const photosReducer = (state = initialState, action) => {
  switch (action.type) {
    case PHOTOS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_PHOTOS_DATA: {
      return {
        ...state,
        photos: action.payload,
      };
    }
    case SET_PHOTO_DETAIL_DATA: {
      return {
        ...state,
        photoDetail: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        photoDetail: {
          ...state.photoDetail,
          downloadCount: (state.photoDetail?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default photosReducer;
