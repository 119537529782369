import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCmsPage, updateDownloadCount } from "../../redux/cms-pages/action";
import Banner from "../../components/common/banner/banner";
import { getEventList } from "../../redux/events/action";
import EventListing from "./list";
import FilterEvents from "./filterEvent";
import ActionFeedback from "../../components/common/takeActions/actionFeedback";
import PageStats from "../../components/pageStats/pageStats";

const EventsList = () => {
  const dispatch = useDispatch();
  const cmsPages = useSelector((state) => state.cmsPages);
  const bannerData = cmsPages.pageData;
  const Events = useSelector((state) => state.events);
  useEffect(() => {
    if (!Events.eventList?.events?.length > 0) dispatch(getEventList());
    dispatch(getCmsPage("Events"));
  }, []);
  return (
    <>
      <Banner
        title={bannerData.title}
        content={bannerData.detail}
        img={bannerData.image}
        loader={bannerData?.loader}
      />
      <FilterEvents />
      <EventListing
        pageCount={Events.eventList?.totalPages}
        events={Events?.eventList?.events}
      />
      <PageStats
        countAction={updateDownloadCount}
        downloadCount={bannerData.downloadCount}
        viewCount={bannerData.pageViewCount}
        pdf={bannerData.pdfFile}
        id={bannerData.id}
        loader={bannerData?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default EventsList;
