import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import "./eventCard.css";
import { formatDate } from "../../../utils/formatDate";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const EventCard = ({ loader, date, heading, location, description, id }) => {
  return (
    <div className="event-card-wrapper d-flex">
      <div className="date-box text-center d-flex align-items-center justify-content-center p-2 me-sm-3 me-2">
        <p className="text-white mb-0">
          {loader ? (
            <Skeleton width={40} height={40} />
          ) : (
            <>
              <span className="fw-semibold">
                {formatDate(date)
                  .split(" ")[1]
                  .replace(",", "")
                  .padStart(2, "0")}
              </span>
              <br />
              {formatDate(date).split(" ")[0].toUpperCase()}{" "}
              {formatDate(date).split(" ")[2]}
            </>
          )}
        </p>
      </div>
      <div className="content-box">
        <h6 className="mb-2">{loader ? <Skeleton width="60%" /> : heading}</h6>
        <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
          <FontAwesomeIcon icon={faLocationDot} color="#003E7F" />
          <span className="content-location">
            {loader ? <Skeleton width={100} /> : location}
          </span>
        </div>
        <div className="ck-content-wrapper">
          {loader ? (
            <Skeleton count={2} height={16} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
        {loader ? (
          <Skeleton width={80} height={16} className="float-end" />
        ) : (
          <Link to={`/workshops/${id}`}>
            <span
              className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
              role="button"
            >
              detail
              <FontAwesomeIcon icon={faArrowRightLong} />
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EventCard;
