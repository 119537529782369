import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Images } from "../../../assets";
import "./membership.css";

const Membership = ({ detail, heading, image, subHeading }) => {
  return (
    <section className="membership-sec">
      <Container className="custom-container">
        <Row>
          <Col lg={6} className="position-relative">
            <div className="member-img pe-3">
              <Image
                src={image}
                alt="members img"
                loading="lazy"
                fluid
                className="h-100"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="content-wrapper">
              <h2 className="mb-0">{subHeading}</h2>
              <strong className="title text-uppercase d-inline-block">
                {heading}
              </strong>
              <p
                className="p-lg"
                dangerouslySetInnerHTML={{ __html: detail }}
              ></p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Membership;
