import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMediaPage } from "../../../redux/media-center/action";
import MetaTags from "../../../components/metaTags/seoTags";
import Banner from "../../../components/common/banner/banner";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import {
  getCmsPage,
  updateDownloadCount,
} from "../../../redux/cms-pages/action";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import { getVideos } from "../../../redux/videos/action";
import VideoAlbum from "./videosList";
import PhotoAlbumFilters from "../photoAlbum/photoAlbumFilters";

export default function VideosList() {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const { pageData } = useSelector((state) => state.cmsPages);
  const videos = useSelector((state) => state.videos);
  const videosData = videos?.videos;
  const mediaPageContent = media.mediaCenter?.mediaCenter;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    title: "",
  });

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getCmsPage("Video Gallery"));
  }, [mediaPageContent]);

  useEffect(() => {
    dispatch(getVideos(currentPage, 9));
  }, [currentPage]);

  return (
    <>
      {videos.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={pageData?.pageTitle}
            description={pageData?.metaDescription}
            keywords={pageData?.metaKeywords}
            metaTitle={pageData?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <PhotoAlbumFilters
            type="video"
            setFilters={setFilters}
            filters={filters}
          />
          <VideoAlbum
            heading={mediaPageContent?.videoAlbumHeading}
            limit={9}
            videos={videosData?.albums}
            onPageChange={setCurrentPage}
            currentPage={currentPage}
            totalPages={videosData.totalPages || 1}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={pageData?.downloadCount}
            viewCount={pageData?.pageViewCount}
            pdf={pageData?.pdfFile}
            id={pageData?.id}
            loader={pageData?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
