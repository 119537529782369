import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import MetaTags from "../../../components/metaTags/seoTags";
import { getMediaPage } from "../../../redux/media-center/action";
import Detail from "../../../components/common/detail/detail";
import { getCmsPage } from "../../../redux/cms-pages/action";
import Banner from "../../../components/common/banner/banner";
import Seminars from "./seminars";
import {
  getSeminarDetail,
  getSeminars,
  updateDownloadCount,
} from "../../../redux/seminars/action";
import PageStats from "../../../components/pageStats/pageStats";

export default function SeminarsDetail() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const media = useSelector((state) => state.media);
  const mediaPageContent = media.mediaCenter?.mediaCenter;
  const seminars = useSelector((state) => state.seminars);
  const seminarsData = seminars?.seminars;
  const singleSeminar = seminars?.seminarDetail;
  const { pageData } = useSelector((state) => state.cmsPages);
  const { id } = useParams();

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getSeminarDetail(id));
    dispatch(getCmsPage("Seminars"));
  }, [id]);

  useEffect(() => {
    dispatch(getSeminars(currentPage, 6));
  }, [currentPage]);

  return (
    <>
      {seminars.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={singleSeminar?.pageTitle}
            description={singleSeminar?.metaDescription}
            keywords={singleSeminar?.metaKeywords}
            metaTitle={singleSeminar?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <Detail {...singleSeminar} />
          <Seminars
            heading={mediaPageContent?.coursesHeading}
            limit={6}
            seminars={seminarsData?.conferences}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalPages={seminarsData.totalPages || 1}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={singleSeminar?.downloadCount}
            viewCount={singleSeminar?.pageViewCount}
            pdf={singleSeminar?.pdfFile}
            id={singleSeminar?.id}
            loader={singleSeminar?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
