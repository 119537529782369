import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  MEDIA_CENTER_LOADER,
  SET_INFO,
  SET_MEDIA_CENTER,
  DOWNLOAD_COUNT,
  GET_INFO,
  DOWNLOAD_POLICY_COUNT,
} from "../actionTypes";

export const getLegalInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", "/media/info/list");
    if (res.data) {
      dispatch({
        type: SET_INFO,
        payload: res.data.data?.infoEntries,
      });
    }
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  }
};

export const getInfoPage = (pageTag) => async (dispatch) => {
  try {
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/info/get/${pageTag}`);
    if (res.data) {
      dispatch({
        type: GET_INFO,
        payload: res.data.data,
      });
    }
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  }
};
export const getMediaPage = () => async (dispatch) => {
  try {
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", "/media/page");
    if (res.data) {
      dispatch({
        type: SET_MEDIA_CENTER,
        payload: res.data.data,
      });
    }
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: MEDIA_CENTER_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  }
};

export const downloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/info/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_POLICY_COUNT,
      });
      dispatch({
        type: MEDIA_CENTER_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: MEDIA_CENTER_LOADER,
      payload: false,
    });
  }
};
