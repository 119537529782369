import React from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Col, Container, Row } from "react-bootstrap";
import EventCard from "../../../components/common/eventCard/eventCard";
import Skeleton from "react-loading-skeleton";
import "./workshop.css";

const Workshop = ({
  loader,
  workshops,
  heading,
  limit,
  onPageChange,
  totalPages,
  currentPage,
}) => {
  const HandlePageClick = (event) => {
    onPageChange(event.selected + 1);
  };

  return (
    <section className="workshop-sec">
      <Container className="custom-container">
        <h4 className="text-blue-1 mb-4">
          {loader ? <Skeleton width="30%" height={24} /> : heading}
        </h4>
        <Row className="gy-lg-4 gy-3">
          {workshops?.length > 0 &&
            workshops.slice(0, limit).map((workshop, index) => (
              <Col
                lg={4}
                md={6}
                key={index}
                // className={`${index >= workshops.length - (workshops.length % 3 || 3)
                //   ? "no-border" : ""
                //   }`}
              >
                <EventCard
                  date={workshop?.createdAt}
                  heading={workshop?.heading}
                  location={workshop?.location}
                  description={workshop?.description}
                  id={workshop?.id}
                  loader={loader}
                />
                {/* <Link to={`/workshops/${workshop?.id}`}>
                  <span
                    className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                    role="button"
                  >
                    detail
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </Link> */}
              </Col>
            ))}
        </Row>
        {limit > 6 ? (
          loader ? (
            <Skeleton
              width="50%"
              height={40}
              className="mt-4 mx-auto d-block"
            />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : (
          <div className="text-end mt-4">
            {loader ? (
              <Skeleton width={120} height={40} />
            ) : (
              <Link to={`/workshops`} className="btn btn-solid-blue">
                View All
              </Link>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default Workshop;
