import React from "react";
import { formatDate } from "../../../utils/formatDate";
import { Images } from "../../../assets";
import "./detail.css";
import { Container, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export default function Detail({
  createdAt,
  category,
  location,
  startDate,
  endDate,
  heading,
  image,
  title,
  description,
  detail,
  summary,
  subHeading,
  loader,
}) {
  return (
    <section className="news-detail-sec">
      <Container className="custom-container">
        <div className="content-wrapper pb-4 mb-5">
          {loader ? (
            <>
              <Skeleton width={200} height={20} />
              <Skeleton width={300} height={30} className="mb-4" />
            </>
          ) : startDate ? (
            <>
              <p className="date pre-heading">
                {formatDate(startDate)} - {formatDate(endDate)} | {location}
              </p>
              <h4 className="mb-4">{title ? title : heading}</h4>
            </>
          ) : (
            <>
              <p className="date pre-heading">
                {formatDate(createdAt)} | {category}
              </p>
              {subHeading && <span className="pre-heading">{subHeading}</span>}
              <h4 className="mb-4">{title ? title : heading}</h4>
            </>
          )}
          <div className="img-wrapper mb-lg-4 mb-3">
            {loader ? (
              <Skeleton height={300} />
            ) : (
              <Image
                src={
                  image
                  // || Images.newsDetail
                }
                alt="blog img"
                loading="lazy"
                fluid
              />
            )}
          </div>

          {loader ? (
            <Skeleton count={3} />
          ) : (
            <>
              {summary && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: summary || "No content available.",
                  }}
                />
              )}
              {description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description || "No content available.",
                  }}
                />
              )}
              {detail && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail || "No content available.",
                  }}
                />
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
}
