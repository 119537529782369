import Swal from "sweetalert2";

export const config = {
  lngs: [
    { code: "en", native: "English" },
    { code: "ar", native: "Arabic" },
  ],
  serverUrl: process.env.REACT_APP_SERVER_URL,
  siteServerUrl: process.env.REACT_APP_CLIENT_SERVER_URL,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  recaptchaSiteSecret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
  uaePassAuthUrl: process.env.REACT_APP_UAE_PASS_AUTH_URL,
  uaePassTokenUrl: process.env.REACT_APP_UAE_PASS_TOKEN_URL,
  uaePassClientId: process.env.REACT_APP_UAE_PASS_CLIENT_ID,
  uaePassClientSecret: process.env.REACT_APP_UAE_PASS_CLIENT_SECRET,
  uaePassRedirectUri: process.env.REACT_APP_UAE_PASS_REDIRECT_URI,
  TOAST_TIMMER: 2000,
  limit: 4,
};

export const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: config.TOAST_TIMMER,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
