import { toast } from "react-toastify";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import {
  ARBITRATION_FORM_LOADER,
  ARBITRATION_FORM_SUBMITTED,
} from "../actionTypes";

export const submitArbitrationForm = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ARBITRATION_FORM_LOADER,
      payload: true,
    });
    const response = await apiHelper(
      "post",
      "/arb-and-med/arbitration-cases",
      formData,
    );
    if (response.status === 200) {
      dispatch({
        type: ARBITRATION_FORM_SUBMITTED,
        payload: true,
      });
      dispatch({
        type: ARBITRATION_FORM_LOADER,
        payload: false,
      });
      toast.success(
        response.data?.data?.message ||
          "Your form has been submitted successfully",
      );
    }
  } catch (error) {
    dispatch({
      type: ARBITRATION_FORM_LOADER,
      payload: false,
    });
    toast.error(
      error.response.data?.message || "Something went wrong. Please try again.",
    );
  }
};
