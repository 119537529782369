import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import MetaTags from "../../../components/metaTags/seoTags";
import { getMediaPage } from "../../../redux/media-center/action";
import Detail from "../../../components/common/detail/detail";
import { getCmsPage } from "../../../redux/cms-pages/action";
import Banner from "../../../components/common/banner/banner";
import PageStats from "../../../components/pageStats/pageStats";
import {
  getPhotoDetail,
  getPhotos,
  updateDownloadCount,
} from "../../../redux/photo-album/action";
import PhotoAlbum from "./photoAlbum";

export default function PhotoDetail() {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const mediaPageContent = media.mediaCenter?.mediaCenter;
  const photos = useSelector((state) => state.photos);
  const photosData = photos?.photos;
  const { photoDetail } = photos;
  const { pageData } = useSelector((state) => state.cmsPages);
  const { id } = useParams();

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getPhotoDetail(id));
    dispatch(getCmsPage("Photo Gallery"));
  }, [id]);

  useEffect(() => {
    dispatch(getPhotos(1, 8));
  }, []);

  return (
    <>
      {photos.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={photoDetail?.pageTitle}
            description={photoDetail?.metaDescription}
            keywords={photoDetail?.metaKeywords}
            metaTitle={photoDetail?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <Detail {...photoDetail} description={" "} />
          <PhotoAlbum
            heading={mediaPageContent?.photoAlbumHeading}
            limit={8}
            photos={photosData?.albums}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={photoDetail?.downloadCount}
            viewCount={photoDetail?.pageViewCount}
            pdf={photoDetail?.pdfFile}
            id={photoDetail?.id}
            loader={photoDetail?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
