import React, { useEffect } from "react";
import "./newsDetail.css";
import { Container, Image } from "react-bootstrap";
import { Images } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { getNewsDetail, updateDownloadCount } from "../../../redux/news/action";
import { useParams } from "react-router-dom";
import LatestNews from "../../../components/common/latestNews/latestNews";
import Banner from "../../../components/common/banner/banner";
import { formatDate } from "../../../utils/formatDate";
import MetaTags from "../../../components/metaTags/seoTags";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import { getCmsPage } from "../../../redux/cms-pages/action";
import Skeleton from "react-loading-skeleton";

/**
 * NewsDetail component. Displays the details of a news item. Fetches the news item
 * data from the server using the `getNewsDetail` action when the component mounts.
 * The component uses the `useParams` hook to get the id of the news item from the
 * URL parameters.
 *
 * The component renders a heading with the title of the news item, a paragraph with
 * the content of the news item, an image, and a link to download the news item as a
 * PDF file. The component also renders a LatestNews component with a list of the
 * latest news items, and a PageStats component with the download count and view count
 * of the news item. Finally, the component renders an ActionFeedback component to
 * display the result of the user's actions.
 *
 * @returns {React.ReactElement} The NewsDetail component.
 */
const NewsDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news);
  const { pageData } = useSelector((state) => state.cmsPages);
  const newsDetail = news?.newsDetail;
  const isLoading = news?.loader;
  useEffect(() => {
    dispatch(getNewsDetail(id));
  }, [id]);
  useEffect(() => {
    dispatch(getCmsPage("News"));
  }, []);
  return (
    <>
      <MetaTags
        title={newsDetail.pageTitle}
        metaTitle={newsDetail.metaTitle}
        description={newsDetail.metaDescription}
        keywords={newsDetail.metaKeywords}
      />
      <Banner
        title={pageData.title}
        content={pageData.detail}
        img={pageData.image || Images.newsBannerBg}
        loader={pageData?.loader}
      />
      <section className="news-detail-sec">
        <Container className="custom-container">
          <div className="content-wrapper pb-5">
            <p className="date pre-heading">
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                formatDate(newsDetail.createdAt)
              )}
            </p>
            <h4 className="mb-4">
              {isLoading ? (
                <Skeleton width={300} height={30} />
              ) : (
                newsDetail.title
              )}
            </h4>
            <div className="img-wrapper mb-lg-4 mb-3">
              {isLoading ? (
                <Skeleton height={300} />
              ) : (
                <Image
                  src={newsDetail.newsImage || Images.newsDetail}
                  alt="blog img"
                  loading="lazy"
                  fluid
                />
              )}
            </div>
            <div className="ck-content-wrapper">
              {isLoading ? (
                <Skeleton count={5} />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: newsDetail.detail || "No content available.",
                  }}
                />
              )}
            </div>
          </div>
        </Container>
      </section>
      <LatestNews
        paginate={true}
        pageCount={news.newsList.totalPages}
        newsList={news.newsList.news}
        mainTitle="Latest News"
        limit={4}
      />
      {newsDetail.pdfFile ? (
        <PageStats
          countAction={updateDownloadCount}
          downloadCount={newsDetail.downloadCount}
          viewCount={newsDetail.pageViewCount}
          pdf={newsDetail.pdfFile}
          id={newsDetail.id}
          loader={newsDetail.loader}
        />
      ) : (
        ""
      )}
      <ActionFeedback />
    </>
  );
};

export default NewsDetail;
