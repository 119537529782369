import headerLogo from "./images/header-logo.png";
import homeBanner from "./images/home-banner.jpg";
import settingIcon from "./images/icon-setting.svg";
import arLangIcon from "./images/icon-lang.svg";
import enLangIcon from "./images/icon-en-lang.svg";
import userIcon from "./images/icon-user.svg";
import searchIcon from "./images/icon-search.png";
import iconHomeVision from "./images/icon-home-vision.svg";
import iconHomeMission from "./images/icon-home-mission.svg";
import iconHomeObjectives from "./images/icon-home-objectives.svg";
import homeVision from "./images/home-vision.png";
import aboutLawsBanner from "./images/banners/about-laws.png";
import juryPanelBanner from "./images/banners/jury-panel.png";
import roleOfCenter from "./images/banners/role-of-center.png";
import servicesBanner from "./images/banners/services.png";
import arbitrationBanner from "./images/banners/arbitration.png";
import mediationBanner from "./images/banners/mediation.png";
import centerTablesBanner from "./images/banners/center-tables.png";
import membershipBanner from "./images/banners/membership.png";
import departmentsOfCenterBanner from "./images/banners/departments-of-center.png";
import contactUsBanner from "./images/banners/contact-us.png";
import alLogo from "./images/al-logo.svg";
import halfAlLogo from "./images/about-al-logo.png";
import articleImg1 from "./images/news-article1.jpg";
import partnerLogo1 from "./images/partners/service/1.png";
import partnerLogo2 from "./images/partners/service/2.png";
import partnerLogo3 from "./images/partners/service/3.png";
import partnerLogo4 from "./images/partners/service/4.png";
import partnerLogo5 from "./images/partners/service/5.png";
import partnerLogo6 from "./images/partners/service/6.png";
import partnerLogo7 from "./images/partners/service/7.png";
import partnerLogo8 from "./images/partners/service/8.png";
import partnerLogo9 from "./images/partners/international-protocol/1.png";
import partnerLogo10 from "./images/partners/international-protocol/2.png";
import partnerLogo11 from "./images/partners/international-protocol/3.png";
import partnerLogo12 from "./images/partners/international-protocol/4.png";
import partnerLogo13 from "./images/partners/international-protocol/5.png";
import partnerLogo14 from "./images/partners/international-protocol/6.png";
import partnerLogo15 from "./images/partners/international-protocol/7.png";
import partnerLogo16 from "./images/partners/international-protocol/8.png";
import partnerLogo17 from "./images/partners/international-protocol/9.png";
import partnerLogo18 from "./images/partners/international-protocol/10.png";
import partnerLogo19 from "./images/partners/main/1.png";
import partnerLogo20 from "./images/partners/main/2.png";
import partnerLogo21 from "./images/partners/main/3.png";
import partnerLogo22 from "./images/partners/main/4.png";
import partnerLogo23 from "./images/partners/main/5.png";
import partnerLogo24 from "./images/partners/main/6.png";
import partnerLogo25 from "./images/partners/main/7.png";
import partnerLogo26 from "./images/partners/main/8.png";
import partnerLogo27 from "./images/partners/main/9.png";
import partnerLogo28 from "./images/partners/main/10.png";
import partnerLogo29 from "./images/partners/main/11.png";
import partnerLogo30 from "./images/partners/main/12.png";
import partnerLogo31 from "./images/partners/main/13.png";
import partnerLogo32 from "./images/partners/main/14.png";
import partnerLogo33 from "./images/partners/main/15.png";
import partnerLogo34 from "./images/partners/main/16.png";
import partnerLogo35 from "./images/partners/main/17.png";
import partnerLogo36 from "./images/partners/main/18.png";
import partnerLogo37 from "./images/partners/main/19.png";
import partnerLogo38 from "./images/partners/main/20.png";
import partnerLogo39 from "./images/partners/main/21.png";
import partnerLogo40 from "./images/partners/main/22.png";
import partnerLogo41 from "./images/partners/main/23.png";
import partnerLogo42 from "./images/partners/main/24.png";
import partnerLogo43 from "./images/partners/main/25.png";
import partnerLogo44 from "./images/partners/main/26.png";
import partnerLogo45 from "./images/partners/main/27.png";
import partnerLogo46 from "./images/partners/main/28.png";
import partnerLogo47 from "./images/partners/main/29.png";
import partnerLogo48 from "./images/partners/main/30.png";
import partnerLogo49 from "./images/partners/strategic/1.png";
import partnerLogo50 from "./images/partners/strategic/2.png";
import partnerLogo51 from "./images/partners/strategic/3.png";
import partnerLogo52 from "./images/partners/strategic/4.png";
import partnerLogo53 from "./images/partners/strategic/5.png";
import newsBannerBg from "./images/news-banner-bg.png";
import newsDetail from "./images/news-detail.png";
import mediaBannerBg from "./images/banners/media-center.png";
import arbiratorWoman from "./images/arbirator-woman.png";
import albumPhoto1 from "./images/photo-album/1.png";
import albumPhoto2 from "./images/photo-album/2.png";
import albumPhoto3 from "./images/photo-album/3.png";
import albumPhoto4 from "./images/photo-album/4.png";
import eventImg from "./images/event.png";
import theEventImg from "./images/the-event.png";
import indoorIcon from "./images/icon-indoor.svg";
import outdoorIcon from "./images/icon-outdoor.svg";
import alLeftside from "./images/al-left-logo.png";
import course1 from "./images/course1.png";
import seminarService1 from "./images/seminar-service1.png";
import seminarService2 from "./images/seminar-service2.png";
import seminarService3 from "./images/seminar-service3.png";
import lawService1 from "./images/laws/1.png";
import lawService2 from "./images/laws/2.png";
import lawService3 from "./images/laws/3.png";
import lawService4 from "./images/laws/4.png";
import lawService5 from "./images/laws/5.png";
import lawService6 from "./images/laws/6.png";
import lawService7 from "./images/laws/7.png";
import lawService8 from "./images/laws/8.png";
import lawService9 from "./images/laws/9.png";
import lawService11 from "./images/riyadh-agreement.png";
import lawService10 from "./images/arbitration-countries.png";
import juryFormation from "./images/jury-formation.png";
import peopleMeeting from "./images/people-meeting.png";
import membership from "./images/membership.png";
import termsReference from "./images/terms-reference.png";
import arbitrationConcept from "./images/arbiration-concept.png";
import mediationConcept from "./images/mediation-concept.png";
import impartialityIcon from "./images/icon-impartiality.svg";
import costIcon from "./images/icon-cost.svg";
import confidentialityIcon from "./images/icon-confidentiality.svg";
import globIcon from "./images/icon-glob.svg";
import speedIcon from "./images/icon-speed.svg";
import flexibilityIcon from "./images/icon-flexibility.svg";
import voluntaryIcon from "./images/icon-voluntary.svg";
import collaborativeIcon from "./images/icon-collaboration.svg";
import clockIcon from "./images/icon-time.svg";
import empowermentIcon from "./images/icon-empowerment.svg";
import whyArbitration from "./images/why-arbitration.png";
import whyMediation from "./images/why-mediation.png";
import bindingArbitration from "./images/binding-arbitration.png";
import nonBindingArbitration from "./images/non-binding-arbitration.png";
import mandatoryArbitration from "./images/mandatory-arbitration.png";
import businessmanSpeaker from "./images/businessman-speaker.png";
import modelArbitration from "./images/model-arbitration-clause.png";
import handshake from "./images/handshake.png";
import caseManagement from "./images/case-management.png";
import financialAffairs from "./images/financial-affairs.png";
import informationTechnology from "./images/information-technology.png";
import conferencesManagement from "./images/conferences-management.png";
import enrollment from "./images/enrollment.png";
import translationSchedule from "./images/translation-schedule.png";
import honorayMembership from "./images/honoray-membership.png";
import associateMember from "./images/associate-member.png";
import electronicLibrary1 from "./images/electornic-library1.png";
import electronicLibrary2 from "./images/electornic-library2.png";
import electronicLibrary3 from "./images/electornic-library3.png";
import electronicLibrary4 from "./images/electornic-library4.png";
import footerLogo from "./images/header-logo.png";
import toggleIcon from "./images/icon-toggle.svg";
import errorImage from "./images/404.png";
import Loader from "./images/spinner.gif";
import UAEPASS_Sign_in_Btn from "./images/UAEPASS_Sign_in_Btn.png";
import aboutBannerBg from "./images/about-banner-bg.png";
import calculatorBannerBg from "./images/calculator-banner-bg.png";

export const Images = {
  headerLogo,
  homeBanner,
  settingIcon,
  arLangIcon,
  enLangIcon,
  userIcon,
  searchIcon,
  aboutLawsBanner,
  juryPanelBanner,
  roleOfCenter,
  servicesBanner,
  arbitrationBanner,
  mediationBanner,
  departmentsOfCenterBanner,
  centerTablesBanner,
  membershipBanner,
  contactUsBanner,
  alLogo,
  halfAlLogo,
  articleImg1,
  partnerLogo1,
  partnerLogo2,
  partnerLogo3,
  partnerLogo4,
  partnerLogo5,
  partnerLogo6,
  partnerLogo7,
  partnerLogo8,
  partnerLogo9,
  partnerLogo10,
  partnerLogo11,
  partnerLogo12,
  partnerLogo13,
  partnerLogo14,
  partnerLogo15,
  partnerLogo16,
  partnerLogo17,
  partnerLogo18,
  partnerLogo19,
  partnerLogo20,
  partnerLogo21,
  partnerLogo22,
  partnerLogo23,
  partnerLogo24,
  partnerLogo25,
  partnerLogo26,
  partnerLogo27,
  partnerLogo28,
  partnerLogo29,
  partnerLogo30,
  partnerLogo31,
  partnerLogo32,
  partnerLogo33,
  partnerLogo34,
  partnerLogo35,
  partnerLogo36,
  partnerLogo37,
  partnerLogo38,
  partnerLogo39,
  partnerLogo40,
  partnerLogo41,
  partnerLogo42,
  partnerLogo43,
  partnerLogo44,
  partnerLogo45,
  partnerLogo46,
  partnerLogo47,
  partnerLogo48,
  partnerLogo49,
  partnerLogo50,
  partnerLogo51,
  partnerLogo52,
  partnerLogo53,
  newsBannerBg,
  newsDetail,
  mediaBannerBg,
  arbiratorWoman,
  albumPhoto1,
  albumPhoto2,
  albumPhoto3,
  albumPhoto4,
  eventImg,
  theEventImg,
  indoorIcon,
  outdoorIcon,
  alLeftside,
  course1,
  seminarService1,
  seminarService2,
  seminarService3,
  lawService1,
  lawService2,
  lawService3,
  lawService4,
  lawService5,
  lawService6,
  lawService7,
  lawService8,
  lawService9,
  lawService10,
  lawService11,
  juryFormation,
  peopleMeeting,
  membership,
  termsReference,
  arbitrationConcept,
  mediationConcept,
  impartialityIcon,
  costIcon,
  confidentialityIcon,
  globIcon,
  speedIcon,
  flexibilityIcon,
  voluntaryIcon,
  collaborativeIcon,
  clockIcon,
  empowermentIcon,
  whyArbitration,
  whyMediation,
  bindingArbitration,
  nonBindingArbitration,
  mandatoryArbitration,
  businessmanSpeaker,
  modelArbitration,
  handshake,
  caseManagement,
  financialAffairs,
  informationTechnology,
  conferencesManagement,
  enrollment,
  translationSchedule,
  honorayMembership,
  associateMember,
  electronicLibrary1,
  electronicLibrary2,
  electronicLibrary3,
  electronicLibrary4,
  iconHomeVision,
  iconHomeMission,
  iconHomeObjectives,
  homeVision,
  footerLogo,
  toggleIcon,
  errorImage,
  Loader,
  UAEPASS_Sign_in_Btn,
  aboutBannerBg,
  calculatorBannerBg,
};
