import React, { useEffect } from "react";
import moment from "moment";
import "./eventDetail.css";
import { Container, Image } from "react-bootstrap";
import { Images } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Banner from "../../../components/common/banner/banner";
import {
  formatDate,
  formatEventTime,
  getEventStatus,
} from "../../../utils/formatDate";
import MetaTags from "../../../components/metaTags/seoTags";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import {
  getEventDetail,
  getEventList,
  updateDownloadCount,
} from "../../../redux/events/action";
import { getCmsPage } from "../../../redux/cms-pages/action";
import EventListing from "../list";

const EventDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cmsPages = useSelector((state) => state.cmsPages);
  const bannerData = cmsPages.pageData;
  const Events = useSelector((state) => state.events);
  const eventDetail = Events.eventDetail;

  useEffect(() => {
    if (!Events.eventList?.events?.length > 0) dispatch(getEventList());
    dispatch(getCmsPage("Events"));
  }, []);
  useEffect(() => {
    dispatch(getEventDetail(id));
  }, [id]);
  return (
    <>
      <MetaTags
        title={eventDetail.pageTitle}
        metaTitle={eventDetail.metaTitle}
        description={eventDetail.metaDescription}
        keywords={eventDetail.metaKeywords}
      />
      <Banner
        title={bannerData.title}
        content={bannerData.detail}
        img={bannerData.image || Images.newsBannerBg}
        loader={bannerData?.loader}
      />
      <section className="news-detail-sec">
        <Container className="custom-container">
          <div className="content-wrapper pb-5">
            <h4 className="mb-4">{eventDetail.heading}</h4>
            <div className="img-wrapper mb-lg-4 mb-3">
              <Image
                src={eventDetail.eventImage || Images.newsDetail}
                alt="blog img"
                loading="lazy"
                fluid
              />
            </div>
            <div>{eventDetail?.eventType}</div>
            <p className="date pre-heading">
              {formatDate(eventDetail?.startDate)} -{" "}
              {formatDate(eventDetail?.endDate)}
            </p>
            <div>
              <FontAwesomeIcon icon={faLocationDot} color="#003E7F" />
              <span className="content-location">{eventDetail?.location}</span>
            </div>
            {eventDetail?.startTime && (
              <div className="event-time mb-1">
                {formatEventTime(eventDetail.startTime, eventDetail.endTime)}
              </div>
            )}
            <div className="event-status mb-2">
              {getEventStatus(eventDetail?.startDate, eventDetail?.endDate)}
            </div>
            <div
              className="ck-content-wrapper"
              dangerouslySetInnerHTML={{
                __html: eventDetail.details || "No content available.",
              }}
            />
          </div>
        </Container>
      </section>
      <EventListing
        pageCount={Events.eventList.totalPages}
        events={Events.eventList?.events}
      />
      <PageStats
        countAction={updateDownloadCount}
        downloadCount={eventDetail.downloadCount}
        viewCount={eventDetail.pageViewCount}
        pdf={eventDetail.pdfFile}
        id={eventDetail.id}
        loader={eventDetail?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default EventDetail;
