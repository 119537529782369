import {
  DOWNLOAD_COUNT,
  SEMINARS_LOADER,
  SET_SEMINARS_DATA,
  SET_SEMINAR_DETAIL_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";

export const getSeminars =
  (page = 1, limit = 10) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SEMINARS_LOADER,
        payload: true,
      });

      const res = await apiHelper(
        "get",
        `/media/conference/list?page=${page}&limit=${limit}`,
      );
      if (res.data) {
        dispatch({
          type: SET_SEMINARS_DATA,
          payload: res.data.data,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch({
        type: SEMINARS_LOADER,
        payload: false,
      });
    }
  };

export const getSeminarDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEMINARS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/conference/${id}`);
    if (res.data) {
      dispatch({
        type: SET_SEMINAR_DETAIL_DATA,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    dispatch({
      type: SEMINARS_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEMINARS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/conference/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: SEMINARS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: SEMINARS_LOADER,
      payload: false,
    });
  }
};
