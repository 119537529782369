import React from "react";
import Faq from "../../components/common/faq/faq";
import "./faqs.css";

function Faqs() {
  return (
    <div className="faq-main no-banner-page">
      <Faq />
    </div>
  );
}

export default Faqs;
