import {
  REGISTER_USER,
  LOGIN_USER,
  ERR_USER,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../actionTypes";

const initialState = {
  auth: [],
  user: {},
  err: "",
  resetPass: {},
  loader: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ERR_USER:
      return {
        ...state,
        err: action.payload,
      };
    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };
    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };
    default:
      return state;
  }
};

export default authReducer;
