import {
  SET_MEDIATION_REQUEST_PAGE_DATA,
  MEDIATION_REQUEST_PAGE_LOADER,
} from "../actionTypes";

const initialState = {
  loader: false,
  mediationForm: {},
};

const mediationRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEDIATION_REQUEST_PAGE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_MEDIATION_REQUEST_PAGE_DATA: {
      return {
        ...state,
        mediationForm: action.payload,
      };
    }
    default:
      return state;
  }
};

export default mediationRequestReducer;
