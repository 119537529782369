import {
  WORKSHOPS_LOADER,
  DOWNLOAD_COUNT,
  SET_WORKSHOPS_DATA,
  SET_WORKSHOP_DETAIL_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  workshopDetail: {},
  workshops: {},
};

const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKSHOPS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_WORKSHOPS_DATA: {
      return {
        ...state,
        workshops: action.payload,
      };
    }
    case SET_WORKSHOP_DETAIL_DATA: {
      return {
        ...state,
        workshopDetail: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        workshopDetail: {
          ...state.workshopDetail,
          downloadCount: (state.workshopDetail?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default workshopReducer;
