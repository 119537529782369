import {
  ELIBRARIES_LOADER,
  DOWNLOAD_COUNT,
  SET_ELIBRARIES_DATA,
  SET_LIBRARY_DETAIL_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  singleElibrary: {},
  eLibraries: {},
};

const elibrariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ELIBRARIES_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_ELIBRARIES_DATA: {
      return {
        ...state,
        eLibraries: action.payload,
      };
    }
    case SET_LIBRARY_DETAIL_DATA: {
      return {
        ...state,
        singleElibrary: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        singleElibrary: {
          ...state.singleElibrary,
          downloadCount: (state.singleElibrary?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default elibrariesReducer;
