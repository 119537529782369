import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  LAW_LOADER,
  SET_LAW_TYPES_DATA,
  SET_LAW_PAGE_DATA,
  DOWNLOAD_COUNT,
  SET_SINGLE_LAW_TYPE,
} from "../actionTypes";

export const getLawPageData = () => async (dispatch) => {
  try {
    dispatch({
      type: LAW_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", "/cms/law");
    if (res?.data) {
      dispatch({
        type: SET_LAW_PAGE_DATA,
        payload: res?.data?.data,
      });
    }
    dispatch({
      type: LAW_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: LAW_LOADER,
      payload: false,
    });
  }
};
export const getLawTypesData =
  (page = 1, limit = 9) =>
  async (dispatch) => {
    try {
      dispatch({
        type: LAW_LOADER,
        payload: true,
      });

      const res = await apiHelper(
        "get",
        `/cms/law-type?page=${page}&limit=${limit}`,
      );
      if (res?.data) {
        dispatch({
          type: SET_LAW_TYPES_DATA,
          payload: res?.data?.data,
        });
      }
      dispatch({
        type: LAW_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: LAW_LOADER,
        payload: false,
      });
    }
  };

export const getSingleLawType = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LAW_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/law-type/${id}`);
    if (res?.data) {
      dispatch({
        type: SET_SINGLE_LAW_TYPE,
        payload: res?.data?.data,
      });
    }
    dispatch({
      type: LAW_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: LAW_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LAW_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/law/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: LAW_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: LAW_LOADER,
      payload: false,
    });
  }
};
