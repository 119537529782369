import React, { useState } from "react";
import { Image, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import { Images } from "../../../assets";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNavClick = (event) => {
    const target = event.target;

    // Close sidebar only if it's a direct navigation link
    if (target.tagName === "A" && !target.closest(".dropdown-toggle")) {
      handleClose();
    }
  };
  return (
    <>
      <div className="toggle-icon" role="button" onClick={handleShow}>
        <Image src={Images.toggleIcon} loading="lazy" fluid />
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header
          closeButton
          className="p-lg-4 p-3 justify-content-end"
        >
          {/* <Offcanvas.Title className='text-blue-1' id="offcanvasNavbarLabel-expand-false">
                                    </Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 px-sm-4">
          <Nav className="ms-auto mb-4 d-lg-none" onClick={handleNavClick}>
            <Nav.Link as={Link} to="/home">
              Home
            </Nav.Link>
            <NavDropdown title="About Us">
              <NavDropdown.Item as={Link} to="/about-us">
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Chairman message
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                General secretary message
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Committee members
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Work teams
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Partners
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Arbitration in the UAE
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/jury-panel">
                                Jury Panel
                              </NavDropdown.Item> */}
              {/* <NavDropdown.Item as={Link} to="/about-laws">
                                The Laws
                              </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="Services">
              <NavDropdown.Item as={Link} to="/services">
                Services
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/arbitration">
                Arbitration
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/mediation">
                Mediation
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Calculator fees
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                A-la-carte service
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/departments-Of-Center">
                                Departments Of The Center
                              </NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/center-tables">
                                Center Tables
                              </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="Arbitrators">
              <NavDropdown.Item as={Link} to="#">
                Apply- arbitrator
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Apply- expert
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#">
                Code of Conducts
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/departments-Of-Center">
                                Departments Of The Center
                              </NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/center-tables">
                                Center Tables
                              </NavDropdown.Item> */}
            </NavDropdown>
            {/* <Nav.Link as={Link} to="/media-center">
                              Media Center
                            </Nav.Link> */}
            <NavDropdown title="Media Center">
              <NavDropdown.Item as={Link} to="/media-center">
                Media Center
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/news">
                News
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/photos">
                Gallery
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/videos">
                Videos
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/events">
                Events
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/workshops">
                Workshops
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/courses">
                                Training Courses
                              </NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/seminars">
                                Seminars & Conferences
                              </NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/libraries">
                                Electornic Library
                              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link as={Link} to="/about-laws">
              Law library
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-us">
              Contact us
            </Nav.Link>
          </Nav>
          <h4 className="text-blue-2 mb-3">Useful Link</h4>
          <Nav
            className="justify-content-end flex-grow-1 pe-3"
            onClick={handleNavClick}
          >
            <Nav.Link as={Link} to="/Faqs">
              Frequently Asked Questions
            </Nav.Link>
            <Nav.Link as={Link} to="#">
              Advertisement Areas
            </Nav.Link>
            <Nav.Link as={Link} to="#">
              Terms And Conditions
            </Nav.Link>
            <Nav.Link as={Link} to="#">
              Privacy Policy
            </Nav.Link>
            <Nav.Link as={Link} to="#">
              Usage Policy
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
