import {
  VIDEOS_LOADER,
  DOWNLOAD_COUNT,
  SET_VIDEO_DETAIL_DATA,
  SET_VIDEOS_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";

export const getVideos =
  (page = 1, limit = 10, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: VIDEOS_LOADER,
        payload: true,
      });
      const queryParams = new URLSearchParams({
        page,
        limit,
        ...filters,
      }).toString();
      const res = await apiHelper(
        "get",
        `/media/album/list?type=video&${queryParams}`,
      );
      if (res.data) {
        dispatch({
          type: SET_VIDEOS_DATA,
          payload: res.data.data,
        });
      }
      dispatch({
        type: VIDEOS_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: VIDEOS_LOADER,
        payload: false,
      });
    }
  };
export const getVideoDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEOS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/album/${id}`);
    if (res.data) {
      dispatch({
        type: SET_VIDEO_DETAIL_DATA,
        payload: res.data.data,
      });
    }
    dispatch({
      type: VIDEOS_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: VIDEOS_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEOS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/videos/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: VIDEOS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: VIDEOS_LOADER,
      payload: false,
    });
  }
};
