import React from "react";
import "./newsCard.css";
import { Col, Image } from "react-bootstrap";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const NewsCard = ({ id, img, preTitle, title, content, loader }) => {
  return (
    <Col lg={6} className="mb-3">
      <div className="news-article-wrapper d-flex h-100" role="button">
        <div className="news-article-img">
          {loader ? (
            <Skeleton height={"100%"} width={"100%"} />
          ) : (
            <Image
              src={img}
              fluid
              alt="article image"
              loading="lazy"
              className="h-100 w-100"
            />
          )}
        </div>
        <div className="news-article-content">
          <div className="upper-sec">
            <span className="pre-heading d-inline-block mb-2">
              {loader ? <Skeleton width={150} /> : preTitle}
            </span>
            <h4 className="mb-lg-3 mb-2">
              {loader ? <Skeleton count={2} /> : title}
            </h4>
            {loader ? (
              <>
                <Skeleton width={"80%"} />
                <Skeleton width={"90%"} />
                <Skeleton width={"85%"} />
              </>
            ) : (
              <div
                className="ck-content-wrapper mb-lg-3 mb-2"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
          <Link
            to={`/detail/${id}`}
            className="details-arrow float-end d-flex align-items-center justify-content-end gap-2 text-decoration-none"
            role="button"
          >
            {loader ? (
              <Skeleton height={20} width={100} />
            ) : (
              <>
                Details
                <FontAwesomeIcon icon={faArrowRightLong} />
              </>
            )}
          </Link>
        </div>
      </div>
    </Col>
  );
};

export default NewsCard;
