import {
  SET_ABOUT_US_DATA,
  ABOUT_US_LOADER,
  SET_ABOUT_US_HOME_DATA,
  DOWNLOAD_COUNT,
} from "../actionTypes";

const initialState = {
  loader: false,
  aboutUsData: {},
  aboutUsHomeData: {},
};

const aboutUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_US_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_ABOUT_US_DATA: {
      return {
        ...state,
        aboutUsData: action.payload,
      };
    }
    case SET_ABOUT_US_HOME_DATA: {
      return {
        ...state,
        aboutUsHomeData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        aboutUsData: {
          ...state.aboutUsData,
          downloadCount: (state.aboutUsData?.downloadCount || 0) + 1,
        },
      };
    }

    default:
      return state;
  }
};

export default aboutUsReducer;
