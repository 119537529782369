import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLawPageData, getSingleLawType } from "../../redux/laws/action";
import AboutLaws from "../aboutLaws/aboutLaws";
import MetaTags from "../../components/metaTags/seoTags";
import Banner from "../../components/common/banner/banner";
import Detail from "../../components/common/detail/detail";
import PageStats from "../../components/pageStats/pageStats";
import ActionFeedback from "../../components/common/takeActions/actionFeedback";

export default function LawDetail() {
  const dispatch = useDispatch();
  const law = useSelector((state) => state.law);
  const pageData = law.lawPageData;
  const { id } = useParams();
  useEffect(() => {
    if (!law.law?.id) dispatch(getLawPageData());
    dispatch(getSingleLawType(id));
  }, [id]);

  return (
    <>
      <MetaTags
        title={pageData?.pageTitle}
        description={pageData?.metaDescription}
        keywords={pageData?.metaKeywords}
        metaTitle={pageData?.metaTitle}
      />
      <Banner
        img={pageData?.bannerImage}
        title={pageData?.heading}
        content={pageData?.summary}
        loader={law.loader}
      />
      <Detail
        {...law.singleLaw}
        image={law.singleLaw?.lawImage}
        loader={law.loader}
      />
      <AboutLaws limit={3} />
      <PageStats
        // countAction={updateDownloadCount}
        downloadCount={pageData?.downloadCount}
        viewCount={pageData?.pageViewCount}
        pdf={pageData?.pdfFile}
        id={pageData?.id}
        loader={law.loader}
      />
      <ActionFeedback />
    </>
  );
}
