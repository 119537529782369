import React, { useEffect } from "react";
import Banner from "../../components/common/banner/banner";
import { Images } from "../../assets";
import Procedures from "../juryPanel/procedures/procedures";
import Membership from "../juryPanel/membership/membership";
import { useDispatch, useSelector } from "react-redux";
import { getRoleOfCenterData } from "../../redux/role-of-center/action";
import MetaTags from "../../components/metaTags/seoTags";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";

const RoleOfTheCenter = () => {
  const dispatch = useDispatch();
  const roleOfCenter = useSelector((state) => state.roleOfCenter);

  useEffect(() => {
    if (!roleOfCenter.pageData?.id) dispatch(getRoleOfCenterData());
  }, []);

  return (
    <>
      {roleOfCenter.loader ? (
        <FullPageLoader />
      ) : (
        <React.Fragment>
          <MetaTags
            title={roleOfCenter.pageData?.pageTitle}
            metaTitle={roleOfCenter.pageData?.metaTitle}
            description={roleOfCenter.pageData?.metaDescription}
            keywords={roleOfCenter.pageData?.metaKeywords}
          />
          <Banner
            img={roleOfCenter.pageData?.image}
            title={roleOfCenter.pageData?.heading}
            content={roleOfCenter.pageData?.subHeading}
            loader={roleOfCenter?.loader}
          />
          <Procedures
            detail={roleOfCenter.pageData?.procedureDetail}
            heading={roleOfCenter.pageData?.procedureHeading}
            image={roleOfCenter.pageData?.procedureImage}
          />
          <Membership
            detail={roleOfCenter.pageData?.membershipDetail}
            heading={roleOfCenter.pageData?.membershipHeading}
            image={roleOfCenter.pageData?.membershipImage}
            subHeading={roleOfCenter.pageData?.membershipSubHeading}
          />
        </React.Fragment>
      )}
    </>
  );
};

export default RoleOfTheCenter;
