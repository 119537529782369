import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { downloadCount, getInfoPage } from "../../redux/media-center/action";
import MetaTags from "../../components/metaTags/seoTags";
import ActionFeedback from "../../components/common/takeActions/actionFeedback";
import PageStats from "../../components/pageStats/pageStats";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  const media = useSelector((state) => state.media);
  const policy = media.infoPage;
  useEffect(() => {
    dispatch(getInfoPage("Privacy Policy"));
  }, []);
  return (
    <>
      <MetaTags
        title={policy?.pageTitle}
        description={policy?.metaDescription}
        keywords={policy?.metaKeywords}
        metaTitle={policy?.metaTitle}
      />
      <section className="privacy-policy legal-information-page no-banner-page">
        <Container>
          <span className="h2">{policy.heading}</span>
          <div
            className="ck-content-wrapper mb-lg-3 mb-2"
            dangerouslySetInnerHTML={{ __html: policy.description }}
          />
        </Container>
      </section>
      <PageStats
        countAction={downloadCount}
        downloadCount={policy?.downloadCount}
        viewCount={policy?.pageViewCount}
        pdf={policy?.pdfFile}
        id={policy?.id}
        loader={policy?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default PrivacyPolicy;
