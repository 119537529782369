import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  DOWNLOAD_COUNT,
  SERVICE_LOADER,
  SET_SERVICES_DATA,
  SET_SERVICES_PAGE_DATA,
  SET_SINGLE_SERVICE_DATA,
} from "../actionTypes";

export const getServicesData =
  (page = 1, limit = 9) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SERVICE_LOADER,
        payload: true,
      });
      const res = await apiHelper(
        "get",
        `/cms/service?page=${page}&limit=${limit}`,
      );
      if (res?.data) {
        dispatch({
          type: SET_SERVICES_DATA,
          payload: res?.data?.data,
        });
      }
      dispatch({
        type: SERVICE_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: SERVICE_LOADER,
        payload: false,
      });
    }
  };
export const getServicesPageData = () => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_LOADER,
      payload: true,
    });
    const res = await apiHelper("get", "/cms/service-page");
    if (res?.data) {
      dispatch({
        type: SET_SERVICES_PAGE_DATA,
        payload: res?.data?.data?.service,
      });
    }
    dispatch({
      type: SERVICE_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: SERVICE_LOADER,
      payload: false,
    });
  }
};
export const getSingleService = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_LOADER,
      payload: true,
    });
    const res = await apiHelper("get", `/cms/service/${id}`); //change in future
    if (res?.data) {
      dispatch({
        type: SET_SINGLE_SERVICE_DATA,
        payload: res?.data?.data,
      });
    }
    dispatch({
      type: SERVICE_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: SERVICE_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/service-page/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: SERVICE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: SERVICE_LOADER,
      payload: false,
    });
  }
};
