import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { Images } from "../../assets";
import AOS from "aos";
import "./FullPageLoader.css";

const FullPageLoader = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="center-body">
      <Image
        fluid
        src={Images.Loader}
        width={300}
        loading="lazy"
        alt="loader"
      />
    </div>
  );
};

export default FullPageLoader;
