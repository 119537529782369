import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServicesCard from "../../../components/common/servicesCard/servicesCard";
import servicesList1 from "../../../assets/images/services-slide1.png";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { getServicesData } from "../../../redux/services/action";
import { useDispatch } from "react-redux";

const ListOfServices = ({
  servicesData,
  servicesListDescription,
  servicesListHeading,
  limit,
  loader,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };
  useEffect(() => {
    dispatch(getServicesData(currentPage, limit));
  }, [currentPage]);

  return (
    <section className="list-of-services pb-5">
      <Container className="custom-container">
        <div className="heading-sec text-center mb-xl-5 mb-md-4 mb-3 px-xl-5 pe-lg-4">
          <h2 className="mb-3">{servicesListHeading}</h2>
          <div
            className="ck-content-wrapper mb-0"
            dangerouslySetInnerHTML={{ __html: servicesListDescription }}
          ></div>
        </div>
        <Row>
          {servicesData?.services?.length > 0 &&
            servicesData.services.slice(0, limit).map((service) => (
              <Col key={service?.id} lg={4} sm={6} className="mb-4">
                <ServicesCard
                  img={service?.serviceImage || servicesList1}
                  preTitle={service?.heading}
                  title={service?.subHeading}
                  content={service?.summary}
                  link={"services"}
                  id={service?.id}
                />
              </Col>
            ))}
        </Row>
        {limit > 3 ? (
          loader ? (
            <Skeleton height={40} width={200} className="mx-auto mt-4" />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={servicesData?.totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : (
          <div className="text-end mt-4">
            {loader ? (
              <Skeleton width={120} height={40} />
            ) : (
              <Link to={`/services`} className="btn btn-solid-blue">
                View All
              </Link>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default ListOfServices;
