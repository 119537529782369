export function calculateFees(amount) {
  if (!amount || amount <= 0 || isNaN(amount)) return null;

  let claimFee = Math.ceil(amount * 0.05);
  claimFee = Math.max(5000, Math.min(claimFee, 30000));

  let adminfeesMin = claimFee;
  let adminfeesMax = 30000;
  let adminfeesAvg = (claimFee + 30000) / 2;

  let min = 5000,
    max = 30000;
  let secretaryFee = calculateValue(amount);
  let avarage = 0;

  if (amount <= 100000) {
    min = 7500;
    max = 12000;
    adminfeesMin = 2000;
  } else if (amount <= 300000) {
    min = 10000 + Math.ceil((amount - 100001) * 0.02);
    max = 25000 + Math.ceil((amount - 100001) * 0.05);
    adminfeesMin = 4000;
    secretaryFee = 3500;
  } else if (amount <= 700000) {
    min = 6000 + Math.ceil((amount - 300001) * 0.01);
    max = 20000 + Math.ceil((amount - 300001) * 0.05);
    adminfeesMin = 7500;
  } else if (amount <= 1000000) {
    min = 18000 + Math.ceil((amount - 700001) * 0.008);
    max = 42000 + Math.ceil((amount - 700001) * 0.025);
    adminfeesMin = 10000;
  } else if (amount <= 2500000) {
    min = 20000 + Math.ceil((amount - 1000001) * 0.003);
    max = 55000 + Math.ceil((amount - 700001) * 0.04);
    adminfeesMin = 12500;
  } else if (amount <= 5000000) {
    min = 22500 + Math.ceil((amount - 2500001) * 0.0045);
    max = 110000 + Math.ceil((amount - 2500001) * 0.025);
    adminfeesMin = 20000;
  } else if (amount <= 10000000) {
    min = 35000 + Math.ceil((amount - 5000001) * 0.003);
    max = 182000 + Math.ceil((amount - 5000001) * 0.018);
    adminfeesMin = 30000;
  } else if (amount <= 30000000) {
    min = 30000 + Math.ceil((amount - 10000001) * 0.005);
    max = 170000 + Math.ceil((amount - 10000001) * 0.02);
    adminfeesMin = 32000;
  } else if (amount <= 50000000) {
    min = 22500 + Math.ceil((amount - 30000001) * 0.0045);
    max = 190000 + Math.ceil((amount - 30000001) * 0.015);
    adminfeesMin = 40000;
  } else if (amount <= 80000000) {
    min = 90000 + Math.ceil((amount - 50000001) * 0.003);
    max = 250000 + Math.ceil((amount - 50000001) * 0.005);
    adminfeesMin = 42000;
  } else if (amount <= 100000000) {
    min = 170000 + Math.ceil((amount - 80000001) * 0.003);
    max = 480000 + Math.ceil((amount - 80000001) * 0.004);
  } else if (amount <= 150000000) {
    min = 200000 + Math.ceil((amount - 100000001) * 0.002);
    max = 550000 + Math.ceil((amount - 100000001) * 0.003);
  } else if (amount <= 200000000) {
    min = 250000 + Math.ceil((amount - 150000001) * 0.001);
    max = 1100000 + Math.ceil((amount - 150000001) * 0.0012);
  } else if (amount <= 250000000) {
    min = 285000 + Math.ceil((amount - 200000001) * 0.0009);
    max = 1395000 + Math.ceil((amount - 200000001) * 0.0011);
  } else {
    min = 320000 + Math.ceil((amount - 250000001) * 0.0001);
    max = 1450000 + Math.ceil((amount - 250000001) * 0.0002);
  }

  if (amount > 80000000) adminfeesMin = 50000;
  avarage = (min + max) / 2;
  if (amount <= 100000) avarage = 7500;
  adminfeesAvg = adminfeesMax = adminfeesMin;

  return {
    min,
    avarage,
    max,
    adminfeesMin,
    adminfeesAvg,
    adminfeesMax,
    secretaryFee,
    totalMin: min + adminfeesMin + secretaryFee,
    totalMax: max + adminfeesMax + secretaryFee,
    totalAvg: avarage + adminfeesAvg + secretaryFee,
  };
}

function calculateValue(amount) {
  const ranges = [
    { min: 1, max: 100000, value: 3000 },
    { min: 100001, max: 300000, value: 3500 },
    { min: 300001, max: 500000, value: 4000 },
    { min: 500001, max: 700000, value: 4500 },
    { min: 700001, max: 900000, value: 5000 },
    { min: 900001, max: 1000000, value: 5500 },
    { min: 1000001, max: 2500000, value: 6000 },
    { min: 2500001, max: 4000000, value: 7000 },
    { min: 4000001, max: 5500000, value: 8000 },
    { min: 5500001, max: 7000000, value: 9000 },
    { min: 7000001, max: 30000000, value: 10000 },
    { min: 30000001, max: 50000000, value: 10000 },
    { min: 50000001, max: 80000000, value: 10000 },
  ];
  for (const range of ranges) {
    if (amount >= range.min && amount <= range.max) return range.value;
  }
  return 0;
}
