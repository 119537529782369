import React from "react";
import CountUp from "react-countup";
import { useDispatch } from "react-redux";
import { Toast } from "../../config/config";
import Skeleton from "react-loading-skeleton";
import "./pageStats.css";
import { Col, Container, Row } from "react-bootstrap";

export default function PageStats({
  loader,
  countAction,
  downloadCount,
  viewCount,
  pdf,
  id,
}) {
  const dispatch = useDispatch();

  const handleDownloadPdf = () => {
    countAction && dispatch(countAction(id));
    const fileUrl = pdf;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileUrl.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => Toast.fire({ icon: "error", title: error }));
  };

  return (
    <section className="stats-section text-white">
      <Container className="custom-container">
        <Row className="align-items-center">
          <Col md={4} sm={6}>
            <div className="stat-item text-center mb-md-0 mb-4">
              <h3 className="stat-label mb-3">Service Recipients</h3>
              <div className="stat-value">
                {loader ? (
                  <Skeleton width={50} height={40} />
                ) : (
                  <CountUp start={0} end={viewCount} duration={2} />
                )}
              </div>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="stat-item text-center mb-md-0 mb-4">
              <h3 className="stat-label mb-3">Rated Content</h3>
              <div className="stat-value">
                {loader ? (
                  <Skeleton width={50} height={40} />
                ) : (
                  <CountUp start={0} end={downloadCount} duration={2} />
                )}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="text-center">
              <button
                className="download-button btn-solid-blue"
                onClick={handleDownloadPdf}
              >
                Download PDF
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
