import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./procedures.css";
import { Images } from "../../../assets";

const Procedures = ({ detail, heading, image }) => {
  return (
    <section className="procedure-followed">
      <Container className="custom-container">
        <Row className="align-items-center flex-lg-row flex-column-reverse">
          <Col lg={7}>
            <div className="content-wrapper">
              <h2 className="mb-lg-4 mb-3">{heading} </h2>
              <p
                className="p-lg"
                dangerouslySetInnerHTML={{ __html: detail }}
              ></p>
            </div>
          </Col>
          <Col lg={5}>
            <div className="position-relative text-end mb-lg-0 mb-sm-5 mb-4">
              <div className="meeting">
                <Image src={image} alt="meeting img" loading="lazy" fluid />
              </div>
              <div className="al-logo position-absolute">
                <Image src={Images.alLogo} fluid alt="al logo" loading="lazy" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Procedures;
