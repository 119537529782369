import {
  MEDIA_CENTER_LOADER,
  SET_INFO,
  SET_MEDIA_CENTER,
  DOWNLOAD_COUNT,
  GET_INFO,
  DOWNLOAD_POLICY_COUNT,
} from "../actionTypes";

const initialState = {
  loader: false,
  info: [],
  mediaCenter: {},
  infoPage: {},
};

const mediaCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_CENTER_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_INFO: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case GET_INFO: {
      return {
        ...state,
        infoPage: action.payload,
      };
    }
    case SET_MEDIA_CENTER: {
      return {
        ...state,
        mediaCenter: action.payload,
      };
    }
    case DOWNLOAD_POLICY_COUNT: {
      return {
        ...state,
        infoPage: {
          ...state.infoPage,
          downloadCount: (state.infoPage?.downloadCount || 0) + 1,
        },
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        mediaCenter: {
          ...state.mediaCenter,
          mediaCenter: {
            ...state.mediaCenter.mediaCenter,
            downloadCount:
              (state.mediaCenter.mediaCenter?.downloadCount || 0) + 1,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default mediaCenterReducer;
