import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMediaPage } from "../../../redux/media-center/action";
import MetaTags from "../../../components/metaTags/seoTags";
import Banner from "../../../components/common/banner/banner";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import {
  getCmsPage,
  updateDownloadCount,
} from "../../../redux/cms-pages/action";
import { getWorkshops } from "../../../redux/workshop/action";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import Workshop from "./workshop";
import WorkshopFilters from "./workshopsFilter";

export default function WorkshopsList() {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const { pageData } = useSelector((state) => state.cmsPages);
  const workshops = useSelector((state) => state.workshops);
  const workshopsData = workshops?.workshops;
  const mediaPageContent = media.mediaCenter?.mediaCenter;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    heading: "",
    location: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getCmsPage("Workshops"));
  }, [mediaPageContent]);

  useEffect(() => {
    dispatch(getWorkshops(currentPage, 9));
  }, [currentPage]);

  return (
    <>
      {workshops.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={pageData?.pageTitle}
            description={pageData?.metaDescription}
            keywords={pageData?.metaKeywords}
            metaTitle={pageData?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <WorkshopFilters filters={filters} setFilters={setFilters} />
          <Workshop
            heading={mediaPageContent?.workshopsHeading}
            limit={9}
            workshops={workshopsData?.workshops}
            onPageChange={setCurrentPage}
            currentPage={currentPage}
            totalPages={workshopsData.totalPages || 1}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={pageData?.downloadCount}
            viewCount={pageData?.pageViewCount}
            pdf={pageData?.pdfFile}
            id={pageData?.id}
            loader={pageData?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
