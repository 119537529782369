import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "../../../components/metaTags/seoTags";
import Banner from "../../../components/common/banner/banner";
import Detail from "../../../components/common/detail/detail";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import {
  getServicesPageData,
  getSingleService,
} from "../../../redux/services/action";
import ListOfServices from "../listOfServices/listOfServices";

export default function ServiceDetail() {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const pageData = services.servicesPageData;
  const { id } = useParams();
  useEffect(() => {
    if (!services.servicesPageData?.id) dispatch(getServicesPageData());
    dispatch(getSingleService(id));
  }, [id]);

  return (
    <>
      <MetaTags
        title={pageData?.pageTitle}
        description={pageData?.metaDescription}
        keywords={pageData?.metaKeywords}
        metaTitle={pageData?.metaTitle}
      />
      <Banner
        img={pageData?.serviceImage}
        title={pageData?.title}
        content={pageData?.description}
        loader={services.loader}
      />
      <Detail
        {...services.singleServiceData}
        image={services.singleServiceData?.serviceImage}
        loader={services.loader}
      />
      <ListOfServices
        servicesData={services?.servicesData}
        {...pageData}
        limit={3}
        loader={services.loader}
      />
      <PageStats
        // countAction={updateDownloadCount}
        downloadCount={pageData?.downloadCount}
        viewCount={pageData?.pageViewCount}
        pdf={pageData?.pdfFile}
        id={pageData?.id}
        loader={services.loader}
      />
      <ActionFeedback />
    </>
  );
}
