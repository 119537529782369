import React, { lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./utils/protectedRoutes/protectedRoutes";
import AdminLayout from "./layouts/adminLayout";
import DefaultLayout from "./layouts/defaultLayout";
import AuthLayout from "./layouts/authLayout";
import Faqs from "./pages/faqs/faqs";

import JuryPanel from "./pages/juryPanel";
import RoleOfTheCenter from "./pages/roleOfCenter";
import MediaCenterLayout from "./pages/mediaCenter/mediaCenterlayout";
import LatestNews from "./components/common/latestNews/latestNews";
import LegalAdvice from "./pages/mediaCenter/legalAdvice/legalAdvice";
import NewsDetail from "./pages/news/newsDetail/newsDetail";
import TraningCoursesList from "./pages/mediaCenter/trainingCourses/traningCoursesList";
import TraningCourseDetail from "./pages/mediaCenter/trainingCourses/trainingCourseDetail";
import SeminarsList from "./pages/mediaCenter/seminars/seminarsListing";
import SeminarsDetail from "./pages/mediaCenter/seminars/seminarsDetail";
import EventsList from "./pages/events";
import EventDetail from "./pages/events/eventDetail/eventDetail";
import WorkshopsList from "./pages/mediaCenter/workshop/workshopsList";
import WorkshopDetail from "./pages/mediaCenter/workshop/workshopsDetail";
import LibraryList from "./pages/mediaCenter/electronicLibrary/electronicLibraryListing";
import LibraryDetail from "./pages/mediaCenter/electronicLibrary/electronicLibraryDetail";
import PhotosList from "./pages/mediaCenter/photoAlbum/photoAlbumList";
import PhotoDetail from "./pages/mediaCenter/photoAlbum/photoAlbumDetail";
import VideosList from "./pages/mediaCenter/videos/videosListPage";
import VideoDetail from "./pages/mediaCenter/videos/videosDetail";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import TermsAndCondition from "./pages/mediaCenter/termsAndCondtion/termsAndCondtion";
import RefundCancellationPolicy from "./pages/mediaCenter/legalInformation/refundCancellationPolicy/refundCancellationPolicy";
import ComplianceApplicableLaws from "./pages/mediaCenter/legalInformation/complianceApplicableLaws/complianceApplicableLaws";
import ArbitrationClause from "./pages/mediaCenter/legalInformation/arbitrationClause/arbitrationClause";
import IntellectualPropertyNotice from "./pages/mediaCenter/legalInformation/intellectualPropertyNotice/intellectualPropertyNotice";
import ServiceDetail from "./pages/services/serviceDetailPage/serviceDetail";
import LawDetail from "./pages/laws/lawDetails";
import ArbitrationForm from "./pages/services/servicesForms/arbitrationForm";
import { MediationForm } from "./pages/services/servicesForms/mediationForm";
import CalculatorFees from "./pages/calculatorFees/calculatorFees";

// Lazy-loaded components
const NotFound = lazy(() => import("./components/404Page/404Page"));
const Login = lazy(() => import("./components/login/login"));
const Profile = lazy(
  () => import("./components/userDashboard/profile/profile"),
);
const Register = lazy(() => import("./components/register/register"));
const Dashboard = lazy(() => import("./components/userDashboard/dashboard"));
const Home = lazy(() => import("./pages/home"));
const LoginForm = lazy(() => import("./pages/auth/LoginForm"));
const AboutUs = lazy(() => import("./pages/aboutUs"));
const News = lazy(() => import("./pages/news"));
const MediaCenter = lazy(() => import("./pages/mediaCenter"));
const AboutLaws = lazy(() => import("./pages/aboutLaws/index"));
// const JuryPanel = lazy(() => import("./pages/juryPanel"));
const Services = lazy(() => import("./pages/services"));
const ArbitrationPage = lazy(() => import("./pages/arbitration"));
const DepartmentsOfCenter = lazy(() => import("./pages/departmentsOfCenter"));
const Mediation = lazy(() => import("./pages/mediation"));
const ResetPasswordComponent = lazy(
  () => import("./components/resetPassword/resetPassword"),
);
const E_Services = lazy(
  () => import("./components/userDashboard/e-services/e-services"),
);
const Arbitration = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/request/arbitration/arbitration"
    ),
);
const CertifiedCopy = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/request/certifiedCopy/certifiedCopy"
    ),
);
const Interpretation = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/request/interpretation/interpretation"
    ),
);
const Memberships = lazy(
  () => import("./components/userDashboard/e-services/memberships/memberships"),
);
const ExpertRegistration = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/memberships/expertComponent/expertRegistration/expertRegistration"
    ),
);
const ArbitratorRegistration = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/memberships/arbitratorComponent/arbitratorRegistration/arbitratorRegistraion"
    ),
);
const ArbitratorDashboard = lazy(
  () => import("./components/arbitrator/dashboard/dashboard"),
);
const ArbitratorProfile = lazy(
  () => import("./components/arbitrator/dashboard/profile.js/profile"),
);
const Intervention = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/request/intervention/intervention"
    ),
);
const Joinder = lazy(
  () => import("./components/userDashboard/e-services/request/joinder/joinder"),
);
const Claim = lazy(
  () => import("./components/userDashboard/e-services/request/claim/claim"),
);
const UserApplication = lazy(
  () =>
    import(
      "./components/arbitrator/dashboard/applications/userApplications/userApplication"
    ),
);
const ApplicationStatus = lazy(
  () =>
    import(
      "./components/arbitrator/dashboard/applications/userApplications/applicationStatus"
    ),
);
const CenterTable = lazy(() => import("./pages/centerTables"));
const Objection = lazy(
  () =>
    import("./components/userDashboard/e-services/request/objection/objection"),
);
const Revocation = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/request/revocation/revocation"
    ),
);
const Certificate = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/request/certificate/certificate"
    ),
);
const Membership = lazy(() => import("./pages/membership"));
const NonListedArbitrator = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/nonListedArbitrator/nonListedArbitrator"
    ),
);
const ArbitratorNotGoverned = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/arbitratorNotGoverned/arbitratorNotGoverned"
    ),
);
const ContactUs = lazy(() => import("./pages/contactUs"));
const MembershipRegistration = lazy(
  () =>
    import(
      "./components/userDashboard/e-services/memberships/membershipRegistration/membershipRegistration"
    ),
);
const Events = lazy(() => import("./pages/events/events"));

function Routes() {
  const router = createBrowserRouter([
    {
      element: <AuthLayout />,
      errorElement: <Navigate to="/login" />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/login2",
          element: <LoginForm />,
        },
        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/resetPassword",
          element: <ResetPasswordComponent />,
        },
      ],
    },
    {
      element: <DefaultLayout />,
      errorElement: <Navigate to="/login" />,
      children: [
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/events",
          element: <EventsList />,
        },
        {
          path: "/event/:id",
          element: <EventDetail />,
        },
        {
          path: "/news",
          element: <News />,
        },
        {
          path: "/detail/:id",
          element: <NewsDetail />,
        },
        {
          path: "/media-center",
          element: <MediaCenter />,
        },
        {
          path: "/courses",
          element: <TraningCoursesList />,
        },
        {
          path: "/courses/:id",
          element: <TraningCourseDetail />,
        },
        {
          path: "/seminars",
          element: <SeminarsList />,
        },
        {
          path: "/seminars/:id",
          element: <SeminarsDetail />,
        },
        {
          path: "/workshops",
          element: <WorkshopsList />,
        },
        {
          path: "/workshops/:id",
          element: <WorkshopDetail />,
        },
        {
          path: "/libraries",
          element: <LibraryList />,
        },
        {
          path: "/libraries/:id",
          element: <LibraryDetail />,
        },
        {
          path: "/photos",
          element: <PhotosList />,
        },
        {
          path: "/photos/:id",
          element: <PhotoDetail />,
        },
        {
          path: "/videos",
          element: <VideosList />,
        },
        {
          path: "/videos/:id",
          element: <VideoDetail />,
        },
        {
          path: "/about-laws",
          element: <AboutLaws />,
        },
        {
          path: "/laws/:id",
          element: <LawDetail />,
        },
        {
          path: "/jury-panel",
          element: <JuryPanel />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/services/:id",
          element: <ServiceDetail />,
        },
        {
          path: "/arbitration-form",
          element: <ArbitrationForm />,
        },
        {
          path: "/mediation-form",
          element: <MediationForm />,
        },
        {
          path: "/arbitration",
          element: <ArbitrationPage />,
        },
        {
          path: "/mediation",
          element: <Mediation />,
        },
        {
          path: "/calculator-fees",
          element: <CalculatorFees />,
        },
        {
          path: "/center-tables",
          element: <CenterTable />,
        },
        {
          path: "membership",
          element: <Membership />,
        },
        {
          path: "departments-Of-Center",
          element: <DepartmentsOfCenter />,
        },
        {
          path: "faqs",
          element: <Faqs />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "/role-of-center",
          element: <RoleOfTheCenter />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndCondition />,
        },
        {
          path: "/refund-cancellation-policy",
          element: <RefundCancellationPolicy />,
        },
        {
          path: "/compliance-applicable-laws",
          element: <ComplianceApplicableLaws />,
        },
        {
          path: "/arbitration-clause",
          element: <ArbitrationClause />,
        },
        {
          path: "/intellectual-property-notice",
          element: <IntellectualPropertyNotice />,
        },
      ],
    },
    {
      element: <AdminLayout />,
      children: [
        {
          path: "/profile",
          element: (
            // <ProtectedRoute>
            <Profile />
            // </ProtectedRoute>
          ),
        },
        {
          path: "/dashboard",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/arbitration",
              element: (
                // <ProtectedRoute>
                <Arbitration />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/copy",
              element: (
                // <ProtectedRoute>
                <CertifiedCopy />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/interpretation",
              element: (
                // <ProtectedRoute>
                <Interpretation />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/intervention",
              element: (
                // <ProtectedRoute>
                <Intervention />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/joinder",
              element: (
                // <ProtectedRoute>
                <Joinder />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/claim",
              element: (
                // <ProtectedRoute>
                <Claim />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/objection",
              element: (
                // <ProtectedRoute>
                <Objection />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/revocation",
              element: (
                // <ProtectedRoute>
                <Revocation />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/request/certificate",
              element: (
                // <ProtectedRoute>
                <Certificate />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/non-listed/arbitrator",
              element: (
                // <ProtectedRoute>
                <NonListedArbitrator />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/arbitrator/not-governed",
              element: (
                // <ProtectedRoute>
                <ArbitratorNotGoverned />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/membership",
              element: (
                // <ProtectedRoute>
                <Memberships />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/membership/arbitrator",
              element: (
                // <ProtectedRoute>
                <ArbitratorRegistration />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/membership/expert-registration",
              element: (
                // <ProtectedRoute>
                <ExpertRegistration />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services/membership/membership",
              element: (
                // <ProtectedRoute>
                <MembershipRegistration />
                // </ProtectedRoute>
              ),
            },
            {
              path: "e-services",
              element: (
                // <ProtectedRoute>
                <E_Services />
                // </ProtectedRoute>
              ),
            },
            {
              path: "arbitrator",
              element: (
                // <ProtectedRoute>
                <ArbitratorDashboard />
                // </ProtectedRoute>
              ),
            },
            {
              path: "arbitrator/profile",
              element: (
                // <ProtectedRoute>
                <ArbitratorProfile />
                // </ProtectedRoute>
              ),
            },
            {
              path: "arbitrator/application",
              element: (
                // <ProtectedRoute>
                <UserApplication />
                // </ProtectedRoute>
              ),
            },
            {
              path: "arbitrator/application/status",
              element: (
                // <ProtectedRoute>
                <ApplicationStatus />
                // </ProtectedRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Routes;
