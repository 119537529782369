import { toast } from "react-toastify";
import {
  ELIBRARIES_LOADER,
  DOWNLOAD_COUNT,
  SET_LIBRARY_DETAIL_DATA,
  SET_ELIBRARIES_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";

export const getLibrary =
  (page = 1, limit = 10, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ELIBRARIES_LOADER,
        payload: true,
      });
      const filterParams = {
        page: Number(page),
        limit: Number(limit),
      };
      if (filters.title) filterParams.title = filters.title;
      if (filters.startDate) filterParams.startDate = filters.startDate;
      if (filters.endDate) filterParams.endDate = filters.endDate;
      const query = new URLSearchParams(filterParams).toString();

      const res = await apiHelper("get", `/media/libraries/list?${query}`);
      if (res.data) {
        dispatch({
          type: SET_ELIBRARIES_DATA,
          payload: res.data.data,
        });
      }
      dispatch({
        type: ELIBRARIES_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: ELIBRARIES_LOADER,
        payload: false,
      });
    }
  };

export const getLibraryDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ELIBRARIES_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/libraries/${id}`);
    if (res.data) {
      dispatch({
        type: SET_LIBRARY_DETAIL_DATA,
        payload: res.data.data,
      });
    }
    dispatch({
      type: ELIBRARIES_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: ELIBRARIES_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ELIBRARIES_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/libraries/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: ELIBRARIES_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: ELIBRARIES_LOADER,
      payload: false,
    });
  }
};
