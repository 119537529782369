//////////////// Auth ///////////////////

export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const ERR_USER = "ERR_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

////////////// Users /////////////////////

export const UPDATE_USER = "UPDATE_USER";

///////////// E-Services ////////////////

export const CREATE_ARBITRATION = "CREATE_ARBITRATION";
export const CREATE_COPY = "CREATE_COPY";
export const CREATE_CORRECTION_REQUEST = "CREATE_CORRECTION_REQUEST";

///////////// Loader ///////////////////

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

///////////// Arbitrator services //////////////

export const ARBITRATOR_ESERVICES_APPLICATIONS =
  "ARBITRATOR_ESERVICES_APPLICATIONS";
export const ARBITRATOR_SERVICE_COUNT = "ARBITRATOR_SERVICE_COUNT";

////////////// Landing Page //////////////////

export const SET_LANDING_PAGE_DATA = "SET_LANDING_PAGE_DATA";
export const LOADER_ACTIVE = "LOADER_ACTIVE";

////////////// News /////////////////////

export const FETCH_NEWS_LIST = "FETCH_NEWS_LIST";
export const NEWS_LOADER = "NEWS_LOADER";
export const FETCH_SINGLE_NEWS = "FETCH_SINGLE_NEWS";
export const FETCH_NEWS = "FETCH_NEWS";

////////////// Events /////////////////////

export const FETCH_EVENTS_LIST = "FETCH_EVENTS_LIST";
export const EVENTS_LOADER = "EVENTS_LOADER";
export const FETCH_SINGLE_EVENTS = "FETCH_SINGLE_EVENTS";
export const FETCH_EVENTS = "FETCH_EVENTS";

///////////// Jury Panel //////////////

export const SET_JURY_PANEL_DATA = "SET_JURY_PANEL_DATA";
export const JURY_PANEL_LOADER = "JURY_PANEL_LOADER";

//////////// Dowload PDF ///////////////
export const DOWNLOAD_COUNT = "DOWNLOAD_COUNT";
export const DOWNLOAD_POLICY_COUNT = "DOWNLOAD_POLICY_COUNT";

//////////// Department Center /////////////

export const SET_DEPT_CENTER_DATA = "SET_DEPT_CENTER_DATA";
export const DEPT_CENTER_LOADER = "DEPT_CENTER_LOADER";

//////////////// Laws ////////////////////

export const LAW_LOADER = "LAW_LOADER";
export const SET_LAW_TYPES_DATA = "SET_LAW_TYPES_DATA";
export const SET_LAW_PAGE_DATA = "SET_LAW_PAGE_DATA";
export const SET_SINGLE_LAW_TYPE = "SET_SINGLE_LAW_TYPE";

//////////////// Polls ////////////////////

export const POLL_LOADER = "POLL_LOADER";
export const FETCH_POLL_DATA = "FETCH_POLL_DATA";
export const FETCH_LATEST_POLL_RESULT = "FETCH_LATEST_POLL_RESULT";
export const SET_POLL_OPTION = "SET_POLL_OPTION";

//////////// Arbitration //////////

export const ARBITRATION_PAGE_LOADER = "ARBITRATION_PAGE_LOADER";
export const SET_ARBITRATION_PAGE_DATA = "SET_ARBITRATION_PAGE_DATA";

//////////// Site Settings /////////////////

export const SITE_SETTINGS_LOADER = "SITE_SETTINGS_LOADER";
export const SET_SITE_SETTINGS = "SET_SITE_SETTINGS";
export const FETCH_CALCULATOR = "FETCH_CALCULATOR";

//////////////// Services ////////////////

export const SERVICE_LOADER = "SERVICE_LOADER";
export const SET_SERVICES_DATA = "SET_SERVICES_DATA";
export const SET_SINGLE_SERVICE_DATA = "SET_SINGLE_SERVICE_DATA";
export const SET_SERVICES_PAGE_DATA = "SET_SERVICES_PAGE_DATA";

/////////////// Vission /////////////////

export const VISSION_LOADER = "VISSION_LOADER";
export const SET_VISSION_DATA = "SET_VISSION_DATA";

////////////// FAQ //////////////////////

export const SET_FAQ_DATA = "SET_FAQ_DATA";
export const FAQ_FORM_LOADER_ACTIVE = "FAQ_FORM_LOADER_ACTIVE";
export const FAQ_LOADER_ACTIVE = "FAQ_LOADER_ACTIVE";
export const FAQ_FORM_SUBMITTED = "FAQ_FORM_SUBMITTED";

/////////// Center Tables ///////////
export const CENTER_TABLE_LOADER = "CENTER_TABLE_LOADER";
export const SET_CENTER_TABLE_DATA = "SET_CENTER_TABLE_DATA";

///////////// About Us ////////////////
export const ABOUT_US_LOADER = "ABOUT_US_LOADER";
export const SET_ABOUT_US_DATA = "SET_ABOUT_US_DATA";
export const SET_ABOUT_US_HOME_DATA = "SET_ABOUT_US_HOME_DATA";

////////////////////// Mediation ///////////
export const SET_MEDIATION_PAGE_DATA = "MEDIATION_PAGE_LOADER";
export const MEDIATION_PAGE_LOADER = "SET_MEDIATION_PAGE_DATA";

////////////////////// Mediation Request ///////////
export const SET_MEDIATION_REQUEST_PAGE_DATA =
  "SET_MEDIATION_REQUEST_PAGE_DATA";
export const MEDIATION_REQUEST_PAGE_LOADER = "MEDIATION_REQUEST_PAGE_LOADER";

// Feedback
export const FETCH_FEEDBACK = "FETCH_FEEDBACK";
export const FEEDBACK_LOADER = "FEEDBACK_LOADER";
export const CREATE_FEEDBACK = "CREATE_FEEDBACK";

// Favorite
export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";

/////////////// Media Center ///////
export const MEDIA_CENTER_LOADER = "MEDIA_CENTER_LOADER";
export const SET_INFO = "SET_INFO";
export const GET_INFO = "GET_INFO";
export const SET_MEDIA_CENTER = "SET_MEDIA_CENTER";

/////////// Courses //////////
export const COURSES_LOADER = "COURSES_LOADER";
export const SET_COURSES_DATA = "SET_COURSES_DATA";
export const SET_COURSE_DETAIL_DATA = "SET_COURSE_DETAIL_DATA";

////////////// CMS common pages ////////
export const SET_CMS_PAGE_DATA = "SET_CMS_PAGE_DATA";
export const CMS_PAGE_LOADER = "CMS_PAGE_LOADER";

/////////// Seminars ///////////
export const SEMINARS_LOADER = "SEMINARS_LOADER";
export const SET_SEMINARS_DATA = "SET_SEMINARS_DATA";
export const SET_SEMINAR_DETAIL_DATA = "SET_SEMINAR_DETAIL_DATA";

/////////////// Workshops /////////////
export const WORKSHOPS_LOADER = "WORKSHOPS_LOADER";
export const SET_WORKSHOP_DETAIL_DATA = "SET_WORKSHOP_DETAIL_DATA";
export const SET_WORKSHOPS_DATA = "SET_WORKSHOPS_DATA";

//////////// E-Libraries ///////////
export const ELIBRARIES_LOADER = "ELIBRARIES_LOADER";
export const SET_ELIBRARIES_DATA = "SET_ELIBRARIES_DATA";
export const SET_LIBRARY_DETAIL_DATA = "SET_LIBRARY_DETAIL_DATA";

/////////// Photos ////////////
export const PHOTOS_LOADER = "PHOTOS_LOADER";
export const SET_PHOTOS_DATA = "SET_PHOTOS_DATA";
export const SET_PHOTO_DETAIL_DATA = "SET_PHOTO_DETAIL_DATA";

///////////// Videos /////////
export const VIDEOS_LOADER = "VIDEOS_LOADER,";
export const SET_VIDEOS_DATA = "SET_VIDEOS_DATA,";
export const SET_VIDEO_DETAIL_DATA = "SET_VIDEO_DETAIL_DATA,";

/////////// Stats/////////////

export const STATS_LOADER = "STATS_LOADER";
export const SET_STATS_DATA = "SET_STATS_DATA";

///////// Role of center /////////
export const ROLE_OF_CENTER_LOADER = "ROLE_OF_CENTER_LOADER";
export const SET_ROLE_OF_CENTER_DATA = "SET_ROLE_OF_CENTER_DATA";

///////////// Arbitration Form /////////
export const ARBITRATION_FORM_LOADER = "ARBITRATION_FORM_LOADER";
export const ARBITRATION_FORM_SUBMITTED = "ARBITRATION_FORM_SUBMITTED";
