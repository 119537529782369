import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./servicesForms.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { arbitrationFormSchema } from "../../../validation/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { submitArbitrationForm } from "../../../redux/arbitrationRequest/action";

const ArbitrationForm = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.arbitrationForm);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(arbitrationFormSchema),
  });

  const [formData, setFormData] = useState({
    rakmacAuthority: "",
    disputeExceeds200k: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const booleanValue =
      value === "yes" ? true : value === "no" ? false : value;
    setValue(name, booleanValue);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const showRakmacAuthority = formData.rakmacAuthority === "yes";
  const showDisputeExceeds200k = formData.disputeExceeds200k === "yes";

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (key === "documents") return;
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });

    if (data.documents && data.documents.length > 0) {
      Array.from(data.documents).forEach((file, index) => {
        formData.append(`documents`, file);
      });
    }
    dispatch(submitArbitrationForm(formData));
  };

  return (
    <section className="services-form-page arbitration-form no-banner-page">
      <Container>
        <h2 className="text-center mb-4">Arbitration Request Form</h2>
        <div className="form-wrapper">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <h5>RAKMAC Authority?*</h5>
              <Form.Label>
                Does the arbitration clause in your contract (or your separate
                arbitration agreement, or any court order) mention the RAKMAC by
                name (or the RAKMAC Arbitration Rules or any of its appendices)?
              </Form.Label>
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  name="rakmacAuthority"
                  value="yes"
                  checked={formData.rakmacAuthority === "yes"}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  name="rakmacAuthority"
                  value="no"
                  checked={formData.rakmacAuthority === "no"}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
            {formData.rakmacAuthority === "" ? null : showRakmacAuthority ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-medium">
                    Does the sum in dispute exceed SAR 200,000 (excluding the
                    costs to pursue your case such as RAKMAC, arbitrator, and
                    legal fees)?*
                  </Form.Label>
                  <div className="d-flex gap-3">
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      name="disputeExceeds200k"
                      value="yes"
                      checked={formData.disputeExceeds200k === "yes"}
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      name="disputeExceeds200k"
                      value="no"
                      checked={formData.disputeExceeds200k === "no"}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
                {formData.disputeExceeds200k ===
                "" ? null : showDisputeExceeds200k ? (
                  <>
                    {/* Claimant Information */}
                    <h4 className="mb-3">CLAIMANT DETAILS</h4>
                    <Row className="mb-4">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Claimant's Name*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Claimant's Name"
                            {...register("claimantName")}
                            isInvalid={!!errors?.claimantName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Claimant's Nationality*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Claimant's Nationality"
                            {...register("claimantNationality")}
                            isInvalid={!!errors?.claimantNationality}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantNationality?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Additional Claimants' Names
                          </Form.Label>
                          <p className="p-sm mb-1">
                            Please add any additional names precisely as they
                            should appear in the case caption.
                          </p>
                          <Form.Control
                            type="text"
                            placeholder="Enter Additional Claimants' Names"
                            {...register("additionalClaimants")}
                            isInvalid={!!errors?.additionalClaimants}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.additionalClaimants?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Additional Claimants' Nationalities
                          </Form.Label>
                          <p className="p-sm mb-1">
                            Please add the nationality next to each additional
                            Claimant name.
                          </p>
                          <Form.Control
                            type="text"
                            placeholder="Enter Additional Claimants' Nationalities"
                            {...register("additionalClaimantsNationalities")}
                            isInvalid={
                              !!errors?.additionalClaimantsNationalities
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.additionalClaimantsNationalities?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            ID / Commercial Register Number*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter ID / Commercial Register Number"
                            {...register("claimantIdOrRegNumber")}
                            isInvalid={!!errors?.claimantIdOrRegNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantIdOrRegNumber?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Address Line 1*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address Line 1"
                            {...register("claimantAddressLine1")}
                            isInvalid={!!errors?.claimantAddressLine1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantAddressLine1?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Address Line 2
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address Line 2"
                            {...register("claimantAddressLine2")}
                            isInvalid={!!errors?.claimantAddressLine2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantAddressLine2?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Address Line 3
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address Line 3"
                            {...register("claimantAddressLine3")}
                            isInvalid={!!errors?.claimantAddressLine3}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantAddressLine3?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            P.O. Box
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter P.O. Box"
                            {...register("claimantPOBox")}
                            isInvalid={!!errors?.claimantPOBox}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantPOBox?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">City*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter City*"
                            {...register("claimantCity")}
                            isInvalid={!!errors?.claimantCity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantCity?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Post Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Post Code"
                            {...register("claimantPostCode")}
                            isInvalid={!!errors?.claimantPostCode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantPostCode?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            State/Province
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter State/Province"
                            {...register("claimantState")}
                            isInvalid={!!errors?.claimantState}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantState?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Country*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Country*"
                            {...register("claimantCountry")}
                            isInvalid={!!errors?.claimantCountry}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantCountry?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            National Address
                          </Form.Label>
                          <p className="p-sm mb-1">
                            (applicable only for Saudi Arabia)
                          </p>
                          <Form.Control
                            type="text"
                            placeholder="Enter National Address"
                            {...register("claimantNationalAddress")}
                            isInvalid={!!errors?.claimantNationalAddress}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantNationalAddress?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Telephone Number*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Telephone Number*"
                            {...register("claimantTelephone")}
                            isInvalid={!!errors?.claimantTelephone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantTelephone?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Email Address*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Email Address*"
                            {...register("claimantEmail")}
                            isInvalid={!!errors?.claimantEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantEmail?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Representative Information */}
                    <h4 className="mb-3">REPRESENTATIVE DETAILS</h4>
                    <Row className="mb-4">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Name*
                          </Form.Label>
                          <p className="p-sm mb-1">
                            If you are an individual and represent yourself,
                            please add your name and contact details in this and
                            the following boxes. If the claimant is a legal
                            person, please add the name of its authorized
                            representative.
                          </p>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Name*"
                            {...register("repName")}
                            isInvalid={!!errors?.repName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Firm Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Firm Name"
                            {...register("repFirmName")}
                            isInvalid={!!errors?.repFirmName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repFirmName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Address Line 1*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Address Line 1"
                            {...register("repAddressLine1")}
                            isInvalid={!!errors?.repAddressLine1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repAddressLine1?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Address Line 2
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Address Line 2"
                            {...register("repAddressLine2")}
                            isInvalid={!!errors?.repAddressLine2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repAddressLine2?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative City*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative City"
                            {...register("repCity")}
                            isInvalid={!!errors?.repCity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repCity?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Post Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Post Code"
                            {...register("repPostCode")}
                            isInvalid={!!errors?.repPostCode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repPostCode?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Country*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Country"
                            {...register("repCountry")}
                            isInvalid={!!errors?.repCountry}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repCountry?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Telephone Number*
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder="Enter Representative Telephone Number"
                            {...register("repTelephone")}
                            isInvalid={!!errors?.repTelephone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repTelephone?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Mobile Number*
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder="Enter Representative Mobile Number"
                            {...register("repMobile")}
                            isInvalid={!!errors?.repMobile}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repMobile?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Email Address*
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Representative Email Address"
                            {...register("repEmail")}
                            isInvalid={!!errors?.repEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.repEmail?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    {/* Dispute Details */}
                    <h4 className="mb-3">DISPUTE DETAILS</h4>
                    <Row className="mb-4">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Date of Contract or Arbitration Agreement*
                          </Form.Label>
                          <p className="p-sm mb-1">
                            Please provide the date of the contract that
                            includes the arbitration clause (or the date of the
                            arbitration agreement, if separate from the
                            contract):
                          </p>
                          <Form.Control
                            type="date"
                            {...register("contractDate")}
                            isInvalid={!!errors?.contractDate}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.contractDate?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Text of Arbitration Clause/Agreement*
                          </Form.Label>
                          <p className="p-sm mb-1">
                            Please copy and paste the language of the
                            arbitration clause (or arbitration agreement) in the
                            below box.
                          </p>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Enter Arbitration Clause/Agreement"
                            {...register("arbitrationClauseText")}
                            isInvalid={!!errors?.arbitrationClauseText}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.arbitrationClauseText?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Nature of the Dispute*
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Describe the nature of the dispute"
                            {...register("natureOfDispute")}
                            isInvalid={!!errors?.natureOfDispute}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.natureOfDispute?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Claim or Relief Sought*
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Describe the claim or relief sought"
                            {...register("claimReliefSought")}
                            isInvalid={!!errors?.claimReliefSought}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimReliefSought?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Claimant Type of Business*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Claimant Type of Business"
                            {...register("claimantBusinessType")}
                            isInvalid={!!errors?.claimantBusinessType}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.claimantBusinessType?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Respondent Type of Business*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Respondent Type of Business"
                            {...register("respondentBusinessType")}
                            isInvalid={!!errors?.respondentBusinessType}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentBusinessType?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Number of Arbitrators*
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Number of Arbitrators"
                            {...register("numberOfArbitrators")}
                            isInvalid={!!errors?.numberOfArbitrators}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.numberOfArbitrators?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Have you also filed an emergency application with
                            your filing pursuant to Article 6 of the RAKMAC
                            Arbitration Rules?
                          </Form.Label>
                          <Form.Select
                            {...register("emergencyApplicationFiled")}
                            isInvalid={!!errors?.emergencyApplicationFiled}
                          >
                            <option value="">Select an option</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors?.emergencyApplicationFiled?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Language
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Language"
                            {...register("language")}
                            isInvalid={!!errors?.language}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.language?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Place of Arbitration
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Place of Arbitration"
                            {...register("placeOfArbitration")}
                            isInvalid={!!errors?.placeOfArbitration}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.placeOfArbitration?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Only if the RAKMAC's Expedited Procedure Rules Apply
                            – Detailed Submissions
                          </Form.Label>
                          <p className="p-sm mb-1">
                            Please present detailed submissions on the facts and
                            claims. You may upload a separate Statement of Claim
                            instead.
                          </p>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter detailed submissions if Expedited Procedure Rules apply"
                            {...register("expeditedProcedureDetails")}
                            isInvalid={!!errors?.expeditedProcedureDetails}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.expeditedProcedureDetails?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    {/* RESPONDENT DETAILS */}
                    <h4 className="mb-3">RESPONDENT DETAILS</h4>
                    <Row className="mb-4">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Respondent's Name:*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name as it should appear in case caption"
                            {...register("respondentName")}
                            isInvalid={!!errors?.respondentName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Respondent's Nationality:*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter nationality"
                            {...register("respondentNationality", {
                              required: "Respondent's Nationality is required",
                            })}
                            isInvalid={!!errors?.respondentNationality}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentNationality?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Additional Respondents' Names:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter additional names"
                            {...register("additionalRespondents")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Additional Respondents' Nationality:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter nationality for additional respondents"
                            {...register("additionalRespondentsNationalities")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            ID / Commercial Register Number:*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter ID or register number"
                            {...register("respondentIdOrRegNumber")}
                            isInvalid={!!errors?.respondentIdOrRegNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentIdOrRegNumber?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Address:*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter address"
                            {...register("respondentAddress", {
                              required: "Address is required",
                            })}
                            isInvalid={!!errors?.respondentAddress}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentAddress?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>P.O. Box:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter P.O. Box"
                            {...register("respondentPOBox")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>City:*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter city"
                            {...register("respondentCity", {
                              required: "City is required",
                            })}
                            isInvalid={!!errors?.respondentCity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentCity?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Post Code:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter post code"
                            {...register("respondentPostCode")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>State/Province:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter state/province"
                            {...register("respondentState")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Country:*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter country"
                            {...register("respondentCountry", {
                              required: "Country is required",
                            })}
                            isInvalid={!!errors?.respondentCountry}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentCountry?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            National Address (applicable only for Saudi Arabia):
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter national address"
                            {...register("respondentNationalAddress")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Telephone Number:*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter telephone number"
                            {...register("respondentTelephone", {
                              required: "Telephone Number is required",
                              pattern: {
                                value: /^[0-9+\-() ]+$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                            isInvalid={!!errors?.respondentTelephone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentTelephone?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Email Address:*</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email address"
                            {...register("respondentEmail", {
                              required: "Email is required",
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Enter a valid email address",
                              },
                            })}
                            isInvalid={!!errors?.respondentEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentEmail?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/*Respondent's reps form*/}
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Name*
                          </Form.Label>
                          <p className="p-sm mb-1">
                            If the respondent is self-represented, please add
                            the respondent’s name and contact details in this
                            and the following boxes. If the respondent is a
                            legal person, please add, if known, the name of its
                            authorized representative.
                          </p>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Name"
                            {...register("respondentRepName", {
                              required: "Representative Name is required",
                            })}
                            isInvalid={!!errors?.respondentRepName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Firm Name (if applicable)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Firm Name"
                            {...register("respondentRepFirmName")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Address*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Address"
                            {...register("respondentRepAddress", {
                              required: "Representative Address is required",
                            })}
                            isInvalid={!!errors?.respondentRepAddress}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepAddress?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative P.O. Box
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative P.O. Box"
                            {...register("respondentRepPOBox")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative City*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative City"
                            {...register("respondentRepCity", {
                              required: "Representative City is required",
                            })}
                            isInvalid={!!errors?.respondentRepCity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepCity?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Post Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Post Code"
                            {...register("respondentRepPostCode")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative State/Province
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative State/Province"
                            {...register("respondentRepState")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Country*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Country"
                            {...register("respondentRepCountry", {
                              required: "Representative Country is required",
                            })}
                            isInvalid={!!errors?.respondentRepCountry}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepCountry?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative National Address (applicable only for
                            Saudi Arabia)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative National Address"
                            {...register("respondentRepNationalAddress")}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Telephone Number*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Telephone Number"
                            {...register("respondentRepTelephone", {
                              required: "Telephone Number is required",
                              pattern: {
                                value: /^[0-9+\-() ]+$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                            isInvalid={!!errors?.respondentRepTelephone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepTelephone?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Mobile Number*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Representative Mobile Number"
                            {...register("respondentRepMobile", {
                              required: "Mobile Number is required",
                              pattern: {
                                value: /^[0-9+\-() ]+$/,
                                message: "Enter a valid mobile number",
                              },
                            })}
                            isInvalid={!!errors?.respondentRepMobile}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepMobile?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-medium">
                            Representative Email Address*
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Representative Email Address"
                            {...register("respondentRepEmail", {
                              required: "Email is required",
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Enter a valid email address",
                              },
                            })}
                            isInvalid={!!errors?.respondentRepEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.respondentRepEmail?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    <h4 className="mb-3">Acknowledgements</h4>
                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        The information provided herein, and any attachments
                        thereto, are true and accurate to the best of my
                        knowledge and belief. If there is any change in the
                        information provided, I agree to promptly notify the
                        RAKMAC.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.infoAccurate}
                          {...register("infoAccurate")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.infoAccurate?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I understand that to start an arbitration proceeding,
                        all filing requirements pursuant to Article 4 of the
                        RAKMAC Arbitration Rules must be satisfied.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.filingRequirementsMet}
                          {...register("filingRequirementsMet")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.filingRequirementsMet?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        In case the Expedited Procedure Rules may apply, I
                        confirm that I have presented detailed submissions on
                        the facts and claims.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.expeditedProcedureConfirmed}
                          {...register("expeditedProcedureConfirmed")}
                        />
                        <Form.Check.Label>I Agree</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.expeditedProcedureConfirmed?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I understand that this proceeding shall commence once
                        also the appropriate filing fee has been received by the
                        RAKMAC.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.filingFeePaid}
                          {...register("filingFeePaid")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.filingFeePaid?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I understand that I will receive a copy of this Notice
                        of Arbitration after I have clicked the below “SUBMIT”
                        button. I agree to forward this Notice of Arbitration,
                        along with the contract and any other documents provided
                        to the SCCA, to the respondent(s) as per Article 4 of
                        the SCCA Arbitration Rules.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.noticeSentToRespondent}
                          {...register("noticeSentToRespondent")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.noticeSentToRespondent?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I agree to receive any and all RAKMAC communications
                        pertaining to my case via email.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.agreeToEmailCommunication}
                          {...register("agreeToEmailCommunication")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.agreeToEmailCommunication?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I agree to receive selected RAKMAC communications
                        pertaining to my case via text message.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.agreeToSMSCommunication}
                          {...register("agreeToSMSCommunication")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.agreeToSMSCommunication?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I have read the RAKMAC's Terms and Conditions and commit
                        to uphold and respect their provisions at all times.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.agreeToTerms}
                          {...register("agreeToTerms")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.agreeToTerms?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        Optional: I authorize the SCCA to use the information I
                        have provided in this form for purposes of marketing
                        communications.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.marketingConsent}
                          {...register("marketingConsent")}
                        />
                        <Form.Check.Label>I Agree</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.marketingConsent?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        I confirm that I have uploaded the contract including
                        the arbitration clause or, where applicable, the
                        arbitration agreement*
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.arbitrationRequirementsMet}
                          {...register("arbitrationRequirementsMet")}
                        />
                        <Form.Check.Label>I Agree</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.arbitrationRequirementsMet?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="mb-1">
                        Where applicable, I confirm that I have uploaded a Power
                        of Attorney.
                      </p>
                      <Form.Check className="fw-medium">
                        <Form.Check.Input
                          type="checkbox"
                          isInvalid={!!errors.powerOfAttorney}
                          {...register("powerOfAttorney")}
                        />
                        <Form.Check.Label>I Agree*</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors?.powerOfAttorney?.message}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>

                    <h4 className="mb-3">Document Upload</h4>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium">
                        Please upload your documents here*
                      </Form.Label>
                      <p className="p-sm mb-2">
                        Please upload your contract and any other supporting
                        documents. You may upload up to 10 documents, preferably
                        in PDF-format. Each upload shall not exceed 30 MB.
                      </p>
                      <Form.Control
                        type="file"
                        multiple
                        accept=".pdf"
                        isInvalid={!!errors.documents}
                        {...register("documents")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.documents?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <hr className="my-4" />
                    <h4 className="mb-3">Complete and Submit:</h4>
                    <p>
                      The parties will receive an official letter initiating
                      this proceeding once all filing requirements have been
                      satisfied
                    </p>
                    {/* Submission */}
                    <div className="mt-4">
                      <Button
                        variant="none"
                        type="submit"
                        className="btn btn-solid-blue"
                        disabled={
                          !showRakmacAuthority ||
                          !showDisputeExceeds200k ||
                          loader
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (
                  <p>Please contact the RAKMAC</p>
                )}
              </>
            ) : (
              <p>Please contact the RAKMAC</p>
            )}
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default ArbitrationForm;
