import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/header/header";
import Footer from "../components/common/footer/footer";
import ScrollToTop from "../ScrollToTop";

const DefaultLayout = () => {
  const [fontSizeClassIndex, setFontSizeClassIndex] = useState(2);
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem("theme") === "dark",
  );
  const [isColorless, setIsColorless] = useState(
    () => localStorage.getItem("colorless") === "true",
  );
  const [colorScheme, setColorScheme] = useState(
    () => localStorage.getItem("colorScheme") || "default",
  );

  const fontSizeClasses = [
    "font-size-xs",
    "font-size-sm",
    "font-size-md",
    "font-size-lg",
    "font-size-xl",
  ];

  const theme = darkMode ? "dark" : "light";

  const darkTheme = () => {
    setDarkMode(true);
    localStorage.setItem("theme", "dark");
  };

  const lightTheme = () => {
    setDarkMode(false);
    localStorage.setItem("theme", "light");
  };

  const toggleTheme = () => {
    const newTheme = !darkMode;
    setDarkMode(newTheme);
    localStorage.setItem("theme", newTheme ? "dark" : "light");
  };

  const toggleColorlessMode = () => {
    setIsColorless((prev) => {
      const newValue = !prev;
      localStorage.setItem("colorless", newValue);
      return newValue;
    });
  };

  const handleGoldenTheme = () => {
    setIsColorless(false);
    setColorScheme("golden");
    localStorage.setItem("colorless", "false"); // Ensure it's stored
    localStorage.setItem("colorScheme", "golden");
    document.body.classList.remove("colorless-mode"); // Ensure immediate update
  };

  const handleGreenTheme = () => {
    setIsColorless(false);
    setColorScheme("green");
    localStorage.setItem("colorless", "false");
    localStorage.setItem("colorScheme", "green");
    document.body.classList.remove("colorless-mode");
  };

  const handlePrimaryTheme = () => {
    setIsColorless(false);
    setColorScheme("default");
    localStorage.setItem("colorless", "false");
    localStorage.setItem("colorScheme", "default");
    document.body.classList.remove("colorless-mode");
  };

  const handleResetSetting = () => {
    setIsColorless(false);
    setDarkMode(false);
    setFontSizeClassIndex(2);
    setColorScheme("default");

    localStorage.setItem("colorless", "false"); // Ensure this is stored
    localStorage.setItem("theme", "light");
    localStorage.setItem("colorScheme", "default");

    document.body.classList.remove("colorless-mode");
  };

  const increaseFontSize = () => {
    if (fontSizeClassIndex < fontSizeClasses.length - 1) {
      setFontSizeClassIndex((prevIndex) => prevIndex + 1);
    }
  };

  const resetFontSize = () => {
    setFontSizeClassIndex(2);
  };

  const decreaseFontSize = () => {
    if (fontSizeClassIndex > 0) {
      setFontSizeClassIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    document.body.classList.remove(
      "golden-theme",
      "green-theme",
      "default-theme",
    );
    document.body.classList.add(`${colorScheme}-theme`);
    document.body.classList.remove("colorless-mode");
  }, [colorScheme]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
    } else {
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    if (isColorless) {
      document.body.classList.add("colorless-mode");
    } else {
      document.body.classList.remove("colorless-mode");
    }
  }, [isColorless]);

  useEffect(() => {
    document.body.classList.remove(...fontSizeClasses);
    document.body.classList.add(fontSizeClasses[fontSizeClassIndex]);
  }, [fontSizeClassIndex]);

  return (
    <>
      <ScrollToTop />
      <div className="main-content-area">
        <Header
          increaseFontSize={increaseFontSize}
          resetFontSize={resetFontSize}
          decreaseFontSize={decreaseFontSize}
          theme={theme}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
          colorScheme={colorScheme}
          toggleColorlessMode={toggleColorlessMode}
          handleGoldenTheme={handleGoldenTheme}
          handleGreenTheme={handleGreenTheme}
          handlePrimaryTheme={handlePrimaryTheme}
          handleResetSetting={handleResetSetting}
        />
        <Outlet />
        <Footer
          increaseFontSize={increaseFontSize}
          decreaseFontSize={decreaseFontSize}
          theme={theme}
          toggleTheme={toggleTheme}
          toggleColorlessMode={toggleColorlessMode}
        />
      </div>
    </>
  );
};
export default DefaultLayout;
