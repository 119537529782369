import React, { useEffect, useState } from "react";
import "./events.css";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faLocationDot,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatDate,
  formatEventTime,
  getEventStatus,
} from "../../utils/formatDate";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEventList } from "../../redux/events/action";
import { Col, Container, Image, Row } from "react-bootstrap";

/**
 * Renders a list of event cards with details such as image, date, time, location, and status.
 *
 * @param {Object[]} events - An array of event objects to be displayed.
 * @param {string} events[].id - The unique identifier for the event.
 * @param {string} events[].imageUrl - The URL of the event image.
 * @param {string} events[].heading - The heading or title of the event.
 * @param {string} events[].location - The location where the event is held.
 * @param {string} events[].startDate - The start date of the event.
 * @param {string} events[].endDate - The end date of the event.
 * @param {string} events[].startTime - The start time of the event.
 * @param {string} events[].endTime - The end time of the event.
 *
 * @returns {React.ReactElement} - A container with a list of styled event cards.
 */
function EventListing({ pageCount, events }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);

  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };

  useEffect(() => {
    dispatch(getEventList(currentPage));
  }, [currentPage, pageOffset]);

  return (
    <div className="event-page-listing py-5 mb-xxl-0 mb-4">
      <Container>
        <h2 className="mb-5">Events</h2>
        <Row className="gy-xxl-4 gy-3">
          {events?.length > 0 ? (
            events.map((event) => {
              const status = getEventStatus(event?.startDate, event?.endDate);
              return (
                <Col lg={6} key={event?.id}>
                  <div className="event-card-wrapper d-sm-flex">
                    <div className="image-box text-center me-xl-3 me-sm-2">
                      <Image
                        src={event?.eventImage}
                        alt={event?.heading}
                        className="event-image img-fluid"
                        loading="lazy"
                        fluid
                      />
                    </div>
                    <div className="content-box">
                      <div className="fw-semibold text-blue-1">
                        {event?.eventType}
                      </div>
                      <h6 className="mb-2 content-para">{event?.heading}</h6>
                      <div className="location-wrapper d-flex align-items-center gap-2 mb-2">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          color="#003E7F"
                          width={16}
                        />
                        <span className="content-location">
                          {event?.location}
                        </span>
                      </div>
                      {event?.startTime && (
                        <div className="event-time d-flex align-items-center gap-2 mb-2">
                          <FontAwesomeIcon
                            icon={faClock}
                            color="#003E7F"
                            width={16}
                          />
                          {formatEventTime(event.startTime, event.endTime)}
                        </div>
                      )}
                      <div className="event-dates d-flex align-items-center gap-2 mb-2">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          color="#003E7F"
                          width={16}
                        />
                        {formatDate(event?.startDate)} -{" "}
                        {formatDate(event?.endDate)}
                      </div>
                      <div className="event-status d-flex align-items-center gap-2 mb-2">
                        <FontAwesomeIcon
                          icon={faStopwatch}
                          color="#003E7F"
                          width={16}
                        />
                        {status}
                      </div>
                      <Link
                        to={`/event/${event?.id}`}
                        className="btn btn-solid-blue btn-sm"
                      >
                        More
                      </Link>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : (
            <p>There is No Data to Display.</p>
          )}
        </Row>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          onPageChange={HandlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
          marginPagesDisplayed={2}
          activeClassName="active"
          forcePage={pageOffset}
        />
      </Container>
    </div>
  );
}

export default EventListing;
