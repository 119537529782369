import {
  DOWNLOAD_COUNT,
  SEMINARS_LOADER,
  SET_SEMINARS_DATA,
  SET_SEMINAR_DETAIL_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  seminarDetail: {},
  seminars: {},
};

const seminarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEMINARS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_SEMINARS_DATA: {
      return {
        ...state,
        seminars: action.payload,
      };
    }
    case SET_SEMINAR_DETAIL_DATA: {
      return {
        ...state,
        seminarDetail: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        seminarDetail: {
          ...state.seminarDetail,
          downloadCount: (state.seminarDetail?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default seminarReducer;
