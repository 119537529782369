import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { downloadCount, getInfoPage } from "../../../redux/media-center/action";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import MetaTags from "../../../components/metaTags/seoTags";

/**
 * TermsAndCondition component.
 *
 * This component renders the Terms and Conditions page. It fetches the data
 * from the server using the `getInfoPage` action and displays the page content
 * including metadata, heading, description, and page statistics.
 *
 * @returns {React.ReactElement} The Terms and Conditions page.
 */

const TermsAndCondition = () => {
  const dispatch = useDispatch();

  const media = useSelector((state) => state.media);
  const terms = media.infoPage;
  useEffect(() => {
    dispatch(getInfoPage("Terms and Conditions"));
  }, []);
  return (
    <>
      <MetaTags
        title={terms?.pageTitle}
        description={terms?.metaDescription}
        keywords={terms?.metaKeywords}
        metaTitle={terms?.metaTitle}
      />
      <section className="terms-snd-condtion legal-information-page no-banner-page">
        <Container>
          <span className="h2">{terms.heading}</span>
          <div
            className="ck-content-wrapper mb-lg-3 mb-2"
            dangerouslySetInnerHTML={{ __html: terms.description }}
          />
        </Container>
      </section>
      <PageStats
        countAction={downloadCount}
        downloadCount={terms?.downloadCount}
        viewCount={terms?.pageViewCount}
        pdf={terms?.pdfFile}
        id={terms?.id}
        loader={terms?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default TermsAndCondition;
