import React, { useState } from "react";
import { Button, Container, Image, Modal } from "react-bootstrap";
import Slider from "react-slick";
import {
  NextArrow,
  PrevArrow,
} from "../../../components/common/customSliderArows/customSliderArows";
import "./videos.css";
import { formatDate } from "../../../utils/formatDate";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Videos = ({ loader, videos, heading }) => {
  const [show, setShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 575, settings: { slidesToShow: 1 } },
    ],
  };
  const settings2 = {
    arrows: false,
    dots: false,
    infinite: false,
    loop: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // DragEvent: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 375, settings: { slidesToShow: 2 } },
    ],
  };
  const onThumbnailClick = (videoUrl) => {
    setCurrentVideo(videoUrl);
  };
  return (
    <section className="video-album">
      <Container className="custom-container">
        <h2 className="mb-lg-5 mb-4">
          {loader ? <Skeleton width={200} height={30} /> : heading}
        </h2>
        <Slider {...settings} className="position-relative mb-lg-5 mb-4">
          {videos?.map((video, index) => (
            <div key={index} className="video-body">
              <div
                className="video-thumbnail position-relative"
                onClick={handleShow}
                role="button"
              >
                {loader ? (
                  <Skeleton height={200} width="100%" borderRadius={8} />
                ) : (
                  <video
                    src={video?.video}
                    width="300"
                    height="200"
                    controls
                    preload="metadata"
                  />
                )}
              </div>
              <div className="video-details d-flex align-items-center">
                <div className="date text-center me-3 flex-shrink-0">
                  {loader ? (
                    <>
                      <Skeleton width={30} height={20} />
                      <Skeleton width={40} height={16} />
                    </>
                  ) : (
                    <>
                      <span className="fw-semibold">
                        {formatDate(video?.createdAt)
                          ?.split(" ")[1]
                          ?.replace(",", "")
                          ?.padStart(2, "0")}
                      </span>
                      <p className="month text-uppercase mb-0">
                        {formatDate(video?.createdAt)
                          .split(" ")[0]
                          .toUpperCase()}
                      </p>
                    </>
                  )}
                </div>
                <p className="mb-0 flex-grow-1">
                  {loader ? <Skeleton width="80%" height={18} /> : video?.title}
                </p>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-end">
          {loader ? (
            <Skeleton width={120} height={40} className="d-inline-block" />
          ) : (
            <Link to={`/videos`}>
              <Button variant="none" className="btn-solid-blue electronic-btn">
                View All
              </Button>
            </Link>
          )}
        </div>
        {currentVideo && (
          <Modal
            className="video-modal"
            show={show}
            onHide={handleClose}
            size="lg"
            centered
          >
            <Modal.Body closeButton>
              <video
                src={currentVideo}
                type="video/mp4"
                controls
                muted
                autoPlay
              />
              <Slider {...settings2}>
                {videos.map((video, index) => (
                  <div key={index} onClick={handleShow} role="button">
                    {loader ? (
                      <Skeleton height={90} width={155} borderRadius={8} />
                    ) : (
                      <Image
                        src={video.thumbnailUrl}
                        alt={`Thumbnail ${index}`}
                        loading="lazy"
                        onClick={() => onThumbnailClick(video.url)}
                        width={155}
                        fluid
                      />
                    )}
                  </div>
                ))}
              </Slider>
            </Modal.Body>
          </Modal>
        )}
      </Container>
    </section>
  );
};

export default Videos;
