import {
  ARBITRATION_FORM_LOADER,
  ARBITRATION_FORM_SUBMITTED,
} from "../actionTypes";

const initialState = {
  loader: false,
  submitted: false,
};

const arbitrationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARBITRATION_FORM_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case ARBITRATION_FORM_SUBMITTED: {
      return {
        ...state,
        submitted: action.payload,
      };
    }
    default:
      return state;
  }
};

export default arbitrationFormReducer;
