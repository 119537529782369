import React, { useMemo, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import countryList from "react-select-country-list";
import "./servicesForms.css";
import { mediationReqSchema } from "../../../validation/validationSchema";
import { mediationRequest } from "../../../redux/mediation-request/action";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";

export const MediationForm = () => {
  const dispatch = useDispatch();
  const [countryValue, setCountryValue] = useState(null);
  const mediationForm = useSelector((state) => state.mediationRequest);
  const options = useMemo(() => countryList().getData(), []);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(mediationReqSchema) });
  const sendCopy = watch("sendCopy", false);

  const changeHandler = (selectedOption) => {
    setCountryValue(selectedOption);
    setValue("country", selectedOption.value);
  };

  /**
   * This function is a callback for the form, it will be called when the form is submitted.
   * It takes the form data, and appends it to a new FormData object.
   * It then dispatches the mediationRequest action with the formData.
   * @param {Object} data - The form data.
   */
  const onSubmit = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (key === "documentUpload") return;
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });

    if (data.documentUpload && data.documentUpload.length > 0) {
      Array.from(data.documentUpload).forEach((file, index) => {
        formData.append(`documentUpload`, file);
      });
    }
    dispatch(mediationRequest(formData));
  };

  return (
    <>
      {mediationForm.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <section className="services-form-page mediation-form no-banner-page">
            <Container>
              <h2 className="text-center mb-4">Request for Mediation</h2>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h4 className="mb-3">CLAIMANT DETAILS</h4>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Claimant's Name*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("claimantName")}
                          placeholder="Enter Claimant's Name"
                          isInvalid={!!errors.claimantName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.claimantName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Claimant's Nationality*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Claimant's Nationality"
                          {...register("claimantNationality")}
                          isInvalid={!!errors.claimantNationality}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.claimantName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Additional Claimants' Names
                        </Form.Label>
                        <p className="p-sm mb-1">
                          Please add any additional names precisely as they
                          should appear in the case caption.
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="Enter Additional Claimants' Names"
                          {...register("additionalClaimantsNames")}
                          isInvalid={!!errors.additionalClaimantsNames}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.additionalClaimantsNames?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Additional Claimants' Nationalities
                        </Form.Label>
                        <p className="p-sm mb-1">
                          Please add the nationality next to each additional
                          Claimant name.
                        </p>
                        <Form.Control
                          type="tel"
                          placeholder="Enter Additional Claimants' Nationalities"
                          {...register("additionalClaimantsNationalities")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          ID / Commercial Register Number
                        </Form.Label>
                        <Form.Control
                          type="ID / Commercial Register Number"
                          placeholder="Enter ID"
                          {...register("idNumber")}
                          isInvalid={!!errors.idNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.idNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Address Line 1*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 1"
                          {...register("addressLine1")}
                          isInvalid={!!errors.addressLine1}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.addressLine1?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Address Line 2
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 2"
                          {...register("addressLine2")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Address Line 3
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 3"
                          {...register("addressLine3")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">P.O. Box</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter P.O. Box"
                          {...register("poBox")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.poBox?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">City*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter City*"
                          {...register("city")}
                          isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">Post Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Post Code"
                          {...register("postCode")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          State/Province
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter State/Province"
                          {...register("stateProvince")}
                        />
                        select
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">Country*</Form.Label>
                        <Select
                          options={options}
                          value={countryValue}
                          onChange={changeHandler}
                          placeholder="Select Country"
                        />
                        {errors.country && (
                          <span className="text-danger">
                            {errors.country.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          National Address
                        </Form.Label>
                        <p className="p-sm mb-1">
                          (applicable only for Saudi Arabia)
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="Enter National Address"
                          {...register("nationalAddress")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Telephone Number*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Telephone Number*"
                          {...register("telephoneNumber")}
                          isInvalid={!!errors.telephoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.telephoneNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Email Address*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Email Address*"
                          {...register("emailAddress")}
                          isInvalid={!!errors.emailAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.emailAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Respondent Information */}
                  <h4>Respondent Information</h4>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Name
                        </Form.Label>
                        <p className="p-sm mb-1">
                          If you are an individual and represent yourself,
                          please add your name and contact details in this and
                          the following boxes. If the claimant is a legal
                          person, please add the name of its authorized
                          representative.
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Name*"
                          {...register("representativeName")}
                          isInvalid={!!errors.representativeName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Firm Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Firm Name"
                          {...register("representativeFirmName")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Address Line 1*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Address Line 1"
                          {...register("representativeAddressLine1")}
                          isInvalid={!!errors.representativeAddressLine1}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeAddressLine1?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Address Line 2
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Enter Representative Address Line 2"
                          {...register("representativeAddressLine2")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Address Line 3
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Address Line 3"
                          {...register("representativeAddressLine3")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative P.O. Box
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative P.O. Box"
                          {...register("representativePOBox")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative City*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative City"
                          {...register("representativeCity")}
                          isInvalid={!!errors.representativeCity}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeCity?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Post Code
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Enter Representative Post Code"
                          {...register("representativePostCode")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative State/Province
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative State/Province"
                          {...register("representativeStateProvince")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Country*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Country"
                          {...register("representativeCountry")}
                          isInvalid={!!errors.representativeCountry}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeCountry?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative National Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Representative National Address"
                          {...register("representativeNationalAddress")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Telephone Number*
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Enter Representative Telephone Number"
                          {...register("representativeTelephoneNumber")}
                          isInvalid={!!errors.representativeTelephoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeTelephoneNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Mobile Number*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Mobile Number"
                          {...register("representativeMobileNumber")}
                          isInvalid={!!errors.representativeMobileNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeMobileNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Representative Email Address*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Representative Email Address"
                          {...register("representativeEmailAddress")}
                          isInvalid={!!errors.representativeEmailAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.representativeEmailAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  {/* Dispute Details */}
                  <h4 className="mb-3">DISPUTE DETAILS</h4>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Date of Contract or Arbitration Agreement*
                        </Form.Label>
                        <p className="p-sm mb-1">
                          Please provide the date of the contract that includes
                          the arbitration clause (or the date of the arbitration
                          agreement, if separate from the contract):
                        </p>
                        <Form.Control
                          type="date"
                          {...register("dateOfContractOrMediationAgreement")}
                          isInvalid={
                            !!errors.dateOfContractOrMediationAgreement
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dateOfContractOrMediationAgreement?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Text of Arbitration Clause/Agreement*
                        </Form.Label>
                        <p className="p-sm mb-1">
                          Please copy and paste the language of the arbitration
                          clause (or arbitration agreement) in the below box.
                        </p>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          type="text"
                          placeholder="Enter Arbitration Clause/Agreement"
                          {...register("textOfMediationClauseAgreement")}
                          isInvalid={!!errors.textOfMediationClauseAgreement}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.textOfMediationClauseAgreement?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Nature of the Dispute*
                        </Form.Label>
                        <p className="p-sm mb-1">
                          Please describe the dispute in adequate detail. The
                          description should include the factual background of
                          the dispute, the relation between parties whether
                          contractual or non-contractual, the legal position of
                          the parties and the relief or remedies sought. You may
                          also summarize the dispute in the below box and upload
                          a more detailed Statement of Claim.
                        </p>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Describe the nature of the dispute"
                          {...register("natureOfDispute")}
                          isInvalid={!!errors.natureOfDispute}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.natureOfDispute?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Claim or Relief Sought:*
                        </Form.Label>
                        <p className="p-sm mb-1">
                          (e.g., claim amount, declaratory relief, etc.)
                        </p>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Describe the nature of the dispute"
                          {...register("claimOrReliefSought")}
                          isInvalid={!!errors.claimOrReliefSought}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.claimOrReliefSought?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Claimant Type of Business*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Claimant Type of Business"
                          {...register("claimantTypeOfBusiness")}
                          isInvalid={!!errors.claimantTypeOfBusiness}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.claimantTypeOfBusiness?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Respondent Type of Business*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Respondent Type of Business"
                          {...register("respondentTypeOfBusiness")}
                          isInvalid={!!errors.respondentTypeOfBusiness}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentTypeOfBusiness?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">
                          Number of Arbitrators
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Number of Arbitrators"
                          {...register("numberOfArbitrators")}
                          isInvalid={!!errors.numberOfArbitrators}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.numberOfArbitrators?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-medium">
                    Have you also filed an emergency application with your
                    filing pursuant to Article 6 of the RAKMAC Arbitration
                    Rules?
                  </Form.Label>
                  <Form.Control as="select" {...register("emergencyApplication")}>
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-medium">Language</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Language"
                          {...register("language")}
                          isInvalid={!!errors.language}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.language?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  {/* RESPONDENT DETAILS */}
                  <h4 className="mb-3">RESPONDENT DETAILS</h4>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Respondent's Name:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter name as it should appear in case caption"
                          {...register("respondentName")}
                          isInvalid={!!errors.respondentName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Respondent’s Nationality:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter nationality"
                          {...register("respondentNationality")}
                          isInvalid={!!errors.respondentNationality}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentNationality?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Additional Respondents' Names:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter additional names"
                          {...register("additionalRespondentsNames")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Additional Respondents' Nationality:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter nationality for additional respondents"
                          {...register("additionalRespondentsNationalities")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          ID / Commercial Register Number:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter ID or register number"
                          {...register("respondentIdNumber")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter address"
                          {...register("respondentAddress")}
                          isInvalid={!!errors.respondentAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>P.O. Box:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter P.O. Box"
                          {...register("respondentPOBox")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>City:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter city"
                          {...register("respondentCity")}
                          isInvalid={!!errors.respondentCity}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentCity?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Post Code:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter post code"
                          {...register("respondentPostCode")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>State/Province:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter state/province"
                          {...register("respondentStateProvince")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Country:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter country"
                          {...register("respondentCountry")}
                          isInvalid={!!errors.respondentCountry}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentCountry?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          National Address (applicable only for Saudi Arabia):
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter national address"
                          {...register("respondentNationalAddress")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Telephone Number:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter telephone number"
                          {...register("respondentTelephoneNumber")}
                          isInvalid={!!errors.respondentTelephoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentTelephoneNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address:*</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email address"
                          {...register("respondentEmailAddress")}
                          isInvalid={!!errors.respondentEmailAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.respondentEmailAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h4 className="mb-3">Acknowledgements</h4>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      The information provided herein, and any attachments
                      thereto, are true and accurate to the best of my knowledge
                      and belief. If there is any change in the information
                      provided, I agree to promptly notify the RAKMAC.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement1")}
                        isInvalid={!!errors.acknowledgement1}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement1?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I understand that to start an arbitration proceeding, all
                      filing requirements pursuant to Article 4 of the RAKMAC
                      Arbitration Rules must be satisfied. This includes the
                      information requested in this Notice of Arbitration, the
                      contract including the arbitration clause or a separate
                      arbitration agreement, and the appropriate filing fee. I
                      understand that if the filing requirements cannot be met
                      within 10 days from the date I have submitted this form,
                      the RAKMAC may discontinue the processing of my incomplete
                      filing without prejudice. I may then refile my claim once
                      I can satisfy all filing requirements subject to any
                      applicable laws and regulations.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement2")}
                        isInvalid={!!errors.acknowledgement2}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement2?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      In case the Expedited Procedure Rules may apply, I confirm
                      that I have presented detailed submissions on the facts
                      and claims in the above form under “Only if the RAKMAC's
                      Expedited Procedure Rules Apply – Detailed Submissions” or
                      have uploaded a separate Statement of Claim. I also
                      confirm that I have uploaded all the evidence then
                      available on which I intend to rely as per Article 2 of
                      the RAKMAC's Expedited Procedure Rules.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement3")}
                        isInvalid={!!errors.acknowledgement3}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement3?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I understand that this proceeding shall commence once also
                      the appropriate filing fee (see Appendix I to the RAKMAC
                      Arbitration Rules) has been received by the RAKMAC. The
                      RAKMAC will provide an invoice and payment instructions
                      under separate cover shortly.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement4")}
                        isInvalid={!!errors.acknowledgement4}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement4?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I understand that I will receive a copy of this Notice of
                      Arbitration after I have clicked the below “SUBMIT”
                      button. I agree to forward this Notice of Arbitration,
                      along with the contract and any other documents provided
                      to the RAKMAC, to the respondent(s) as per Article 4 of
                      the RAKMAC Arbitration Rules.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement5")}
                        isInvalid={!!errors.acknowledgement5}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement5?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I agree to receive any and all RAKMAC communications
                      pertaining to my case via email.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement6")}
                        isInvalid={!!errors.acknowledgement6}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement6?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I agree to receive selected RAKMAC communications
                      pertaining to my case via text message.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement7")}
                        isInvalid={!!errors.acknowledgement7}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement7?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I have read the RAKMAC's Terms and Conditions
                      (https://sadr.org/terms-condition?lang=en) and commit to
                      uphold and respect their provisions at all times.
                    </p>

                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement8")}
                        isInvalid={!!errors.acknowledgement8}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement8?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      Optional: I authorize the RAKMAC to use the information I
                      have provided in this form for purposes of marketing
                      communications.
                    </p>
                    <Form.Check type="checkbox" className="fw-medium">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("acknowledgement9")}
                        isInvalid={!!errors.acknowledgement9}
                      />
                      <Form.Check.Label>I Agree*</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.acknowledgement9?.message}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>

                  <h4 className="mb-3">Document Upload</h4>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-medium">
                      Please upload your documents here*
                    </Form.Label>
                    <p className="p-sm mb-2">
                      Please upload your contract and any other supporting
                      documents. You may upload up to 10 documents, preferably
                      in PDF-format. Each upload shall not exceed 30 MB.
                    </p>
                    <Form.Control
                      type="file"
                      accept=".pdf"
                      {...register("documentUpload")}
                      multiple
                    />
                    <p className="text-danger">
                      {errors.documentUpload?.message}
                    </p>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      I confirm that I have uploaded the contract including the
                      arbitration clause or, where applicable, the arbitration
                      agreement*
                    </p>
                    <Form.Check
                      type="checkbox"
                      label="I Agree*"
                      className="fw-medium"
                      {...register("confirmContractUpload")}
                      isInvalid={!!errors.confirmContractUpload}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmContractUpload?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">
                      Where applicable, I confirm that I have uploaded a Power
                      of Attorney.
                    </p>
                    <Form.Check
                      type="checkbox"
                      label="I Agree*"
                      className="fw-medium"
                      {...register("confirmPowerOfAttorneyUpload")}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p className="mb-1">Send me a copy of my responses</p>
                    <Form.Check
                      type="checkbox"
                      className="form-check"
                      {...register("sendCopy")}
                    />
                  </Form.Group>

                  {sendCopy && (
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address:*</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email address"
                          {...register("email")}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  )}
                  <hr className="my-4" />
                  <h4 className="mb-3">Complete and Submit:</h4>
                  <p>
                    The parties will receive an official letter initiating this
                    proceeding once all filing requirements have been satisfied
                  </p>
                  <Button
                    type="submit"
                    variant="none"
                    className="btn btn-solid-blue"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </Container>
          </section>
        </>
      )}
    </>
  );
};
