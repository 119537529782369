import {
  FAQ_FORM_LOADER_ACTIVE,
  FAQ_LOADER_ACTIVE,
  SET_FAQ_DATA,
  FAQ_FORM_SUBMITTED,
} from "../actionTypes";

const initialState = {
  faqLoader: false,
  faqData: [],
  faqFormLoader: false,
  formSubmitted: false,
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ_LOADER_ACTIVE:
      return {
        ...state,
        faqLoader: action?.payload,
      };
    case FAQ_FORM_LOADER_ACTIVE:
      return {
        ...state,
        faqFormLoader: action?.payload,
      };
    case FAQ_FORM_SUBMITTED:
      return {
        ...state,
        formSubmitted: action?.payload,
      };
    case SET_FAQ_DATA: {
      return {
        ...state,
        faqData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default faqReducer;
