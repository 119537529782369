import React from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import ServicesCard from "../../../components/common/servicesCard/servicesCard";
import "./seminars.css";
import Skeleton from "react-loading-skeleton";

const Seminars = ({
  loader,
  seminars,
  heading,
  limit,
  onPageChange,
  totalPages,
  currentPage,
}) => {
  const HandlePageClick = (event) => {
    onPageChange(event.selected + 1);
  };

  return (
    <section className="seminars-conferences position-relative">
      <Container className="custom-container">
        <h2 className="title text-white text-center">
          {loader ? <Skeleton width="200px" /> : heading}
        </h2>
        <Row className="justify-content-center gy-3">
          {seminars?.length > 0 &&
            seminars?.slice(0, limit)?.map((seminar) => {
              return (
                <Col lg={4} sm={6} key={seminar.id}>
                  <ServicesCard
                    id={seminar?.id}
                    img={seminar?.image}
                    preTitle={seminar?.category}
                    title={seminar?.title}
                    content={seminar?.description}
                    loader={loader}
                    link={"seminars"}
                  />
                </Col>
              );
            })}
        </Row>
        {limit > 3 ? (
          loader ? (
            <Skeleton height={40} width={200} className="mx-auto mt-4" />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : (
          <div className="text-end mt-4">
            {loader ? (
              <Skeleton width={120} height={40} />
            ) : (
              <Link to={`/seminars`} className="btn btn-solid-blue">
                View All
              </Link>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default Seminars;
