import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import MetaTags from "../../../components/metaTags/seoTags";
import { getMediaPage } from "../../../redux/media-center/action";
import { getCmsPage } from "../../../redux/cms-pages/action";
import Banner from "../../../components/common/banner/banner";
import PageStats from "../../../components/pageStats/pageStats";
import {
  getVideoDetail,
  getVideos,
  updateDownloadCount,
} from "../../../redux/videos/action";
import Videos from "./videos";
import { formatDate } from "../../../utils/formatDate";
import { Container } from "react-bootstrap";

export default function VideoDetail() {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const mediaPageContent = media.mediaCenter?.mediaCenter;
  const videos = useSelector((state) => state.videos);
  const videosData = videos?.videos;
  const { videoDetail } = videos;
  const { pageData } = useSelector((state) => state.cmsPages);
  const { id } = useParams();

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getVideoDetail(id));
    dispatch(getCmsPage("Video Gallery"));
  }, [id]);

  useEffect(() => {
    dispatch(getVideos(1, 9));
  }, []);

  return (
    <>
      {videos.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={videoDetail?.pageTitle}
            description={videoDetail?.metaDescription}
            keywords={videoDetail?.metaKeywords}
            metaTitle={videoDetail?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <section className="video-detail py-5">
            <Container>
              <p className="date pre-heading">
                {formatDate(videoDetail?.createdAt)}
              </p>
              <h4 className="mb-4">{videoDetail?.title}</h4>
              <div className="video-thumbnail position-relative">
                <video
                  src={videoDetail?.video}
                  width="50%"
                  height="500"
                  controls
                  preload="metadata"
                />
              </div>
            </Container>
          </section>
          <Videos
            heading={mediaPageContent?.videoAlbumHeading}
            limit={8}
            videos={videosData?.albums}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={videoDetail?.downloadCount}
            viewCount={videoDetail?.pageViewCount}
            pdf={videoDetail?.pdfFile}
            id={videoDetail?.id}
            loader={videoDetail?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
