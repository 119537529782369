import React from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Form, Container } from "react-bootstrap";
import { getWorkshops } from "../../../redux/workshop/action";

const WorkshopFilters = ({ filters, setFilters }) => {
  const dispatch = useDispatch();

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleResetFilters = () => {
    setFilters({ heading: "", location: "", startDate: "", endDate: "" });
    dispatch(getWorkshops(1, 9, {}));
  };

  const handleSearch = () => {
    dispatch(getWorkshops(1, 9, filters));
  };

  return (
    <Container>
      <div className="filter-bar rounded">
        <Row className="align-items-center gy-3">
          <Col xl={3} md={4}>
            <Form.Control
              type="text"
              placeholder="Search by Heading"
              name="heading"
              value={filters.heading || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={3} md={4}>
            <Form.Control
              type="text"
              placeholder="Search by Location"
              name="location"
              value={filters.location || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={3} md={4}>
            <Form.Control
              type="date"
              name="startDate"
              value={filters.startDate || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={3} md={4}>
            <Form.Control
              type="date"
              name="endDate"
              value={filters.endDate || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={{ span: 4, offset: 8 }} md={8}>
            <div className="d-flex justify-content-md-end justify-content-center gap-3">
              <Button
                onClick={handleSearch}
                variant="none"
                type="button"
                className="btn-solid-blue btn-sm"
              >
                Reset Filters
              </Button>
              <Button
                onClick={handleResetFilters}
                variant="none"
                type="button"
                className="btn-solid-blue btn-sm"
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default WorkshopFilters;
