import React, { useEffect, useState } from "react";
import { Images } from "../../../assets";
import SettingModal from "../settingModal/settingModal";
import {
  Container,
  Dropdown,
  Form,
  Image,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { config } from "../../../config/config";
import { useTranslation } from "react-i18next";
import SearchDropdown from "./searchDropdown";
import Sidebar from "./sidebar";
import { useLocation } from "react-router-dom";
import "./header.css";
function Header({
  increaseFontSize,
  resetFontSize,
  decreaseFontSize,
  theme,
  darkTheme,
  lightTheme,
  toggleColorlessMode,
  handleGoldenTheme,
  handleGreenTheme,
  handlePrimaryTheme,
  handleResetSetting,
  colorScheme,
}) {
  const [show, setShow] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { i18n } = useTranslation();
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);

  const handleTranslate = () => {
    const nextLanguageIndex = (currentLanguageIndex + 1) % config.lngs.length;
    const nextLanguageCode = config.lngs[nextLanguageIndex].code;
    i18n.changeLanguage(nextLanguageCode);
    setCurrentLanguageIndex(nextLanguageIndex);
  };

  const location = useLocation();
  const noBannerURLs =
    location.pathname === "/Faqs" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/intellectual-property-notice" ||
    location.pathname === "/arbitration-clause" ||
    location.pathname === "/compliance-applicable-laws" ||
    location.pathname === "/refund-cancellation-policy" ||
    location.pathname === "/terms-and-conditions" ||
    location.pathname === "/arbitration-form" ||
    location.pathname === "/mediation-form";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="header"
      className={`header postion-relative ${isScrolled || noBannerURLs ? "add-bg" : ""}`}
    >
      <Container className="custom-container">
        <div className="d-flex py-2">
          <Navbar expand="lg" className="flex-fill">
            <Navbar.Brand as={Link} to="/" className="p-0" id="header-logo">
              <Image src={Images.headerLogo} alt="logo" loading="lazy" fluid />
            </Navbar.Brand>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="d-lg-block d-none"
            >
              <Nav
                className="ms-auto my-2 my-lg-0 align-items-center"
                id="main-menu"
              >
                <Nav.Link as={Link} to="/home">
                  Home
                </Nav.Link>
                {/* <NavDropdown title="e-Services" className="e-services">
                  <NavDropdown.Item as={Link} to="/membership">
                    Memberships
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/arbitration"
                  >
                    Arbitration Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/claim"
                  >
                    Claim Statement Form
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action4">
                    Request The Arbitrator’s Response
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/intervention"
                  >
                    Intervention Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/interpretation"
                  >
                    Interpretation Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/joinder"
                  >
                    Joinder Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action4">
                    Objection Request
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/copy"
                  >
                    Request A True Copy Of The Ruling
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action3">
                    Request A Statement/Certificate
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action4">
                    Request To Select An Arbitrator Outside The Center’s List Of
                    Accredited Arbitrators
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action5">
                    An Arbitration Request That Is Not Subject To The Centre’s
                    Rules And Regulations
                  </NavDropdown.Item>
                </NavDropdown> */}
                <NavDropdown title="About Us">
                  <NavDropdown.Item as={Link} to="/about-us">
                    About Us
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Chairman message
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    General secretary message
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Committee members
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Work teams
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Partners
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Arbitration in the UAE
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item as={Link} to="/jury-panel">
                    Jury Panel
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item as={Link} to="/about-laws">
                    The Laws
                  </NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown title="Services">
                  <NavDropdown.Item as={Link} to="/services">
                    Services
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/arbitration">
                    Arbitration
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/mediation">
                    Mediation
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Calculator fees
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    A-la-carte service
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/departments-Of-Center">
                    Departments Of The Center
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/center-tables">
                    Center Tables
                  </NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown title="Arbitrators">
                  <NavDropdown.Item as={Link} to="#">
                    Apply- arbitrator
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Apply- expert
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#">
                    Code of Conducts
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/departments-Of-Center">
                    Departments Of The Center
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/center-tables">
                    Center Tables
                  </NavDropdown.Item> */}
                </NavDropdown>
                {/* <Nav.Link as={Link} to="/media-center">
                  Media Center
                </Nav.Link> */}
                <NavDropdown title="Media Center">
                  <NavDropdown.Item as={Link} to="/media-center">
                    Media Center
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/news">
                    News
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/photos">
                    Gallery
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/videos">
                    Videos
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/events">
                    Events
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/workshops">
                    Workshops
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/courses">
                    Training Courses
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/seminars">
                    Seminars & Conferences
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/libraries">
                    Electornic Library
                  </NavDropdown.Item> */}
                </NavDropdown>
                <Nav.Link as={Link} to="/about-laws">
                  Law library
                </Nav.Link>
                <Nav.Link as={Link} to="/contact-us">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Nav
              className="flex-row gap-xl-3 gap-2 me-xl-3 me-lg-2"
              id="personalization"
            >
              <Nav.Item role="button" onClick={handleShow}>
                <Image
                  src={Images.settingIcon}
                  alt="icon"
                  loading="lazy"
                  fluid
                />
              </Nav.Item>
              <Nav.Item
                role="button"
                // onClick={(event) => handleTranslate(event)}
              >
                <Image
                  className="ar-lang"
                  src={Images.arLangIcon}
                  alt="icon"
                  loading="lazy"
                  fluid
                />
                <Image
                  className="en-lang"
                  src={Images.enLangIcon}
                  alt="icon"
                  loading="lazy"
                  fluid
                />
              </Nav.Item>
              {/* <Nav.Item role="button">
                <NavLink to="/login">
                <Image
                  src={Images.userIcon}
                  alt="icon"
                  loading="lazy"
                  fluid
                />
                </NavLink>
              </Nav.Item> */}
              <SearchDropdown />
            </Nav>
            <Sidebar />
          </Navbar>
        </div>
      </Container>
      <SettingModal
        increaseFontSize={increaseFontSize}
        resetFontSize={resetFontSize}
        decreaseFontSize={decreaseFontSize}
        theme={theme}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
        colorScheme={colorScheme}
        toggleColorlessMode={toggleColorlessMode}
        show={show}
        handleClose={handleClose}
        handleGoldenTheme={handleGoldenTheme}
        handleGreenTheme={handleGreenTheme}
        handlePrimaryTheme={handlePrimaryTheme}
        handleResetSetting={handleResetSetting}
      />
    </header>
  );
}

export default Header;
