import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import "./formation.css";
const Formation = ({ data }) => {
  return (
    <section className="jury-formation">
      <Container className="custom-container">
        <Row className="align-items-center formation-main">
          <Col lg={6}>
            <h2 className="mb-3">{data?.sectionName}</h2>
            <div
              className="ck-content-wrapper mb-lg-4 mb-3"
              dangerouslySetInnerHTML={{ __html: data?.details }}
            />
          </Col>
          <Col lg={6}>
            <div className="img-wrapper text-center">
              <Image
                src={data?.juryImage}
                alt="jury formation img"
                loading="lazy"
                fluid
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Formation;
