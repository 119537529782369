import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getEventList } from "../../redux/events/action";

const FilterEvents = () => {
  const dispatch = useDispatch();
  const Events = useSelector((state) => state.events);
  const [filters, setFilters] = useState({
    heading: "",
    eventType: "",
    location: "",
    year: "",
  });

  useEffect(() => {
    if (!Events?.eventList?.length > 0) dispatch(getEventList());
  }, []);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleResetFilters = () => {
    setFilters({ heading: "", eventType: "", location: "", year: "" });
    dispatch(getEventList());
  };
  const handleSearch = () => {
    dispatch(getEventList(1, 6, filters));
  };

  const currentYear = new Date().getFullYear();
  const yearsList = Array.from(
    { length: currentYear - 1990 + 1 },
    (_, i) => 1990 + i,
  ).reverse();
  return (
    <Container>
      <div className="filter-bar rounded">
        <Row className="align-items-center gy-3">
          <Col xl={3} md={4}>
            <Form.Control
              type="text"
              placeholder="Search by title"
              name="heading"
              value={filters.heading || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={3} md={4}>
            <Form.Control
              type="text"
              placeholder="Search by type"
              name="eventType"
              value={filters.eventType || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={3} md={4}>
            <Form.Control
              type="text"
              placeholder="Search by location"
              name="location"
              value={filters.location || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xl={3} md={4}>
            <Form.Control
              as="select"
              name="year"
              value={filters.year || ""}
              onChange={handleFilterChange}
            >
              <option value="">Select a year</option>
              {yearsList.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xl={{ span: 4, offset: 8 }} md={8}>
            <div className="d-flex justify-content-md-end justify-content-center gap-3">
              <Button
                onClick={handleResetFilters}
                variant="none"
                type="button"
                className="btn-solid-blue btn-sm"
              >
                Reset Filters
              </Button>
              <Button
                onClick={handleSearch}
                variant="none"
                type="button"
                className="btn-solid-blue btn-sm"
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default FilterEvents;
