import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import ServicesCard from "../../components/common/servicesCard/servicesCard";
import { Images } from "../../assets";
import "./aboutLaws.css";
import { getLawTypesData } from "../../redux/laws/action";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const AboutLaws = ({ limit, showDescription }) => {
  const law = useSelector((state) => state.law);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };
  useEffect(() => {
    dispatch(getLawTypesData(currentPage, limit));
  }, [currentPage]);

  return (
    <section className="about-laws">
      <Container className="custom-container position-relative">
        <div className="sec-al-logo">
          <Image src={Images.alLogo} alt="al logo" loading="lazy" fluid />
        </div>
        <div className="text-center mb-lg-5 mb-4 px-xl-5 px-lg-4">
          {showDescription && (
            <div
              className="ck-content-wrapper mb-0"
              dangerouslySetInnerHTML={{ __html: law.lawPageData?.description }}
            ></div>
          )}
        </div>
        <Row className="gy-4">
          {law.laws?.lawTypes?.length > 0 &&
            law.laws.lawTypes.slice(0, limit).map((item, index) => (
              <Col lg={4} sm={6} key={index}>
                <ServicesCard
                  img={item?.lawImage}
                  title={item?.title}
                  content={item?.detail}
                  link="laws"
                  id={item?.id}
                />
              </Col>
            ))}
        </Row>
        {limit > 3 ? (
          law.loader ? (
            <Skeleton height={40} width={200} className="mx-auto mt-4" />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={law.laws?.totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : (
          <div className="text-end mt-4">
            {law.loader ? (
              <Skeleton width={120} height={40} />
            ) : (
              <Link to={`/about-laws`} className="btn btn-solid-blue">
                View All
              </Link>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default AboutLaws;
