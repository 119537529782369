import {
  DOWNLOAD_COUNT,
  FETCH_NEWS,
  FETCH_NEWS_LIST,
  FETCH_SINGLE_NEWS,
  NEWS_LOADER,
} from "../actionTypes";

const initialState = {
  loader: false,
  newsList: [],
  News: {},
  newsDetail: {},
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_NEWS_LIST: {
      return {
        ...state,
        newsList: action.payload,
      };
    }
    case FETCH_SINGLE_NEWS: {
      return {
        ...state,
        newsDetail: action.payload,
      };
    }
    case FETCH_NEWS: {
      return {
        ...state,
        News: action.payload.data[0],
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        News: {
          ...state.News,
          downloadCount: (state.News?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default newsReducer;
