import { ROLE_OF_CENTER_LOADER, SET_ROLE_OF_CENTER_DATA } from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";

export const getRoleOfCenterData = () => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_OF_CENTER_LOADER,
      payload: true,
    });
    const res = await apiHelper("get", "/role-of-center");
    if (res.data) {
      dispatch({
        type: SET_ROLE_OF_CENTER_DATA,
        payload: res.data?.data,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    dispatch({
      type: ROLE_OF_CENTER_LOADER,
      payload: false,
    });
  }
};
