import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMediaPage } from "../../../redux/media-center/action";
import MetaTags from "../../../components/metaTags/seoTags";
import Banner from "../../../components/common/banner/banner";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import {
  getCmsPage,
  updateDownloadCount,
} from "../../../redux/cms-pages/action";
import PageStats from "../../../components/pageStats/pageStats";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import { getSeminars } from "../../../redux/seminars/action";
import Seminars from "./seminars";

export default function SeminarsList() {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const { pageData } = useSelector((state) => state.cmsPages);
  const seminars = useSelector((state) => state.seminars);
  const seminarData = seminars?.seminars;
  const mediaPageContent = media.mediaCenter?.mediaCenter;

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getCmsPage("Seminars"));
  }, []);

  useEffect(() => {
    dispatch(getSeminars(currentPage, 9));
  }, [currentPage]);

  return (
    <>
      {seminars.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={pageData?.pageTitle}
            description={pageData?.metaDescription}
            keywords={pageData?.metaKeywords}
            metaTitle={pageData?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <Seminars
            heading={mediaPageContent?.coursesHeading}
            limit={9}
            seminars={seminarData?.conferences}
            onPageChange={setCurrentPage}
            currentPage={currentPage}
            totalPages={seminarData.totalPages || 1}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={pageData?.downloadCount}
            viewCount={pageData?.pageViewCount}
            pdf={pageData?.pdfFile}
            id={pageData?.id}
            loader={pageData?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
