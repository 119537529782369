import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./actionFeedback.css";
import TooLow from "../../../assets/images/too-low.png";
import Sad from "../../../assets/images/sad.png";
import Average from "../../../assets/images/avrage.png";
import Happy from "../../../assets/images/happy.png";
import Excelent from "../../../assets/images/excelent.png";
import Fav from "../../../assets/images/fav.svg";
import BookmarkSquare from "../../../assets/images/bookmark-square.svg";
import Report from "../../../assets/images/report.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  feedbackSchema,
  reportSchema,
} from "../../../validation/validationSchema";
import {
  addToFavourite,
  createFeedback,
  getFavorite,
  reportLink,
} from "../../../redux/feedback/action";
import { helperFunction } from "../../../utils/helperFunctions";
import { Modal, Button, Image } from "react-bootstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  RedditIcon,
} from "react-share";

const ActionFeedback = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const userId = helperFunction.getUserInfo();
  const feedback = useSelector((state) => state.reviews);
  const shareUrl = window.location.href;
  const shareTitle = "Check out this feedback page!";

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(feedbackSchema),
  });

  const {
    register: registerReport,
    handleSubmit: handleReportSubmit,
    setValue: setReportValue,
    formState: { errors: reportErrors },
    reset: resetReport,
  } = useForm({
    resolver: yupResolver(reportSchema),
  });

  useEffect(() => {
    if (userId) {
      const payload = { userId, pageUrl: shareUrl };
      dispatch(getFavorite(payload));
    }
  }, []);
  const handleEmojiClick = (value) => {
    setValue("rating", value);
    setShowForm(true);
  };

  const handleBackClick = () => {
    setShowForm(false);
  };

  const onFeedbackSubmit = (data) => {
    if (!userId) {
      toast.error("Please login first to send feedback.");
      return;
    }

    const paylaod = {
      ...data,
      userId: userId,
      pageUrl: shareUrl,
    };
    dispatch(createFeedback(paylaod));
    reset();
    setShowForm(false);
  };

  const toggleFavourite = () => {
    if (!userId) {
      toast.error("Please login first to add to favourites.");
      return;
    }

    const payload = {
      userId: userId,
      pageUrl: shareUrl,
    };
    dispatch(addToFavourite(payload));
  };

  const toggleBookmark = () => {
    const title = document.title;
    const url = window.location.href;

    if (window.sidebar && window.sidebar.addPanel) {
      window.sidebar.addPanel(title, url, "");
      toast.success("Bookmark added successfully!");
    } else if (window.external && "AddFavorite" in window.external) {
      window.external.AddFavorite(url, title);
      toast.success("Bookmark added successfully!");
    } else if (window.chrome) {
      toast.info(
        "Press " +
          (navigator.userAgent.indexOf("Mac") !== -1 ? "Command/Cmd" : "Ctrl") +
          " + D to bookmark this page.",
      );
    } else {
      toast.info(
        "Your browser does not support adding bookmarks. Please use the bookmark feature in your browser.",
      );
    }
  };

  const handleReportLinkSubmit = (data) => {
    if (!userId) {
      toast.error("Please login first to report link.");
      return;
    }
    const paylaod = {
      ...data,
      userId: userId,
    };
    dispatch(reportLink(paylaod));
    setIsReportModalOpen(false);
    resetReport();
  };
  return (
    <section className="action-feedback container pt-5 pb-5">
      <div className="row align-items-center action-header pb-3 ">
        <div className="col-md-6">
          <h5 className="section-title">Take Action</h5>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-end gap-3 text-md-end text-center">
          <h5 className="share-title">Share this page</h5>
          <div className="share-icons gap-2 d-flex">
            <FacebookShareButton url={shareUrl} quote={shareTitle}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={shareTitle}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} title={shareTitle}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} title={shareTitle}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <TelegramShareButton url={shareUrl} title={shareTitle}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <RedditShareButton url={shareUrl} title={shareTitle}>
              <RedditIcon size={32} round />
            </RedditShareButton>
          </div>
        </div>
      </div>
      <div className="row justify-content-between align-items-start mt-5">
        <div className="col-lg-6 col-md-12">
          <div className="action-item">
            <span>Add to my favourite page</span>
            <button onClick={toggleFavourite}>
              {feedback?.favorite?.isFavorite
                ? "Remove from Favorites"
                : "Add to Favourite"}{" "}
              <Image
                className={`${feedback?.favorite?.isFavorite ? "red-heart" : ""}`}
                src={Fav}
                alt="heart icon"
                loading="lazy"
              />
            </button>
          </div>
          <div className="action-item">
            <span>Bookmark this page</span>
            <button onClick={toggleBookmark}>
              Bookmark Page <Image src={BookmarkSquare} alt="bookmark icon" />
            </button>
          </div>
          <div className="action-item">
            <span>Report corrupted link</span>
            <button
              onClick={() => {
                setReportValue("pageUrl", shareUrl);
                setIsReportModalOpen(true);
              }}
            >
              Report Link <Image src={Report} alt="report icon" />
            </button>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 text-center">
          <h5 className="section-title">Content Feedback</h5>
          {!showForm ? (
            <div className="">
              <p className="feedback-question">
                How would you rate your experience on our website?
              </p>
              <div className="feedback-emojis">
                {[1, 2, 3, 4, 5]?.map((value) => (
                  <span
                    key={value}
                    className="emoji"
                    onClick={() => handleEmojiClick(value)}
                    role="button"
                  >
                    <Image
                      src={
                        value === 1
                          ? TooLow
                          : value === 2
                            ? Sad
                            : value === 3
                              ? Average
                              : value === 4
                                ? Happy
                                : Excelent
                      }
                      alt={`Rating ${value}`}
                    />
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <form
              className="tell-us-form"
              onSubmit={handleSubmit(onFeedbackSubmit)}
            >
              <div className="form-content-wrapper">
                <div className="d-flex align-items-center justify-content-center text-center flex-column mb-4">
                  <label>Tell us more about your experience.</label>
                  <textarea
                    placeholder="Enter here…"
                    {...register("feedback")}
                  ></textarea>
                  {errors.feedback && (
                    <p className="error-message">{errors.feedback.message}</p>
                  )}
                </div>
                <div className="back-submit-btns d-flex justify-content-between align-items-center">
                  <span onClick={handleBackClick}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </span>
                  <button type="submit" className="btn btn-solid-blue">
                    SUBMIT
                    <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      {/* Report Modal */}
      <Modal
        show={isReportModalOpen}
        onHide={() => setIsReportModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report a Corrupted Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleReportSubmit(handleReportLinkSubmit)}>
            <div className="mb-3">
              <label htmlFor="pageUrl" className="form-label">
                URL
              </label>
              <input
                type="text"
                {...registerReport("pageUrl")}
                className="form-control"
              />
              {reportErrors.pageUrl && (
                <p className="error-message">{reportErrors.pageUrl.message}</p>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="comment" className="form-label">
                Comment
              </label>
              <textarea
                {...registerReport("feedback")}
                className="form-control"
              />
              {reportErrors.feedback && (
                <p className="error-message">{reportErrors.feedback.message}</p>
              )}
            </div>
            <Button variant="primary" type="submit">
              Submit Report
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ActionFeedback;
