import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCount,
  getInfoPage,
} from "../../../../redux/media-center/action";
import PageStats from "../../../../components/pageStats/pageStats";
import ActionFeedback from "../../../../components/common/takeActions/actionFeedback";
import MetaTags from "../../../../components/metaTags/seoTags";

/**
 * IntellectualPropertyNotice component.
 *
 * This component is used to render the Intellectual Property Notice page.
 *
 * It fetches the data from the server using the `getInfoPage` action and then renders
 * the page with the fetched data.
 *
 * The page includes a heading, a description, a page stats component and an action feedback component.
 *
 * @returns {React.ReactElement} The Intellectual Property Notice page.
 */
const IntellectualPropertyNotice = () => {
  const dispatch = useDispatch();

  const media = useSelector((state) => state.media);
  const intellectualProperty = media.infoPage;
  useEffect(() => {
    dispatch(getInfoPage("Intellectual Property Notice"));
  }, []);
  return (
    <>
      <MetaTags
        title={intellectualProperty?.pageTitle}
        description={intellectualProperty?.metaDescription}
        keywords={intellectualProperty?.metaKeywords}
        metaTitle={intellectualProperty?.metaTitle}
      />
      <section className="terms-snd-condtion legal-information-page no-banner-page">
        <Container>
          <span className="h2">{intellectualProperty.heading}</span>
          <div
            className="ck-content-wrapper mb-lg-3 mb-2"
            dangerouslySetInnerHTML={{
              __html: intellectualProperty.description,
            }}
          />
        </Container>
      </section>
      <PageStats
        countAction={downloadCount}
        downloadCount={intellectualProperty?.downloadCount}
        viewCount={intellectualProperty?.pageViewCount}
        pdf={intellectualProperty?.pdfFile}
        id={intellectualProperty?.id}
        loader={intellectualProperty?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default IntellectualPropertyNotice;
