import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Images } from "../../../assets";
import "./legalAdvice.css";
import { Link } from "react-router-dom";
function LegalAdvice({ loader, advice }) {
  return (
    <section className="legal-advice">
      <Container className="custom-container">
        <Row>
          <Col lg={6} className="position-relative">
            {loader ? (
              <Skeleton height={400} width="100%" />
            ) : (
              <div className="arbirator-woman">
                <Image
                  src={
                    advice?.mediaCenter?.adviceImage || Images.placeholderImage
                  }
                  alt="arbitrator woman"
                  loading="lazy"
                  className="h-100"
                  fluid
                />
              </div>
            )}
          </Col>
          <Col lg={6}>
            <div className="content-wrapper">
              <div className="mb-xl-5 mb-4">
                <strong className="advice-text text-uppercase fw-bold">
                  {loader ? (
                    <Skeleton width={200} />
                  ) : (
                    advice?.mediaCenter?.adviceHeading
                  )}
                </strong>
                <div className="ck-content-wrapper p-lg">
                  {loader ? (
                    <Skeleton count={4} height={20} />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: advice?.mediaCenter?.adviceDescription,
                      }}
                    />
                  )}
                </div>
              </div>
              <Link
                to={"/contact-us"}
                variant="none"
                className="btn btn-solid-blue"
              >
                FREE CONSULTATION
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LegalAdvice;
