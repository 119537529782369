import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./banner.css";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
function Banner({ loader, title, content, img, addButton }) {
  return (
    <section
      className="common-banner text-white"
      style={{
        backgroundImage: loader ? "none" : `url(${img})`,
        backgroundColor: loader ? "#c8c8c8" : "transparent",
      }}
    >
      <Container className="custom-container">
        <Row>
          <Col xxl={7} xl={8} lg={9}>
            <h1 className="mb-3">
              {loader ? <Skeleton width={300} /> : title}
            </h1>
            {loader ? (
              <Skeleton count={3} />
            ) : (
              <div
                className="ck-content-wrapper"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {addButton?.enabled && (
              <div className="d-flex align-items-center gap-3 mt-4">
                {addButton.firstButton && (
                  <Link
                    to={addButton.firstButton.link}
                    target="_blank"
                    className="btn btn-solid-blue"
                  >
                    {addButton.firstButton.text}
                  </Link>
                )}
                {addButton.secondButton && (
                  <Link
                    to={addButton.secondButton.link}
                    target="_blank"
                    className="btn btn-solid-blue"
                  >
                    {addButton.secondButton.text}
                  </Link>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Banner;
