import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Images } from "../../../assets";
import NewsCard from "../newsCard/newsCard";
import "./latestNews.css";
import { formatDate } from "../../../utils/formatDate";
import ReactPaginate from "react-paginate";
import { getNewsList } from "../../../redux/news/action";
import { Link } from "react-router-dom";

function LatestNews({ pageCount, newsList, mainTitle, limit = 4, paginate }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);

  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };
  useEffect(() => {
    dispatch(getNewsList(currentPage, limit));
  }, [currentPage, pageOffset]);

  return (
    <>
      {newsList?.length > 0 ? (
        <section className="latest-news-sec news-section">
          <Container className="custom-container" id="news-section">
            <h2 className="mb-lg-5 mb-4">{mainTitle}</h2>
            <Row>
              {newsList.slice(0, limit).map((news, index) => (
                <NewsCard
                  key={index}
                  id={news.id}
                  img={news.newsImage || Images.articleImg1}
                  preTitle={`${formatDate(news.createdAt)} | ${news.category}`}
                  title={news.title}
                  content={news.detail}
                  loader={news.loader}
                />
              ))}
            </Row>
            {!paginate ? (
              <div className="text-center mt-4">
                <Link to="/news" className="btn btn-solid-blue">
                  View all News
                </Link>
              </div>
            ) : (
              ""
            )}
            {paginate ? (
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                onPageChange={HandlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
                marginPagesDisplayed={2}
                activeClassName="active"
                forcePage={pageOffset}
              />
            ) : (
              ""
            )}
          </Container>
        </section>
      ) : (
        ""
      )}
    </>
  );
}

export default LatestNews;
