import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getPhotos } from "../../../redux/photo-album/action";
import { getVideos } from "../../../redux/videos/action";

export default function PhotoAlbumFilters({ filters, setFilters, type }) {
  const dispatch = useDispatch();

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleResetFilters = () => {
    setFilters({ title: "" });
    if (type === "video") {
      dispatch(getVideos(1, 9, {}));
    } else {
      dispatch(getPhotos(1, 16, {}));
    }
  };

  const handleSearch = () => {
    if (type === "video") {
      dispatch(getVideos(1, 9, filters));
    } else {
      dispatch(getPhotos(1, 16, filters));
    }
  };
  return (
    <Container>
      <div className="filter-bar rounded">
        <Row className="align-items-center justify-content-end gy-3">
          <Col xl={4} md={4}>
            <Form.Control
              type="text"
              placeholder="Search by Title"
              name="title"
              value={filters.title || ""}
              onChange={handleFilterChange}
            />
          </Col>
          <Col md={8}>
            <div className="d-flex justify-content-md-end justify-content-center gap-3">
              <Button
                onClick={handleResetFilters}
                variant="none"
                type="button"
                className="btn-solid-blue btn-sm flex-grow-0"
              >
                Reset Filters
              </Button>
              <Button
                onClick={handleSearch}
                variant="none"
                type="button"
                className="btn-solid-blue btn-sm flex-grow-0"
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
