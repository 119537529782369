import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  FETCH_EVENTS_LIST,
  FETCH_SINGLE_EVENTS,
  EVENTS_LOADER,
  DOWNLOAD_COUNT,
} from "../actionTypes";

export const getEventList =
  (page = 1, limit = 6, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EVENTS_LOADER,
        payload: true,
      });
      const pageNumber = Number(page) || 1;
      const limitNumber = Number(limit) || 6;

      const filterParams = {};
      if (filters.heading) filterParams.heading = filters.heading;
      if (filters.eventType) filterParams.eventType = filters.eventType;
      if (filters.location) filterParams.location = filters.location;
      if (filters.year) filterParams.year = filters.year;
      const query = new URLSearchParams({
        page: pageNumber,
        limit: limitNumber,
        ...filterParams,
      }).toString();
      const res = await apiHelper("get", `/event/public?${query}`);
      if (res.data) {
        dispatch({
          type: FETCH_EVENTS_LIST,
          payload: res.data.data,
        });
        dispatch({
          type: EVENTS_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: EVENTS_LOADER,
        payload: false,
      });
    }
  };

/**
 * @function getEventDetail
 * @description Fetches the details of a single event by event ID and updates the state with the event data.
 * Dispatches EVENTS_LOADER with true before making an API call. On success, dispatches FETCH_SINGLE_EVENTS with the event data and sets EVENTS_LOADER to false.
 * In case of an error, displays an error toast and sets EVENTS_LOADER to false.
 *
 * @param {string} id - The ID of the event to fetch details for.
 * @returns {void}
 */

export const getEventDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENTS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/event/${id}`);
    if (res.data) {
      dispatch({
        type: FETCH_SINGLE_EVENTS,
        payload: res.data.data,
      });
      dispatch({
        type: EVENTS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: EVENTS_LOADER,
      payload: false,
    });
  }
};

/**
 * Asynchronously updates the download count for a specific event.
 * Dispatches EVENTS_LOADER with true before making an API call to fetch
 * the download count. On success, dispatches DOWNLOAD_COUNT and sets
 * EVENTS_LOADER to false. In case of an error, displays an error toast
 * and sets EVENTS_LOADER to false.
 *
 * @param {number} id - The ID of the event for which to update the download count.
 * @returns {void}
 */
export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENTS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/event/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: EVENTS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: EVENTS_LOADER,
      payload: false,
    });
  }
};
