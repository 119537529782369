import {
  CMS_PAGE_LOADER,
  DOWNLOAD_COUNT,
  SET_CMS_PAGE_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  pageData: {},
};

const cmsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CMS_PAGE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_CMS_PAGE_DATA: {
      return {
        ...state,
        pageData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          downloadCount: (state.pageData?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default cmsPageReducer;
