import {
  WORKSHOPS_LOADER,
  DOWNLOAD_COUNT,
  SET_WORKSHOP_DETAIL_DATA,
  SET_WORKSHOPS_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";

export const getWorkshops =
  (page = 1, limit = 10, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: WORKSHOPS_LOADER,
        payload: true,
      });
      const queryParams = new URLSearchParams({
        page,
        limit,
        ...filters,
      }).toString();

      const res = await apiHelper(
        "get",
        `/media/workshops/list?${queryParams}`,
      );
      if (res.data) {
        dispatch({
          type: SET_WORKSHOPS_DATA,
          payload: res.data.data,
        });
      }
      dispatch({
        type: WORKSHOPS_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: WORKSHOPS_LOADER,
        payload: false,
      });
    }
  };

export const getWorkshopDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WORKSHOPS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/workshops/${id}`);
    if (res.data) {
      dispatch({
        type: SET_WORKSHOP_DETAIL_DATA,
        payload: res.data.data,
      });
    }
    dispatch({
      type: WORKSHOPS_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: WORKSHOPS_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WORKSHOPS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/workshops/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: WORKSHOPS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: WORKSHOPS_LOADER,
      payload: false,
    });
  }
};
