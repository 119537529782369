import React from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config } from "../config/config";
import { Images } from "../assets";
import { Image } from "react-bootstrap";
import "./dashboard.css";

const AdminLayout = () => {
  const { i18n } = useTranslation();

  const handleTranslate = (event, code) => {
    event.preventDefault();
    i18n.changeLanguage(code);
  };

  return (
    <>
      <div className="dasboard-header d-flex align-items-center justify-content-between">
        <h1 className="mb-0">Navbar</h1>
        <div className="logo-icon d-flex align-items-center justify-content-center">
          <Image src={Images.headerLogo} alt="Logo icon" loading="lazy" fluid />
        </div>
        <div className="bt-container d-flex gap-2">
          {config.lngs.map((lng, i) => {
            const { code, native } = lng;
            return (
              <button onClick={(event) => handleTranslate(event, code)} key={i}>
                {native}
              </button>
            );
          })}
        </div>
      </div>
      {<Outlet />}
    </>
  );
};
export default AdminLayout;
