import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  DOWNLOAD_COUNT,
  FETCH_NEWS,
  FETCH_NEWS_LIST,
  FETCH_SINGLE_NEWS,
  NEWS_LOADER,
} from "../actionTypes";

/**
 * @function getNewsList
 * @description Retrieves a list of news items based on the provided pagination and filter criteria.
 * @param {number} [page=1] - The page number to retrieve.
 * @param {number} [limit=10] - The number of news items per page.
 * @param {object} filters - The filters to apply to the news list retrieval.
 * @param {string} [filters.title] - Filter news by title.
 * @param {string} [filters.category] - Filter news by category.
 * @param {string} [filters.year] - Filter news by year.
 * @returns {void}
 */
export const getNewsList =
  (page = 1, limit = 6, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: NEWS_LOADER,
        payload: true,
      });
      const pageNumber = Number(page) || 1;
      const limitNumber = Number(limit) || 4;

      const filterParams = {};
      if (filters.title) filterParams.title = filters.title;
      if (filters.category) filterParams.category = filters.category;
      if (filters.year) filterParams.year = filters.year;
      const query = new URLSearchParams({
        page: pageNumber,
        limit: limitNumber,
        ...filterParams,
      }).toString();
      const res = await apiHelper("get", `/cms/news/public?${query}`);
      if (res.data) {
        dispatch({
          type: FETCH_NEWS_LIST,
          payload: res.data.data,
        });
        dispatch({
          type: NEWS_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: NEWS_LOADER,
        payload: false,
      });
    }
  };

/**
 * @function getNews
 * @description Gets the news page data by pageTag
 * @param {object} filters - The filters to get the news page data
 * @param {string} filters.pageTag - The pageTag to get the news page data
 * @returns {object} The news page data
 */
export const getNews =
  (filters = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: NEWS_LOADER,
        payload: true,
      });
      const filterParams = {};
      if (filters.pageTag) filterParams.pageTag = filters.pageTag;

      const query = new URLSearchParams({
        ...filterParams,
      }).toString();
      const res = await apiHelper("get", `/cms/detail-page?${query}`);
      if (res.data) {
        dispatch({
          type: FETCH_NEWS,
          payload: res.data.data,
        });
        dispatch({
          type: NEWS_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: NEWS_LOADER,
        payload: false,
      });
    }
  };

/**
 * @function getNewsDetail
 * @description Gets a single news detail by id
 * @param {string} id - The id of the news
 * @returns {object} A single news detail
 */
export const getNewsDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/news/${id}`);
    if (res.data) {
      dispatch({
        type: FETCH_SINGLE_NEWS,
        payload: res.data.data,
      });
      dispatch({
        type: NEWS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: NEWS_LOADER,
      payload: false,
    });
  }
};
/**
 * Asynchronously updates the download count for a specific news item.
 * Dispatches NEWS_LOADER with true before making an API call to fetch
 * the download count. On success, dispatches DOWNLOAD_COUNT and sets
 * NEWS_LOADER to false. In case of an error, displays an error toast
 * and sets NEWS_LOADER to false.
 *
 * @param {number} id - The ID of the news item for which to update the download count.
 * @returns {void}
 */

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/news/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: NEWS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: NEWS_LOADER,
      payload: false,
    });
  }
};

/**
 * Asynchronously downloads a specific news item by its ID.
 * Dispatches NEWS_LOADER with true to indicate loading state before
 * making an API call to download the news item. On successful download,
 * it dispatches DOWNLOAD_COUNT and sets NEWS_LOADER to false.
 * If an error occurs, it displays an error toast and sets NEWS_LOADER
 * to false.
 *
 * @param {number} id - The ID of the news item to download.
 * @returns {void}
 */

export const downloadNews = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/download-detail-page/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: NEWS_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: NEWS_LOADER,
      payload: false,
    });
  }
};
