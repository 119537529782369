import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCount,
  getInfoPage,
} from "../../../../redux/media-center/action";
import PageStats from "../../../../components/pageStats/pageStats";
import ActionFeedback from "../../../../components/common/takeActions/actionFeedback";
import MetaTags from "../../../../components/metaTags/seoTags";

/**
 * ComplianceApplicableLaws component displays the compliance with applicable laws page
 * @returns {JSX.Element}
 */
const ComplianceApplicableLaws = () => {
  const dispatch = useDispatch();

  const media = useSelector((state) => state.media);
  const applicableLaws = media.infoPage;
  useEffect(() => {
    dispatch(getInfoPage("Compliance with Applicable Laws"));
  }, []);
  return (
    <>
      <MetaTags
        title={applicableLaws?.pageTitle}
        description={applicableLaws?.metaDescription}
        keywords={applicableLaws?.metaKeywords}
        metaTitle={applicableLaws?.metaTitle}
      />
      <section className="terms-snd-condtion legal-information-page no-banner-page">
        <Container>
          <span className="h2">{applicableLaws.heading}</span>
          <div
            className="ck-content-wrapper mb-lg-3 mb-2"
            dangerouslySetInnerHTML={{ __html: applicableLaws.description }}
          />
        </Container>
      </section>
      <PageStats
        countAction={downloadCount}
        downloadCount={applicableLaws?.downloadCount}
        viewCount={applicableLaws?.pageViewCount}
        pdf={applicableLaws?.pdfFile}
        id={applicableLaws?.id}
        loader={applicableLaws?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default ComplianceApplicableLaws;
