import {
  COURSES_LOADER,
  DOWNLOAD_COUNT,
  SET_COURSE_DETAIL_DATA,
  SET_COURSES_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";

export const getCourses =
  (page = 1, limit = 10) =>
  async (dispatch) => {
    try {
      dispatch({
        type: COURSES_LOADER,
        payload: true,
      });

      const res = await apiHelper(
        "get",
        `/media/courses/list?page=${page}&limit=${limit}`,
      );
      if (res.data) {
        dispatch({
          type: SET_COURSES_DATA,
          payload: res.data.data,
        });
      }
      dispatch({
        type: COURSES_LOADER,
        payload: false,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: COURSES_LOADER,
        payload: false,
      });
    }
  };
export const getCourseDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSES_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/courses/${id}`);
    if (res.data) {
      dispatch({
        type: SET_COURSE_DETAIL_DATA,
        payload: res.data.data,
      });
    }
    dispatch({
      type: COURSES_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: COURSES_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSES_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/media/courses/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: COURSES_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: COURSES_LOADER,
      payload: false,
    });
  }
};
