import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import "./servicesCard.css";

const ServicesCard = ({ loader, preTitle, title, img, content, link, id }) => {
  return (
    <div className="services-card">
      <div className="img-wrapper">
        {loader ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <Image src={img} alt="service img" loading="lazy" fluid />
        )}
      </div>
      <div className="card-content">
        <div className="upper-sec">
          <span className="pre-heading">
            {loader ? <Skeleton width="120px" /> : preTitle}
          </span>
          <h4 className="mb-2">
            {loader ? <Skeleton width="100%" count={2} /> : title}
          </h4>
          {loader ? (
            <Skeleton count={3} width={"100%"} />
          ) : (
            <div
              className="ck-content-wrapper"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
        <div className="text-end">
          {loader ? (
            <Skeleton width={80} height={24} />
          ) : (
            link && (
              <Link to={`/${link}/${id}`}>
                <span
                  className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                  role="button"
                >
                  Details
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </span>
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
