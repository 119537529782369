import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Image, Row, Form } from "react-bootstrap";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import { formatDate } from "../../../utils/formatDate";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { getLibrary } from "../../../redux/e-library/action";
import "./electronicLibrary.css";

const ElectronicLibrary = ({
  loader,
  heading,
  libraries,
  limit,
  onPageChange,
  currentPage,
  totalPages,
}) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    title: "",
    startDate: "",
    endDate: "",
  });

  const HandlePageClick = (event) => {
    onPageChange(event.selected + 1);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleResetFilters = () => {
    setFilters({ title: "", startDate: "", endDate: "" });
    if (onPageChange) onPageChange(1);
    dispatch(getLibrary());
  };

  const handleSearch = () => {
    if (onPageChange) onPageChange(1);
    dispatch(getLibrary(1, limit, filters));
  };

  return (
    <section className="electronic-library">
      <Container>
        {limit > 4 && (
          <div className="filter-bar rounded mb-5">
            <Row className="align-items-center justify-content-end gy-3">
              {["title", "startDate", "endDate"].map((field, index) => (
                <Col xl={3} md={4} key={index}>
                  {loader ? (
                    <Skeleton height={38} />
                  ) : (
                    <Form.Control
                      type={field === "title" ? "text" : "date"}
                      placeholder={field === "title" ? "Search by title" : ""}
                      name={field}
                      value={filters[field] || ""}
                      onChange={handleFilterChange}
                    />
                  )}
                </Col>
              ))}
              <Col xl={3} lg={4}>
                <div className="d-flex justify-content-md-end justify-content-center gap-3">
                  {loader ? (
                    <>
                      <Skeleton width={120} height={38} />
                      <Skeleton width={120} height={38} />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleResetFilters}
                        variant="none"
                        type="button"
                        className="btn-solid-blue btn-sm"
                      >
                        Reset Filters
                      </Button>
                      <Button
                        onClick={handleSearch}
                        variant="none"
                        type="button"
                        className="btn-solid-blue btn-sm"
                      >
                        Search
                      </Button>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
        <h2 className="mb-lg-5 mb-4">
          {loader ? <Skeleton width={200} /> : heading}
        </h2>
        <Row>
          {!libraries?.length > 0 ? (
            <p>No data to display.</p>
          ) : (
            libraries?.slice(0, limit)?.map((article, index) => (
              <Col lg={6} md={12} className="mb-3" key={index}>
                <div className="news-article-wrapper d-flex h-100 align-items-center">
                  <div className="news-article-img">
                    {loader ? (
                      <Skeleton height="100%" width="100%" />
                    ) : (
                      <Image
                        src={article?.image}
                        alt="article image"
                        loading="lazy"
                        className="h-100 w-100"
                        fluid
                      />
                    )}
                  </div>
                  <div className="news-article-content">
                    {loader ? (
                      <Skeleton width={100} />
                    ) : (
                      <span className="pre-heading d-inline-block mb-2">
                        {formatDate(article?.createdAt)}
                      </span>
                    )}
                    <h4 className="mb-lg-3 mb-2">
                      {loader ? <Skeleton width="80%" /> : article?.heading}
                    </h4>
                    {loader ? (
                      <Skeleton count={3} />
                    ) : (
                      <div
                        className="ck-content-wrapper mb-lg-3 mb-2"
                        dangerouslySetInnerHTML={{
                          __html: article?.description,
                        }}
                      ></div>
                    )}
                    {loader ? (
                      <Skeleton width={80} height={24} className="mt-3" />
                    ) : (
                      <Link to={`/libraries/${article?.id}`}>
                        <span
                          className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                          role="button"
                        >
                          More
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
        {limit > 4 ? (
          loader ? (
            <Skeleton height={40} width={200} className="mx-auto mt-4" />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : (
          <div className="text-end mt-4">
            {loader ? (
              <Skeleton width={120} height={40} />
            ) : (
              <Link to={`/libraries`} className="btn btn-solid-blue">
                View All
              </Link>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default ElectronicLibrary;
