import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCount,
  getInfoPage,
} from "../../../../redux/media-center/action";
import PageStats from "../../../../components/pageStats/pageStats";
import ActionFeedback from "../../../../components/common/takeActions/actionFeedback";
import MetaTags from "../../../../components/metaTags/seoTags";

/**
 * RefundCancellationPolicy
 *
 * This component renders the refund and cancellation policy page
 *
 * @returns {React.ReactElement} The JSX element representing the refund and cancellation policy page
 */
const RefundCancellationPolicy = () => {
  const dispatch = useDispatch();

  const media = useSelector((state) => state.media);
  const refundPolicy = media.infoPage;
  useEffect(() => {
    dispatch(getInfoPage("Refund and Cancellation Policy"));
  }, []);
  return (
    <>
      <MetaTags
        title={refundPolicy?.pageTitle}
        description={refundPolicy?.metaDescription}
        keywords={refundPolicy?.metaKeywords}
        metaTitle={refundPolicy?.metaTitle}
      />
      <section className="terms-snd-condtion legal-information-page no-banner-page">
        <Container>
          <span className="h2">{refundPolicy.heading}</span>
          <div
            className="ck-content-wrapper mb-lg-3 mb-2"
            dangerouslySetInnerHTML={{ __html: refundPolicy.description }}
          />
        </Container>
      </section>
      <PageStats
        countAction={downloadCount}
        downloadCount={refundPolicy?.downloadCount}
        viewCount={refundPolicy?.pageViewCount}
        pdf={refundPolicy?.pdfFile}
        id={refundPolicy?.id}
        loader={refundPolicy?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default RefundCancellationPolicy;
