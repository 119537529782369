import React from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "./centralBuildingSec.css";

const CentralBuildingSec = ({ loader, title, description, image }) => {
  return (
    <section
      className="central-building-sec text-center text-white position-relative"
      style={{
        backgroundImage: loader ? "none" : `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: loader ? "#ccc" : "transparent",
        minHeight: "300px",
      }}
    >
      <Container className="custom-container">
        <h2 className="mb-3 text-white">
          {loader ? <Skeleton width={250} /> : title}
        </h2>
        {loader ? (
          <Skeleton count={4} height={20} />
        ) : (
          <div
            className="ck-content-wrapper mb-4"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Container>
    </section>
  );
};

export default CentralBuildingSec;
