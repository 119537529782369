import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { formatDate } from "../../../utils/formatDate";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function VideoAlbum({
  videos,
  heading,
  limit,
  totalPages,
  currentPage,
  onPageChange,
}) {
  const HandlePageClick = (event) => {
    onPageChange(event.selected + 1);
  };
  return (
    <section className="video-album pt-5">
      <Container>
        <h2 className="mb-lg-5 mb-4">{heading}</h2>
        <Row className="gx-lg-4 gx-3">
          {videos?.slice(0, limit)?.map((video, index) => (
            <Col key={index} md={4} sm={6} xs={12} className="mb-4">
              <div className="video-body">
                <div className="video-thumbnail position-relative">
                  <video
                    src={video?.video}
                    width="100%"
                    height="200"
                    controls
                    preload="metadata"
                  />
                </div>
                <div className="video-details d-flex align-items-center">
                  <div className="date text-center me-3 flex-shrink-0">
                    <span className="fw-semibold">
                      {formatDate(video?.createdAt)
                        ?.split(" ")[1]
                        ?.replace(",", "")
                        ?.padStart(2, "0")}
                    </span>
                    <p className="month text-uppercase mb-0">
                      {formatDate(video?.createdAt).split(" ")[0].toUpperCase()}
                    </p>
                  </div>
                  <Link
                    className="flex-grow-1 text-decoration-none text-blue-1"
                    to={`/videos/${video?.id}`}
                  >
                    <p className="mb-0">{video?.title}</p>
                  </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          onPageChange={HandlePageClick}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
          marginPagesDisplayed={2}
          activeClassName="active"
          forcePage={currentPage - 1}
        />
      </Container>
    </section>
  );
}

export default VideoAlbum;
