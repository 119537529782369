import {
  DOWNLOAD_COUNT,
  FETCH_EVENTS,
  FETCH_EVENTS_LIST,
  FETCH_SINGLE_EVENTS,
  EVENTS_LOADER,
} from "../actionTypes";

const initialState = {
  loader: false,
  eventList: [],
  eventDetail: {},
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_EVENTS_LIST: {
      return {
        ...state,
        eventList: action.payload,
      };
    }
    case FETCH_SINGLE_EVENTS: {
      return {
        ...state,
        eventDetail: action.payload,
      };
    }
    case FETCH_EVENTS: {
      return {
        ...state,
        eventDetail: action.payload.data[0],
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          downloadCount: (state.eventDetail?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default eventReducer;
