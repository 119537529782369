import React, { useCallback, useEffect, useMemo, useState } from "react";
import Banner from "../../components/common/banner/banner";
import { Images } from "../../assets";
import "./calculatorFees.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  fetchCalculatorData,
  getSiteSettingData,
} from "../../redux/site-settings/action";
import { calculateFees } from "../../utils/calculatorFunction/calculatorFunction";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

const CalculatorFees = () => {
  const dispatch = useDispatch();
  const siteSettings = useSelector((state) => state.siteSettings);

  useEffect(() => {
    dispatch(fetchCalculatorData());
    if (!siteSettings?.siteSettingsData?.length) {
      dispatch(getSiteSettingData());
    }
  }, [dispatch, siteSettings?.siteSettingsData?.length]);

  const calculatorData = useMemo(
    () => siteSettings?.calculatorData,
    [siteSettings],
  );

  const [aedValue, setAedValue] = useState("");
  const [result, setResult] = useState(null);

  const calculate = useCallback(
    (event) => {
      event.preventDefault();
      setResult(calculateFees(aedValue));
    },
    [aedValue],
  );
  return (
    <>
      <Banner
        img={Images.calculatorBannerBg}
        title={"Calculator Fees"}
        content={
          "Arbitrators facilitate the resolution of commercial conflicts through specialized arbitrators, promoting a swift and effective means of settling disputes."
        }
      />
      <section className="calculator-fees-sec">
        <Container>
          <div className="heading-wrapper mb-lg-5 mb-4">
            <h2 className="secondary-color mb-3">
              Arbitration fees E-Calculator
            </h2>
            <p>
              Nullam imperdiet, erat dignissim pellentesque laoreet, turpis
              lorem posuere quam, ac fringilla justo felis et leo. Nam sed odio
              ipsum. Quisque venenatis efficitur felis, sit amet dignissim odio
              pharetra eget. Vivamus nulla metus, euismod at magna in,
              pellentesque aliquam neque. Fusce tortor orci, sollicitudin
              feugiat velit a, congue lobortis magna. Nulla tincidunt vitae enim
              vel feugia
            </p>
          </div>
          <Col lg={8}>
            <Form onSubmit={calculate}>
              <div className="d-flex align-items-center gap-4 mb-3">
                <h4 className="text-blue-2 fw-semibold mb-0">AED</h4>
                {calculatorData ? (
                  <Form.Control
                    type="number"
                    className="aed-price"
                    value={aedValue}
                    onChange={(e) => setAedValue(e.target.value)}
                    placeholder="Enter AED amount"
                    min="1"
                    required
                  />
                ) : (
                  <Skeleton width={200} height={50} />
                )}
              </div>

              <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                <h4 className="fw-semibold mb-0">No. of Arbitrators</h4>
                {calculatorData ? (
                  <Form.Select className="number-field" aria-label="number">
                    {Array.from(
                      { length: calculatorData?.noOfArbitrator || 1 },
                      (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ),
                    )}
                  </Form.Select>
                ) : (
                  <Skeleton width={120} height={50} />
                )}
              </div>

              <div className="text-end">
                {calculatorData ? (
                  <Button
                    variant="none"
                    className="btn-solid-blue"
                    type="submit"
                  >
                    CALCULATE
                  </Button>
                ) : (
                  <Skeleton width={120} height={50} />
                )}
              </div>
            </Form>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default CalculatorFees;
