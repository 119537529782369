import React from "react";
import ReactPaginate from "react-paginate";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";
import "./trainingCourses.css";

function TrainingCourses({
  loader,
  courses,
  heading,
  limit,
  onPageChange,
  totalPages,
  currentPage,
}) {
  const HandlePageClick = (event) => {
    onPageChange(event.selected + 1);
  };
  const location = useLocation();

  return (
    <section
      className={`training-courses ${location.pathname === "/courses" ? "pt-5" : ""}`}
    >
      <Container className="custom-container">
        <h4 className="text-blue-1 mb-4">
          {loader ? <Skeleton width="200px" /> : heading}
        </h4>
        <Row className="gy-3 justify-content-center">
          {loader
            ? [...Array(limit)].map((_, index) => (
                <Col lg={4} sm={6} key={index}>
                  <Card className="info-card border-0">
                    <Skeleton height={180} />
                    <Card.Body>
                      <Skeleton width="50%" />
                      <h4 className="title">
                        <Skeleton width="100%" count={2} />
                      </h4>
                      <Skeleton count={3} />
                      <Skeleton width={80} height={24} className="mt-3" />
                    </Card.Body>
                  </Card>
                </Col>
              ))
            : courses?.length > 0 &&
              courses.slice(0, limit).map((course) => (
                <Col lg={4} sm={6} key={course?.id}>
                  <Card className="info-card border-0">
                    <Card.Img variant="top" src={course?.image} />
                    <Card.Body>
                      <span className="pre-heading">{course?.category}</span>
                      <h4 className="title">{course?.title}</h4>
                      <div
                        className="card-text ck-content-wrapper"
                        dangerouslySetInnerHTML={{
                          __html: course?.description,
                        }}
                      ></div>
                      <Link to={`/courses/${course?.id}`}>
                        <span
                          className="details-arrow float-end d-flex align-items-center justify-content-end gap-2"
                          role="button"
                        >
                          Details
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
        </Row>
        {limit > 3 ? (
          loader ? (
            <Skeleton height={40} width={200} className="mx-auto mt-4" />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : (
          <div className="text-end mt-4">
            {loader ? (
              <Skeleton width={120} height={40} />
            ) : (
              <Link to="/courses" className="btn btn-solid-blue">
                View All
              </Link>
            )}
          </div>
        )}
      </Container>
    </section>
  );
}

export default TrainingCourses;
