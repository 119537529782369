import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  SET_MEDIATION_REQUEST_PAGE_DATA,
  MEDIATION_REQUEST_PAGE_LOADER,
} from "../actionTypes";
import { config } from "../../config/config";

export const mediationRequest = (paylaod) => async (dispatch) => {
  try {
    dispatch({
      type: MEDIATION_REQUEST_PAGE_LOADER,
      payload: true,
    });

    const res = await apiHelper(
      "post",
      "/arb-and-med/mediations-request",
      paylaod,
    );
    if (res?.data) {
      dispatch({
        type: SET_MEDIATION_REQUEST_PAGE_DATA,
        payload: res?.data?.data?.mediation,
      });
      toast.success(res?.data?.message);
    }
    dispatch({
      type: MEDIATION_REQUEST_PAGE_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: MEDIATION_REQUEST_PAGE_LOADER,
      payload: false,
    });
  }
};
