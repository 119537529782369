import {
  CMS_PAGE_LOADER,
  DOWNLOAD_COUNT,
  SET_CMS_PAGE_DATA,
} from "../actionTypes";
import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";

export const getCmsPage =
  (pageTag = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: CMS_PAGE_LOADER,
        payload: true,
      });

      const res = await apiHelper("get", `/cms/detail-page?pageTag=${pageTag}`);
      if (res.data) {
        dispatch({
          type: SET_CMS_PAGE_DATA,
          payload: res.data.data?.data[0],
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch({
        type: CMS_PAGE_LOADER,
        payload: false,
      });
    }
  };

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_PAGE_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/cms/detail-page/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: CMS_PAGE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: CMS_PAGE_LOADER,
      payload: false,
    });
  }
};
