import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCount,
  getInfoPage,
} from "../../../../redux/media-center/action";
import PageStats from "../../../../components/pageStats/pageStats";
import ActionFeedback from "../../../../components/common/takeActions/actionFeedback";
import MetaTags from "../../../../components/metaTags/seoTags";

/**
 * @function ArbitrationClause
 * @description This component renders the Arbitration Clause page.
 * It fetches the data from the server and displays it.
 * It also displays the MetaTags for SEO and a PageStats component.
 * @returns {React.Component} The rendered component.
 */
const ArbitrationClause = () => {
  const dispatch = useDispatch();

  const media = useSelector((state) => state.media);
  const arbitrationClause = media.infoPage;
  useEffect(() => {
    dispatch(getInfoPage("Arbitration Clause"));
  }, []);
  return (
    <>
      <MetaTags
        title={arbitrationClause?.pageTitle}
        description={arbitrationClause?.metaDescription}
        keywords={arbitrationClause?.metaKeywords}
        metaTitle={arbitrationClause?.metaTitle}
      />
      <section className="terms-snd-condtion legal-information-page no-banner-page">
        <Container>
          <span className="h2">{arbitrationClause.heading}</span>
          <div
            className="ck-content-wrapper mb-lg-3 mb-2"
            dangerouslySetInnerHTML={{ __html: arbitrationClause.description }}
          />
        </Container>
      </section>
      <PageStats
        countAction={downloadCount}
        downloadCount={arbitrationClause?.downloadCount}
        viewCount={arbitrationClause?.pageViewCount}
        pdf={arbitrationClause?.pdfFile}
        id={arbitrationClause?.id}
        loader={arbitrationClause?.loader}
      />
      <ActionFeedback />
    </>
  );
};

export default ArbitrationClause;
