import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ActionFeedback from "../../../components/common/takeActions/actionFeedback";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import MetaTags from "../../../components/metaTags/seoTags";
import { getMediaPage } from "../../../redux/media-center/action";
import Detail from "../../../components/common/detail/detail";
import { getCmsPage } from "../../../redux/cms-pages/action";
import Banner from "../../../components/common/banner/banner";
import PageStats from "../../../components/pageStats/pageStats";
import Workshop from "./workshop";
import {
  getWorkshopDetail,
  getWorkshops,
  updateDownloadCount,
} from "../../../redux/workshop/action";

export default function WorkshopDetail() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const media = useSelector((state) => state.media);
  const mediaPageContent = media.mediaCenter?.mediaCenter;
  const workshops = useSelector((state) => state.workshops);
  const workshopsData = workshops?.workshops;
  const singleWorkshop = workshops?.workshopDetail;
  const { pageData } = useSelector((state) => state.cmsPages);
  const { id } = useParams();

  useEffect(() => {
    if (!mediaPageContent?.id) dispatch(getMediaPage());
    dispatch(getWorkshopDetail(id));
    dispatch(getCmsPage("Workshops"));
  }, [id]);

  useEffect(() => {
    dispatch(getWorkshops(currentPage, 9));
  }, [currentPage]);

  return (
    <>
      {workshops.loader ? (
        <FullPageLoader />
      ) : (
        <>
          <MetaTags
            title={singleWorkshop?.pageTitle}
            description={singleWorkshop?.metaDescription}
            keywords={singleWorkshop?.metaKeywords}
            metaTitle={singleWorkshop?.metaTitle}
          />
          <Banner
            title={pageData?.title}
            content={pageData?.detail}
            img={pageData?.image}
            loader={pageData?.loader}
          />
          <Detail {...singleWorkshop} />
          <Workshop
            heading={mediaPageContent?.workshopHeading}
            limit={6}
            workshops={workshopsData?.workshops}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalPages={workshopsData.totalPages || 1}
          />
          <PageStats
            countAction={updateDownloadCount}
            downloadCount={singleWorkshop?.downloadCount}
            viewCount={singleWorkshop?.pageViewCount}
            pdf={singleWorkshop?.pdfFile}
            id={singleWorkshop?.id}
            loader={singleWorkshop?.loader}
          />
          <ActionFeedback />
        </>
      )}
    </>
  );
}
