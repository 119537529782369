import React from "react";
import { Dropdown, Form, Image } from "react-bootstrap";
import { Images } from "../../../assets";

const SearchDropdown = () => {
  return (
    <Dropdown className="search-dropdown position-relative">
      <Dropdown.Toggle variant="none" className="search-icon">
        <Image src={Images.searchIcon} alt="icon" loading="lazy" fluid />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Form className="search-form">
          <div className="input-wrapper d-flex">
            <input type="text" className="form-control" placeholder="Search" />
            <button className="btn btn-sm btn-solid-blue">Search</button>
          </div>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchDropdown;
