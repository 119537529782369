import moment from "moment";

export const formatDate = (date) => {
  if (!date) return "N/A"; // Return 'N/A' if date is falsy

  const formattedDate = new Date(date);

  // Check if the date is valid
  if (isNaN(formattedDate)) return "Invalid Date";

  // Use the Intl.DateTimeFormat for locale-based formatting
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(formattedDate);
};

/**
 * Takes start and end dates and determines the status of the event.
 *
 * Returns one of the following:
 * - "Happening Now" if the event is currently happening
 * - "Finished" if the event has finished
 * - The number of days until the event, in the format "After X Day(s)"
 * - An empty string if the event has no valid dates
 *
 * @param {string} startDate - The start date of the event
 * @param {string} endDate - The end date of the event
 * @returns {string} The status of the event
 */
export const getEventStatus = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const today = moment();

  if (start.isSameOrBefore(today) && end.isAfter(today)) {
    return "Happening Now";
  } else if (end.isBefore(today)) {
    return "Finished";
  } else if (end.isAfter(today)) {
    const daysLeft = end.diff(today, "days");
    return `After ${daysLeft} Day${daysLeft !== 1 ? "s" : ""}`;
  }

  return "";
};

/**
 * Formats the start and end times of an event.
 *
 * @param {string} startTime - The start time of the event in "HH:mm" format.
 * @param {string} endTime - The end time of the event in "HH:mm" format.
 * @returns {string} - A formatted string representing the time range in "h:mm A" format.
 *                     Returns an empty string if either startTime or endTime is falsy.
 */

export const formatEventTime = (startTime, endTime) => {
  if (!startTime || !endTime) return "";
  return `${moment(startTime, "HH:mm").format("h:mm A")} - ${moment(endTime, "HH:mm").format("h:mm A")}`;
};
