import {
  SET_DEPT_CENTER_DATA,
  DEPT_CENTER_LOADER,
  DOWNLOAD_COUNT,
} from "../actionTypes";

const initialState = {
  loader: false,
  departmentCenterData: {},
};

const departmentCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEPT_CENTER_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_DEPT_CENTER_DATA: {
      return {
        ...state,
        departmentCenterData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        departmentCenterData: {
          ...state.departmentCenterData,
          downloadCount: (state.departmentCenterData?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default departmentCenterReducer;
