import React from "react";
import "./photoAlbum.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faImage } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../../utils/formatDate";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";

function PhotoAlbum({
  loader,
  photos,
  heading,
  limit,
  totalPages,
  currentPage,
  onPageChange,
}) {
  const HandlePageClick = (event) => {
    onPageChange(event.selected + 1);
  };

  const itemsToRender = loader
    ? Array.from({ length: limit || 8 })
    : photos?.slice(0, limit);

  return (
    <section className="photo-album">
      <Container>
        <h2 className="mb-lg-5 mb-4">
          {loader ? <Skeleton width={200} height={30} /> : heading}
        </h2>
        <Row className="mb-lg-4 mb-3 gx-lg-4 gx-3 gy-3">
          {itemsToRender?.length > 0
            ? itemsToRender.map((photo, index) => (
                <Col lg={3} md={4} xs={6} key={index}>
                  <div className="content-wrapper position-relative h-100">
                    <div className="img-warpper position-relative h-100">
                      {loader ? (
                        <Skeleton height={200} width="100%" borderRadius={8} />
                      ) : (
                        <Image
                          src={photo?.image}
                          alt="album photo"
                          loading="lazy"
                          className="h-100"
                          fluid
                        />
                      )}
                    </div>
                    <div className="details position-absolute p-xl-3 p-2">
                      <span className="date text-yellow">
                        {loader ? (
                          <Skeleton width={100} height={16} />
                        ) : (
                          formatDate(photo?.createdAt)
                        )}
                      </span>
                      <h4 className="title text-white mb-0">
                        {loader ? (
                          <Skeleton width="80%" height={18} />
                        ) : (
                          photo?.title
                        )}
                      </h4>
                    </div>
                    {!loader && (
                      <Link to={`/photos/${photo?.id}`}>
                        <div className="hover-content text-white">
                          <div className="img-icons position-relative mb-3">
                            <FontAwesomeIcon
                              icon={faImage}
                              className="img-icon1"
                              fontSize={30}
                            />
                            <FontAwesomeIcon
                              icon={faImage}
                              className="img-icon2"
                              fontSize={30}
                            />
                          </div>
                          <p className="fw-semibold">View Album</p>
                        </div>
                      </Link>
                    )}
                  </div>
                </Col>
              ))
            : !loader && <p>No photos to show.</p>}
        </Row>

        {limit > 8 ? (
          loader ? (
            <Skeleton
              width={150}
              height={40}
              borderRadius={8}
              className="mx-auto d-block mt-4"
            />
          ) : (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              containerClassName="pagination justify-content-center mt-lg-5 mt-4 mb-0"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={currentPage - 1}
            />
          )
        ) : loader ? (
          <Skeleton width={120} height={20} className="mx-auto d-block mt-3" />
        ) : (
          <Link className="text-decoration-none" to={"/photos"}>
            <div className="text-blue-1 text-center">
              <p className="text-uppercase mb-0 fw-semibold">LOAD MORE</p>
              <FontAwesomeIcon
                icon={faChevronDown}
                fontSize={25}
                role="button"
              />
            </div>
          </Link>
        )}
      </Container>
    </section>
  );
}

export default PhotoAlbum;
