import {
  DOWNLOAD_COUNT,
  MEDIATION_PAGE_LOADER,
  SET_MEDIATION_PAGE_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  mediationPageData: {},
};

const mediationPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEDIATION_PAGE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_MEDIATION_PAGE_DATA: {
      return {
        ...state,
        mediationPageData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        mediationPageData: {
          ...state.mediationPageData,
          downloadCount: (state.mediationPageData?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default mediationPageReducer;
