import { STATS_LOADER, SET_STATS_DATA } from "../actionTypes";

const initialState = {
  loader: false,
  statsData: {},
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_STATS_DATA: {
      return {
        ...state,
        statsData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default statsReducer;
