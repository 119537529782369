// src/validation/validationSchema.js
import * as yup from "yup";

// Common email and password schema
const emailSchema = yup
  .string()
  .transform((value) => value?.toLowerCase() || "")
  .required("Email is required")
  .matches(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Please enter a valid email.",
  );

// Common PhoneNumber  schema
const phoneNumberSchema = yup
  .string()
  .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
  .required("Phone number is required");

// Common password schema
const passwordSchema = yup
  .string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters")
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\[\]{}\\|;':",./<>?`~\-])[A-Za-z\d!@#$%^&*()_+\[\]{}\\|;':",./<>?`~\-]{8,}$/,
    "Password must contain at least 8 characters, one letter, one number, and one special character",
  );

// Common validation schema for profile image
const profileImageSchema = yup
  .mixed()
  .required("Profile picture is required")
  .test("fileType", "Unsupported File Format", (value) => {
    return (
      value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
    );
  });
// Common validation schema for name
const nameSchema = yup
  .string()
  .required("Name is required")
  .min(3, "Name is too short, minimum 3 characters")
  .max(30, "Name is too long")
  .matches(/^[a-zA-Z ]*$/, "Name should only contain alphabets");

// Common validation schema for date
const dateSchema = yup.date().required("Date is Required");

// reCaptcha validation schema
const reCaptchaSchema = yup.string().required("ReCaptcha field is required");

// Define userType-specific schemas
const userTypeDependentSchemas = {
  generalPublic: yup.object().shape({
    fullName: yup.string().required("Full Name is required"),
  }),
  governmentEmployee: yup.object().shape({
    governmentID: yup.string().required("Government ID is required"),
  }),
  student: yup.object().shape({
    studentID: yup.string().required("Student ID is required"),
  }),
  privateSectorWithMembership: yup.object().shape({
    businessLicense: yup.string().required("Business license is required"),
    membershipNumber: yup.string().required("Membership number is required"),
  }),
  privateSectorWithoutMembership: yup.object().shape({
    businessLicense: yup.string().required("Business license is required"),
  }),
  arbitrator: yup.object().shape({
    arbitratorCategory: yup
      .string()
      .required("Arbitrator Category is required"),
    certifications: yup
      .array()
      .of(yup.mixed().required("Certification file is required"))
      .min(1, "At least one certification is required")
      .required("Certifications are required"),
    experience: yup.string().required("Experience is required"),
  }),
  expert: yup.object().shape({
    expertiseField: yup.string().required("Expertise field is required"),
  }),
  translator: yup.object().shape({
    languages: yup
      .array()
      .of(yup.string().required("Language is required"))
      .min(1, "At least one language is required")
      .required("Languages are required"),
    certifications: yup
      .array()
      .of(yup.mixed().required("Certification file is required"))
      .min(1, "At least one certification is required")
      .required("Certifications are required"),
    experience: yup.string().required("Experience is required"),
    translationRate: yup
      .number()
      .positive()
      .required("Translation rate is required"),
  }),
  systemAdministrator: yup.object().shape({
    adminID: yup.string().required("Administrator ID is required"),
  }),

  centerAdministrator: yup.object().shape({
    adminID: yup.string().required("Center Administrator ID is required"),
  }),

  centerEmployee: yup.object().shape({
    employeeID: yup.string().required("Employee ID is required"),
  }),
};

export const registerSchema = yup
  .object()
  .shape({
    userName: nameSchema,
    email: emailSchema,
    password: passwordSchema,
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords do not match")
      .required("Confirm Password is required"),
    profileImage: profileImageSchema,
    arbitratorCheck: yup.boolean(),
    userType: yup
      .string()
      .oneOf(Object.keys(userTypeDependentSchemas), "Invalid user type")
      .required("User type is required"),
    reCaptcha: reCaptchaSchema,
  })
  .test(
    "userType-dependent-validation",
    "Invalid user type details",
    function (values, context) {
      const { userType, ...rest } = values;

      if (userType && userTypeDependentSchemas[userType]) {
        try {
          userTypeDependentSchemas[userType].validateSync(rest, {
            context: this.options.context,
            abortEarly: false,
          });
          return true;
        } catch (err) {
          if (err.inner && err.inner.length) {
            const errors = err.inner.map((validationError) =>
              this.createError({
                path: validationError.path,
                message: validationError.message,
              }),
            );
            throw new yup.ValidationError(errors);
          }
        }
      }
      return true;
    },
  );
// Schema for profile update
export const profileSchema = yup.object().shape({
  username: nameSchema,
  profileImage: profileImageSchema,
});

// Schema for login or authentication
export const loginSchema = yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
  reCaptcha: reCaptchaSchema,
});

// Schema for forgot password
export const forgotPasswordSchema = yup.object().shape({
  email: emailSchema,
});
// Schema for change password
export const changePasswordSchema = yup.object().shape({
  password: passwordSchema,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Confirm Password is required"),
});

// Schema for resend  email
export const resendEmailSchema = yup.object().shape({
  email: emailSchema,
});

// Schema for FAQ
export const faqSchema = yup.object().shape({
  name: nameSchema,
  email: emailSchema,
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
});

// Define the schema for an arbitration claimant
export const arbitrationSchema = yup.object().shape({
  arbitrationClaimant: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        lawyer: yup.string().nullable(), // Not required
        lawyerAddress: yup.string().required("Lawyer Address is required"),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one claimant is required"),

  arbitrationRespondent: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one respondent is required"),

  subject: yup.string().required("Subject is required"),

  claims: yup.string().required("Claims are required"),

  documents: yup.array().of(yup.mixed().required("A file is required")),
});

// Define the schema for an Intervention claimant
export const interventionSchema = yup.object().shape({
  interventionClaimant: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        lawyer: yup.string().nullable(), // Not required
        lawyerAddress: yup.string().required("Lawyer Address is required"),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one claimant is required"),

  interventionRespondent: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one respondent is required"),

  subject: yup.string().required("Subject is required"),

  claims: yup.string().required("Claims are required"),

  documents: yup.array().of(yup.mixed().required("A file is required")),
});

// Define the schema for an Joinder claimant
export const joinderSchema = yup.object().shape({
  joinderClaimant: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        lawyer: yup.string().nullable(), // Not required
        lawyerAddress: yup.string().required("Lawyer Address is required"),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one claimant is required"),

  joinderRespondent: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one respondent is required"),

  subject: yup.string().required("Subject is required"),

  claims: yup.string().required("Claims are required"),

  documents: yup.array().of(yup.mixed().required("A file is required")),
});

export const certifiedCopySchema = yup.object().shape({
  caseDate: yup.string().required("Date is Required"),
  caseNo: yup.string().required("Case No. is Required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is Required")
    .matches(/^[A-Za-z\s]+$/, "Invalid Name"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  copies: yup
    .string()
    .required("No of copies is Required")
    .matches(/^\d+$/, "Phone number must contain only numbers"),
  signatureDate: yup
    .date()
    .required("Date of issue is Required")
    .min(new Date(), "Signature date must be today or in the future"),
  signature: yup.string().required("Signature is Required"),
});

export const interpretationSchema = yup.object().shape({
  caseDate: yup.date().required("Date is required"),
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  awardDateOfIssuance: yup
    .date()
    .required("Award Date of Issuance is required"),
  awardDateOfReceipt: yup.date().required("Award Date of Receipt is required"),
  interpretationSubject: yup
    .string()
    .required("Interpretation Subject is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
  documents: yup.mixed().required("A file is required"),
  reCaptcha: reCaptchaSchema,
});

// Define contact-us validation schema
export const contactSchema = yup.object().shape({
  name: nameSchema,
  email: emailSchema,
  subject: yup.string().required("Subject is required"),
  message: yup
    .string()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters"),
  reCaptcha: reCaptchaSchema,
});

// Define claim validation schema
export const claimSchema = yup.object().shape({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  copies: yup
    .number()
    .required("Number of copies is required")
    .min(1, "At least one copy is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
  reCaptcha: reCaptchaSchema,
});

// Define objection validation schema
export const objectionSchema = yup.object().shape({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  challenge: yup.string().required("Challenge is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
});

// Define certificate  validation schema
export const certificateSchema = yup.object().shape({
  caseDate: dateSchema,
  RequestedBy: yup.string().required("Requested by is Required"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  AddressedTo: yup.string().required("Addressed To field is Required"),
  otherDetails: yup.string().required("Further details is Required"),
  signatureDate: yup.date().required("Date of issue is Required"),
  signature: yup.string().required("Signature is Required"),
  selectedSubject: yup
    .string()
    .required("Please select a subject of the certificate"),
});

// Define validation schema
export const nominationSchema = yup.object({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case number is required"),
  RequestedBy: yup.string().required("Requested by is required"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  nameOfArbitrator: nameSchema,
  nationality: yup.object().required("Nationality is required"),
  specialization: yup.string().required("Specialization is required"),
  addressAritrator: yup.string().required("Address is required"),
  emailArbitrator: emailSchema,
  phoneNumberArbitrator: phoneNumberSchema,
  challenge: yup.string().required("Challenge is required"),
  signatureDate: yup.date().required("Signature date is required"),
  signature: yup.string().required("Signature is required"),
});

// Define not governed validation schema
export const nonGovernedSchema = yup.object().shape({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  applicableLaw: yup.string().required("applicable law is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
});

export const pollOptionsSchema = yup.object().shape({
  selectedOption: yup.string().required("You must select an option"),
});

export const feedbackSchema = yup.object().shape({
  feedback: yup
    .string()
    .min(3, "Feedback must be at least 3 characters long")
    .required("Feedback is required"),
  rating: yup
    .number()
    .min(1, "Rating must be at least 1")
    .max(5, "Rating must be at most 5")
    .required("Rating is required"),
});

export const reportSchema = yup.object().shape({
  pageUrl: yup.string().required("URL is required"),
  feedback: yup.string().required("Comment is required"),
});

export const mediationReqSchema = yup.object().shape({
  claimantName: yup
    .string()
    .required("Claimant name is required")
    .min(1, "Claimant name must be at least 1 character long"),
  claimantNationality: yup
    .string()
    .required("Claimant nationality is required")
    .min(1, "Claimant nationality must be at least 1 character long"),
  additionalClaimantsNames: yup.string().optional(),
  additionalClaimantsNationalities: yup.string().optional(),
  idNumber: yup.string().optional(),
  addressLine1: yup
    .string()
    .required("Address line 1 is required")
    .min(1, "Address line 1 must be at least 1 character long"),
  addressLine2: yup.string().optional(),
  addressLine3: yup.string().optional(),
  poBox: yup.string().optional(),
  city: yup
    .string()
    .required("City is required")
    .min(1, "City must be at least 1 character long"),
  postCode: yup.string().optional(),
  stateProvince: yup.string().optional(),
  country: yup
    .string()
    .required("Country is required")
    .min(1, "Country must be at least 1 character long"),
  nationalAddress: yup.string().optional(),
  telephoneNumber: yup
    .string()
    .required("Telephone number is required")
    .min(1, "Telephone number must be at least 1 character long"),
  emailAddress: yup
    .string()
    .required("Email address is required")
    .email("Email address must be a valid email"),
  representativeName: yup
    .string()
    .required("Representative name is required")
    .min(1, "Representative name must be at least 1 character long"),
  representativeFirmName: yup.string().optional(),
  representativeAddressLine1: yup
    .string()
    .required("Representative address line 1 is required")
    .min(1, "Representative address line 1 must be at least 1 character long"),
  representativeAddressLine2: yup.string().optional(),
  representativeAddressLine3: yup.string().optional(),
  representativePOBox: yup.string().optional(),
  representativeCity: yup
    .string()
    .required("Representative city is required")
    .min(1, "Representative city must be at least 1 character long"),
  representativePostCode: yup.string().optional(),
  representativeStateProvince: yup.string().optional(),
  representativeCountry: yup
    .string()
    .required("Representative country is required")
    .min(1, "Representative country must be at least 1 character long"),
  representativeNationalAddress: yup.string().optional(),
  representativeTelephoneNumber: yup
    .string()
    .required("Representative telephone number is required")
    .min(
      1,
      "Representative telephone number must be at least 1 character long",
    ),
  representativeMobileNumber: yup
    .string()
    .required("Representative mobile number is required")
    .min(1, "Representative mobile number must be at least 1 character long"),
  representativeEmailAddress: yup
    .string()
    .required("Representative email address is required")
    .email("Representative email address must be a valid email"),
  dateOfContractOrMediationAgreement: yup
    .date()
    .required("Date of contract or mediation agreement is required")
    .typeError("Date must be a valid date"),
  textOfMediationClauseAgreement: yup
    .string()
    .required("Text of mediation clause agreement is required")
    .min(
      1,
      "Text of mediation clause agreement must be at least 1 character long",
    ),
  natureOfDispute: yup
    .string()
    .required("Nature of dispute is required")
    .min(1, "Nature of dispute must be at least 1 character long"),
  claimOrReliefSought: yup
    .string()
    .required("Claim or relief sought is required")
    .min(1, "Claim or relief sought must be at least 1 character long"),
  claimantTypeOfBusiness: yup
    .string()
    .required("Claimant type of business is required")
    .min(1, "Claimant type of business must be at least 1 character long"),
  respondentTypeOfBusiness: yup
    .string()
    .required("Respondent type of business is required")
    .min(1, "Respondent type of business must be at least 1 character long")
    .max(50, "Number of arbitrators must not exceed 50"),
  numberOfArbitrators: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : Number(originalValue),
    )
    .positive("Must be greater than zero")
    .integer("Must be an integer")
    .required("Number of arbitrators is required"),
  placeOfMediation: yup.string().optional(),
  language: yup.string().optional(),
  requestedMediatorQualifications: yup.string().optional(),
  respondentName: yup
    .string()
    .required("Respondent name is required")
    .min(1, "Respondent name must be at least 1 character long"),
  respondentNationality: yup
    .string()
    .required("Respondent nationality is required")
    .min(1, "Respondent nationality must be at least 1 character long"),
  additionalRespondentsNames: yup.string().optional(),
  additionalRespondentsNationalities: yup.string().optional(),
  respondentIdNumber: yup.string().optional(),
  respondentAddress: yup
    .string()
    .required("Respondent address is required")
    .min(1, "Respondent address must be at least 1 character long"),
  respondentPOBox: yup.string().optional(),
  respondentCity: yup
    .string()
    .required("Respondent city is required")
    .min(1, "Respondent city must be at least 1 character long"),
  respondentPostCode: yup.string().optional(),
  respondentStateProvince: yup.string().optional(),
  respondentCountry: yup
    .string()
    .required("Respondent country is required")
    .min(1, "Respondent country must be at least 1 character long"),
  respondentNationalAddress: yup.string().optional(),
  respondentTelephoneNumber: yup
    .string()
    .required("Respondent telephone number is required")
    .min(1, "Respondent telephone number must be at least 1 character long"),
  respondentEmailAddress: yup
    .string()
    .required("Respondent email address is required")
    .email("Respondent email address must be a valid email"),
  acknowledgement1: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement2: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement3: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement4: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement5: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement6: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement7: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement8: yup.boolean().oneOf([true], "You must agree to continue."),
  acknowledgement9: yup.boolean().oneOf([true], "You must agree to continue."),
  documentUpload: yup
    .mixed()
    .test(
      "required",
      "A file upload is required to submit this form",
      (value) => {
        return value && value.length > 0;
      },
    ),
  confirmContractUpload: yup
    .boolean()
    .required("Contract upload confirmation is required")
    .default(false),
  confirmPowerOfAttorneyUpload: yup
    .boolean()
    .required("Power of attorney upload confirmation is required")
    .default(false),
  sendCopy: yup.boolean().optional(),
  email: yup
    .string()
    .email("Must be a valid email")
    .when("sendCopy", {
      is: true,
      then: (schema) =>
        schema.required("Email address is required when sending a copy."),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const arbitrationFormSchema = yup.object().shape({
  // Claimant Details
  claimantName: yup.string().required("Claimant name is required"),
  claimantNationality: yup
    .string()
    .required("Claimant nationality is required"),
  additionalClaimants: yup.string().nullable(),
  additionalClaimantsNationalities: yup.string().nullable(),
  claimantIdOrRegNumber: yup
    .string()
    .required("Claimant ID or Reg. Number is required"),
  claimantAddressLine1: yup.string().required("Address Line 1 is required"),
  claimantAddressLine2: yup.string().nullable(),
  claimantAddressLine3: yup.string().nullable(),
  claimantPOBox: yup.string().nullable(),
  claimantCity: yup.string().required("City is required"),
  claimantPostCode: yup.string().nullable(),
  claimantState: yup.string().nullable(),
  claimantCountry: yup.string().required("Country is required"),
  claimantNationalAddress: yup.string().nullable(),
  claimantTelephone: yup.string().required("Telephone is required"),
  claimantEmail: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  claimantBusinessType: yup.string().nullable(),

  // Claimant Representative
  repName: yup.string().required("Representative name is required"),
  repFirmName: yup.string().nullable(),
  repAddressLine1: yup.string().required("Representative Address is required"),
  repAddressLine2: yup.string().nullable(),
  repAddressLine3: yup.string().nullable(),
  repPOBox: yup.string().nullable(),
  repCity: yup.string().required("Representative City is required"),
  repPostCode: yup.string().nullable(),
  repState: yup.string().nullable(),
  repCountry: yup.string().required("Representative Country is required"),
  repNationalAddress: yup.string().nullable(),
  repTelephone: yup
    .string()
    .required("Representative Telephone number is required"),
  repMobile: yup.string().required("Representative Mobile number is required"),
  repEmail: yup
    .string()
    .required("Representative email address is required")
    .email("Invalid email"),

  // Respondent Details
  respondentName: yup.string().required("Respondent name is required"),
  respondentNationality: yup
    .string()
    .required("Respondent nationality is required"),
  additionalRespondents: yup.string().nullable(),
  additionalRespondentsNationalities: yup.string().nullable(),
  respondentIdOrRegNumber: yup
    .string()
    .required("Respondent ID or Reg. Number is required"),
  respondentAddress: yup.string().required("Respondent address is required"),
  respondentPOBox: yup.string().nullable(),
  respondentCity: yup.string().required("City is required"),
  respondentPostCode: yup.string().nullable(),
  respondentState: yup.string().nullable(),
  respondentCountry: yup.string().required("Country is required"),
  respondentNationalAddress: yup.string().nullable(),
  respondentTelephone: yup.string().required("Telephone is required"),
  respondentEmail: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  respondentBusinessType: yup.string().nullable(),

  // Respondent Representative
  respondentRepName: yup.string().required("Representative Name is required"),
  respondentRepFirmName: yup.string().nullable(),
  respondentRepAddress: yup
    .string()
    .required("Representative Address is required"),
  respondentRepPOBox: yup.string().nullable(),
  respondentRepCity: yup.string().required("Representative City is required"),
  respondentRepPostCode: yup.string().nullable(),
  respondentRepState: yup.string().nullable(),
  respondentRepCountry: yup
    .string()
    .required("Representative Country is required"),
  respondentRepNationalAddress: yup.string().nullable(),
  respondentRepTelephone: yup.string().required("Telephone Number is required"),
  respondentRepMobile: yup.string().required("Mobile Number is required"),
  respondentRepEmail: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email address"),

  // Dispute Details
  contractDate: yup
    .date()
    .transform((value, originalValue) =>
      originalValue === "" ? null : new Date(originalValue),
    )
    .required("Contract date is required"),
  arbitrationClauseText: yup
    .string()
    .required("Arbitration Clause Text is required"),
  natureOfDispute: yup.string().required("Nature of dispute is required"),
  claimReliefSought: yup.string().required("Claim relief sought is required"),
  numberOfArbitrators: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : Number(originalValue),
    )
    .positive("Must be greater than zero")
    .integer("Must be an integer")
    .required("Number of arbitrators is required"),
  placeOfArbitration: yup.string().nullable(),
  language: yup.string().nullable(),
  expeditedProcedureDetails: yup.string().nullable(),
  emergencyApplicationFiled: yup
    .boolean()
    .transform((value, originalValue) =>
      originalValue === "" ? false : value,
    ),
  // Acknowledgements
  infoAccurate: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  filingRequirementsMet: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  filingFeePaid: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  agreeToEmailCommunication: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  agreeToSMSCommunication: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  agreeToTerms: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  powerOfAttorney: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue."),
  arbitrationRequirementsMet: yup
    .boolean()
    .required("You must agree to continue.")
    .default(false),
  expeditedProcedureConfirmed: yup
    .boolean()
    .required("You must agree to continue.")
    .default(false),
  marketingConsent: yup
    .boolean()
    .required("You must agree to continue.")
    .default(false),
  noticeSentToRespondent: yup
    .boolean()
    .required("You must agree to continue.")
    .oneOf([true], "You must agree to continue.")
    .default(false),
  documents: yup
    .mixed()
    .test("fileRequired", "At least one document is required", (files) => {
      return files && files.length > 0;
    })
    .test("fileType", "Only PDF files are allowed", (files) => {
      if (!files || files.length === 0) return true;
      return Array.from(files).every((file) => file.type === "application/pdf");
    })
    .test("fileCount", "You can upload a maximum of 10 files", (files) => {
      if (!files || files.length === 0) return true;
      return files.length <= 10;
    })
    .test("fileSize", "Each file must be 30MB or smaller", (files) => {
      if (!files || files.length === 0) return true;
      return Array.from(files).every((file) => file.size <= 30 * 1024 * 1024);
    }),
});
