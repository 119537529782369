import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/common/banner/banner";
import { Images } from "../../assets";
import Formation from "./formation/formation";
import RoleOfCenter from "./roleOfCenter/roleOfCenter";
import Procedures from "./procedures/procedures";
import Membership from "./membership/membership";
import TermsOfReferences from "./termsOfReferences/termsOfReferences";
import Footer from "../../components/common/footer/footer";
import ActionFeedback from "../../components/common/takeActions/actionFeedback";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  getJuryPanelData,
  updateDownloadCount,
} from "../../redux/jury-panel/action";
import MetaTags from "../../components/metaTags/seoTags";
import PageStats from "../../components/pageStats/pageStats";

const JuryPanel = () => {
  const dispatch = useDispatch();
  const juryPanel = useSelector((state) => state.juryPanel);
  useEffect(() => {
    dispatch(getJuryPanelData());
  }, []);
  return (
    <React.Fragment>
      <MetaTags
        title={juryPanel?.juryPanelData?.pageTitle}
        metaTitle={juryPanel?.juryPanelData?.metaTitle}
        description={juryPanel?.juryPanelData?.metaDescription}
        keywords={juryPanel?.juryPanelData?.metaKeywords}
      />
      <Banner
        img={Images.juryPanelBanner}
        title={juryPanel?.juryPanelData?.heading}
        content={juryPanel?.juryPanelData?.summary}
        loader={juryPanel?.loader}
      />
      <div className="admin-multi-sec jury-page">
        {juryPanel?.juryPanelData?.sections?.length > 0 &&
          juryPanel?.juryPanelData?.sections?.map((section, index) => {
            return <Formation key={index} data={section} />;
          })}
      </div>
      <PageStats
        countAction={updateDownloadCount}
        downloadCount={juryPanel.juryPanelData?.downloadCount}
        viewCount={juryPanel.juryPanelData?.pageViewCount}
        pdf={juryPanel.juryPanelData?.pdfFile}
        id={juryPanel.juryPanelData?.id}
        loader={juryPanel?.loader}
      />
      {/* <TermsOfReferences />; */}
      {/* <RoleOfCenter /> */}
      {/* <Procedures /> */}
      {/* <Membership /> */}
      <ActionFeedback />
    </React.Fragment>
  );
};

export default JuryPanel;
